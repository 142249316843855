import { QueryFunctionContext } from '@tanstack/react-query';
import { UseNotificationsMeQueryKey } from './props';
import { PaginatedResponse } from '@/models/pagination/paginated-response.dto';
import { NotificationsInputDto } from '@/services/dto/notifications/notifications-input.dto';
import baseAPI from '@/api';
import { Notification } from '@/models/notification/notification';

export async function fetchNotificationsMe({
  pageParam,
  queryKey,
}: QueryFunctionContext<UseNotificationsMeQueryKey>): Promise<
  PaginatedResponse<Notification>
> {
  const { groupId, limit }: NotificationsInputDto = queryKey[1];

  const response = await baseAPI.get<PaginatedResponse<Notification>>(
    '/notifications/me',
    {
      params: {
        page: pageParam,
        groupId,
        limit,
      },
    },
  );

  return response.data;
}
