import { Tabs } from '@/models/fortune-double-tabs';
import { Nullable } from '@starsoft/common/interfaces';
import { RouletteColor } from '@/models/roulette/color';
import { Round } from '@/models/round';

export enum FortuneDoubleActions {
  UpdateRound = 'fortune-double/update-round',
  HandleStoppedRoulette = 'fortune-double/handle-stopped-roulette',
  ChangeColor = 'fortune-double/change-color',
  Reset = 'fortune-double/reset',
  SetSocketConnection = 'fortune-double/socket-connection',
  SetActiveTab = 'fortune-double/set-active-tab',
  SetCurrentStep = 'fortune-double/set-current-step',
  SetHasSeenBefore = 'fortune-double/set-has-seen-before',
}

export interface FortuneDoublePrize {
  id: string;
  color: RouletteColor;
  number: number;
  image: string;
}

export interface FortuneDoubleProps {
  prizeList: FortuneDoublePrize[];
  color: RouletteColor;
  closedRound: boolean;
  isSocketConnected: boolean;
  activeTab: Tabs.Tutorial | Tabs.Rules;
  currentStep: number;
  hasSeenBefore: boolean;
  round: Nullable<Round>;
}

export interface FortuneDoubleBasePayload<T extends FortuneDoubleActions> {
  type: T;
}

export interface FortuneDoubleChangeColorPayload
  extends FortuneDoubleBasePayload<FortuneDoubleActions.ChangeColor> {
  payload: {
    color: RouletteColor;
  };
}
export interface FortuneDoubleUpdateRoundPayload
  extends FortuneDoubleBasePayload<FortuneDoubleActions.UpdateRound> {
  payload: {
    round: Round;
  };
}

export interface FortuneDoubleSetSocketConnectionPayload
  extends FortuneDoubleBasePayload<FortuneDoubleActions.SetSocketConnection> {
  payload: {
    isSocketConnected: boolean;
  };
}

export interface FortuneDoubleSetCurrentStepPayload
  extends FortuneDoubleBasePayload<FortuneDoubleActions.SetCurrentStep> {
  payload: {
    currentStep: number;
  };
}

export interface FortuneDoubleSetActiveTabPayload
  extends FortuneDoubleBasePayload<FortuneDoubleActions.SetActiveTab> {
  payload: {
    activeTab: Tabs.Tutorial | Tabs.Rules;
  };
}

export interface FortuneDoubleSetHasSeenBeforePayload
  extends FortuneDoubleBasePayload<FortuneDoubleActions.SetHasSeenBefore> {
  payload: {
    hasSeenBefore: boolean;
  };
}

export type FortuneDoubleStatePayload =
  | FortuneDoubleBasePayload<FortuneDoubleActions.UpdateRound>
  | FortuneDoubleBasePayload<FortuneDoubleActions.HandleStoppedRoulette>
  | FortuneDoubleBasePayload<FortuneDoubleActions.Reset>
  | FortuneDoubleSetSocketConnectionPayload
  | FortuneDoubleChangeColorPayload
  | FortuneDoubleSetCurrentStepPayload
  | FortuneDoubleSetActiveTabPayload
  | FortuneDoubleSetHasSeenBeforePayload;
