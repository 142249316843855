import { ItemProps } from './props';
import { getComputedColor } from '@/utils/theme';

export default <ItemProps[]>[
  {
    name: 'tab_casino',
    icon: 'fa-solid fa-spade fa-swap-opacity',
    active: true,
    image: `${process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL}/artworks/900x389/casino.png`,
    path: '/casino',
    label: 'home_tabs_description_Casino',
    activePaths: ['/casino', '/games'],
    iconColor: getComputedColor('--primary-color'),
  },
  {
    name: 'tab_sportsbook',
    icon: 'fa-solid fa-basketball',
    iconColor: getComputedColor('--warning-color'),
    active: true,
    image: `${process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL}/artworks/900x389/sports.png`,
    path: '/sports',
    label: 'home_tabs_description_Sportsbook',
    activePaths: ['/sports'],
  },
  {
    name: 'tournaments_label',
    icon: 'fa-solid fa-trophy',
    iconColor: getComputedColor('--success-color'),
    active: true,
    path: '/tournaments',
    label: 'home_tabs_description_tournaments',
    activePaths: ['/tournaments'],
  },
];
