import { toggleSearch } from '@/lib/store/casino/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/lib/store';
import { IconButton } from '@starsoft/common/components';

export default function GameSearchButton() {
  const dispatch = useDispatch();
  const open: boolean = useSelector(
    (state: RootState) => state.casino.openSearch,
  );

  function handleClick() {
    dispatch(toggleSearch());
  }

  return (
    <IconButton onClick={handleClick} disabled={open}>
      <i className="fa-solid fa-magnifying-glass" />
    </IconButton>
  );
}
