import { EmailProvidersQueryKeys } from '../../queryKey';
import { PaginatedRequest } from '@/models/pagination/paginated-request';
import {
  UseEmailProvidersProps,
  UseEmailProvidersQueryKeyProps,
} from './props';
import { InfiniteData, useInfiniteQuery } from '@tanstack/react-query';
import { fetchEmailProviders } from './service';
import { PaginatedResponse } from '@/models/pagination/paginated-response.dto';
import { AxiosError } from 'axios';
import { GenericError } from '@/models/generic-error';
import {
  useInfiniteQueryCallbacks,
  useInfiniteQueryReset,
} from '@starsoft/common/hooks';
import { EmailProviders } from '@/models/email-providers';
import { useMemo } from 'react';

export function useEmailProviders(options?: UseEmailProvidersProps) {
  const { enabled = true } = options ?? {};
  const filter: PaginatedRequest = {
    limit: 8,
  };

  const queryKey: UseEmailProvidersQueryKeyProps = [
    EmailProvidersQueryKeys.Primary,
    filter,
  ];

  const { getNextPageParam, getPreviousPageParam } =
    useInfiniteQueryCallbacks();
  useInfiniteQueryReset(queryKey);

  const { data, isPending, error, refetch, ...rest } = useInfiniteQuery<
    PaginatedResponse<EmailProviders>,
    AxiosError<GenericError>,
    InfiniteData<PaginatedResponse<EmailProviders>>,
    UseEmailProvidersQueryKeyProps
  >({
    queryKey,
    queryFn: fetchEmailProviders,
    getNextPageParam,
    getPreviousPageParam,
    initialPageParam: 1,
    enabled,
  });

  const emailProviders = useMemo(
    () => data?.pages?.flatMap(page => page?.data),
    [data],
  );

  return {
    ...rest,
    error,
    refetch,
    isLoading: isPending,
    emailProviders,
  };
}
