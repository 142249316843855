import { useNotificationGroupsMe } from '@/api/notification-groups';
import { NotificationGroupId } from '@/enums/notificationGroupId';
import { RootState } from '@/lib/store';
import { useDispatch, useSelector } from 'react-redux';
import styles from './styles.module.scss';
import { ErrorChip } from '@starsoft/common/components';
import { Tabs } from '@starsoft/common/components';
import { Tab } from '@starsoft/common/src/components/Tabs/Tab/props';
import { NotificationGroupWithUnreadCount } from '@/models/notification/notificationGroupWithUnreadCount';
import { useMemo } from 'react';
import { setNotificationModalTab } from '@/lib/store/notifications/actions';
import NotificationModalTab from './Tab';

export default function NotificationModalTabs() {
  const tab: NotificationGroupId = useSelector(
    (state: RootState) => state.notifications.tab,
  );
  const dispatch = useDispatch();
  const { notificationGroups, isPending, error, refetch } =
    useNotificationGroupsMe({
      refetchOnMount: 'always',
    });
  const tabs: Tab<NotificationGroupWithUnreadCount>[] = useMemo(
    () =>
      notificationGroups?.data
        .filter(
          (notificationGroup: NotificationGroupWithUnreadCount) =>
            notificationGroup.id === NotificationGroupId.Inbox,
        )
        .map((notificationGroup: NotificationGroupWithUnreadCount) => ({
          label: notificationGroup.name,
          value: notificationGroup,
        })) ?? [],
    [notificationGroups],
  );
  const currentTab: NotificationGroupWithUnreadCount | undefined = useMemo(
    () =>
      notificationGroups?.data.find(
        (notificationGroup: NotificationGroupWithUnreadCount) =>
          notificationGroup.id === tab,
      ),
    [notificationGroups, tab],
  );

  function setTab(tab: NotificationGroupWithUnreadCount) {
    dispatch(setNotificationModalTab(tab.id));
  }

  if (error) {
    return (
      <div className={styles.container}>
        <ErrorChip action={refetch} cardBg />
      </div>
    );
  }

  if (isPending) {
    <div className={styles.container}>
      <div className={styles.skeleton} />
    </div>;
  }

  return (
    <div className={styles.container}>
      <Tabs
        currentTab={currentTab as NotificationGroupWithUnreadCount}
        tabs={tabs}
        setTab={setTab}
        renderTab={props => <NotificationModalTab {...props} />}
        showIndicator
      />
    </div>
  );
}
