import { AuthStep } from '@/enums/auth/authStep';
import useAuthSearchParams from '@/hooks/auth/useAuthSearchParams';
import AuthModalRegisterEmailContent from '../Email';
import AuthModalRegisterPasswordContent from '../Password';

export default function AuthModalContentRegisterSteps() {
  const { step } = useAuthSearchParams();

  switch (step) {
    case AuthStep.Email:
      return <AuthModalRegisterEmailContent />;
    case AuthStep.Password:
      return <AuthModalRegisterPasswordContent />;
  }
}
