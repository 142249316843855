import { NotificationsInputDto } from '@/services/dto/notifications/notifications-input.dto';
import { UseNotificationsMeProps, UseNotificationsMeQueryKey } from './props';
import { NotificationsQuerykey } from '../../queryKey';
import {
  useInfiniteQueryCallbacks,
  useInfiniteQueryReset,
} from '@starsoft/common/hooks';
import { PaginatedResponse } from '@/models/pagination/paginated-response.dto';
import { InfiniteData, useInfiniteQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { GenericError } from '@/models/generic-error';
import { fetchNotificationsMe } from './service';
import { useMemo } from 'react';
import { NotificationGroupId } from '@/enums/notificationGroupId';
import { Notification } from '@/models/notification/notification';

export function useNotificationsMe(options?: UseNotificationsMeProps) {
  const { groupId, enabled = true, refetchOnMount = true } = options ?? {};
  const params: NotificationsInputDto = {
    limit: 50,
    groupId: groupId as NotificationGroupId,
  };
  const queryKey: UseNotificationsMeQueryKey = [
    NotificationsQuerykey.Me,
    params,
  ];

  const { getNextPageParam, getPreviousPageParam } =
    useInfiniteQueryCallbacks();
  useInfiniteQueryReset(queryKey);

  const { data, isPending, fetchStatus, ...infinityQuery } = useInfiniteQuery<
    PaginatedResponse<Notification>,
    AxiosError<GenericError>,
    InfiniteData<PaginatedResponse<Notification>>,
    UseNotificationsMeQueryKey
  >({
    queryKey,
    initialPageParam: 1,
    queryFn: fetchNotificationsMe,
    getNextPageParam,
    getPreviousPageParam,
    refetchOnWindowFocus: false,
    enabled: !!groupId && enabled,
    refetchOnMount,
  });

  const formattedData: Notification[] | undefined = useMemo(
    () => data?.pages?.flatMap(page => page?.data),
    [data],
  );

  return {
    ...infinityQuery,
    notifications: formattedData,
    isPending: isPending && fetchStatus !== 'idle',
  };
}
