import { CoinflipProps, CoinflipActions } from './props';

const initialState: CoinflipProps = {
  animate: false,
};

export default function reducer(
  state: CoinflipProps = initialState,
  action: any,
): CoinflipProps {
  switch (action.type) {
    case CoinflipActions.FlipCoinAnimation:
      return {
        ...state,
        animate: !state.animate,
      };
    default:
      return state;
  }
}
