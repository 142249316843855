import { useForm } from 'react-hook-form';
import styles from './styles.module.scss';
import { useModalNavigation } from '@/hooks/modals/useModalNavigation';
import { ModalsKey } from '@/enums/modalsKey';
import { BarPasswordStrong, Button, Input } from '@package/components';
import useTranslation from 'next-translate/useTranslation';
import { useRecoveryPasswordReset } from '@/api/recovery-passwords';
import { FormProps } from './props';
import { NotificationsService } from '@/services/NotificationsService';
import zxcvbn, { ZXCVBNResult } from 'zxcvbn';
import { ChangeEvent, useState } from 'react';
import { Nullable } from '@package/interfaces';
import { useSelector } from 'react-redux';
import { RootState } from '@/lib/store';

export default function ResetPassword() {
  const { t } = useTranslation();
  const {
    watch,
    register,
    handleSubmit,
    formState: { touchedFields, dirtyFields },
  } = useForm<FormProps>();
  const { navigateToModal } = useModalNavigation();
  const { reset, isPending } = useRecoveryPasswordReset();
  const [passwordStrength, setPasswordStrength] = useState<ZXCVBNResult | null>(
    null,
  );
  const selectedEmail: Nullable<string> = useSelector(
    (state: RootState) => state.auth.recoverEmail,
  );

  const code: string | undefined = watch('code');
  const password: string | undefined = watch('password');
  const passwordConfirmation: string | undefined = watch(
    'passwordConfirmation',
  );
  const isPasswordEmpty: boolean = password?.length === 0;
  const isValidPasswordLength: boolean =
    password?.length >= 4 && password?.length <= 128;
  const isValidPassword: boolean = isValidPasswordLength && !isPasswordEmpty;
  const isDisabled: boolean =
    !code || !password || !passwordConfirmation || !isValidPassword;

  function handlePasswordChange(e: ChangeEvent<HTMLInputElement>) {
    const newPassword = e.target.value;
    setPasswordStrength(zxcvbn(newPassword));
  }

  function getPasswordError(): string | undefined {
    if (isPasswordEmpty) {
      return t('common:empty_password');
    }

    if (!isValidPasswordLength) {
      return t('common:invalid_password_length');
    }
  }

  function handleBack() {
    navigateToModal(ModalsKey.RecoverPassword);
  }

  function onSubmit(data: FormProps) {
    if (!data?.code || !data?.password || !data?.passwordConfirmation) {
      return;
    }

    if (data.password != data.passwordConfirmation) {
      NotificationsService.error({
        title: 'confirm_password_error_title',
        message: 'confirm_password_error_description',
      });
      return;
    }

    reset({
      password: data.password,
      code: data.code,
      email: selectedEmail as string,
    });
  }

  return (
    <form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.container__content}>
        <Input
          placeholder={t('common:verification_code')}
          type="text"
          name="code"
          register={register}
          registerOptions={{
            required: true,
          }}
        />
        <Input
          name="password"
          type="password"
          className={
            isValidPassword ? styles.container__content__input : undefined
          }
          placeholder={t('register:password_placeholder')}
          register={register}
          registerOptions={{
            required: true,
            onChange: handlePasswordChange,
          }}
          isError={!isValidPassword && touchedFields?.password}
          errorMessage={getPasswordError()}
          icon={isValidPassword ? 'fa-solid fa-circle-check' : undefined}
          hasPaddingRight
        />

        <Input
          name="passwordConfirmation"
          type="password"
          className={
            !!password &&
            !!passwordConfirmation &&
            passwordConfirmation === password
              ? styles.container__content__input
              : undefined
          }
          placeholder={t('register:passwordConfirmation_placeholder')}
          register={register}
          registerOptions={{
            required: true,
          }}
          isError={
            passwordConfirmation !== password &&
            touchedFields?.passwordConfirmation
          }
          errorMessage={t('notify:confirm_password_error_description')}
          icon={
            !!password &&
            !!passwordConfirmation &&
            passwordConfirmation === password
              ? 'fa-solid fa-circle-check'
              : undefined
          }
          hasPaddingRight
        />

        {dirtyFields?.password && !!passwordStrength && password !== '' && (
          <BarPasswordStrong
            forceScore={Number(passwordStrength?.score) as 0 | 1 | 2 | 3 | 4}
          />
        )}
      </div>

      <div className={styles.container__buttons}>
        <Button type="button" isSecondary onClick={handleBack}>
          {t('modal:return_button')}
        </Button>

        <Button type="submit" isloading={isPending} disabled={isDisabled}>
          {t('common:advance')}
        </Button>
      </div>
    </form>
  );
}
