import { RootState } from '@/lib/store';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSelector } from 'react-redux';
import SidebarContentCollapsed from './Collapsed';
import SidebarContentNotCollapsed from './NotCollapsed';

export default function SidebarContent() {
  const mobileSize = useMediaQuery('(max-width: 768px)');
  const isCollapsed: boolean = useSelector(
    (state: RootState) => state.sideBar.collapsed,
  );
  const collapsedSidebar = mobileSize ? false : isCollapsed;

  if (collapsedSidebar) {
    return <SidebarContentCollapsed />;
  }

  return <SidebarContentNotCollapsed />;
}
