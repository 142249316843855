import { useMutation } from '@tanstack/react-query';
import { fetchRegister } from './service';
import { AuthRegisterResponseDto } from '@/services/dto/auth/auth-register-response.dto';
import { ErrorService } from '@/services/ErrorService';
import { AxiosError } from 'axios';
import { GenericError } from '@/models/generic-error';
import { useLoginCallback } from '@/hooks/useLogInCallback';
import { setCookie } from 'cookies-next';
import { CookiesKey } from '@/enums/cookiesKey';

export function useRegister() {
  const logIn = useLoginCallback();

  function onSuccess(data: AuthRegisterResponseDto) {
    setCookie(CookiesKey.Register, true);
    logIn(data);
  }

  function onError(error: AxiosError<GenericError>) {
    ErrorService.onError(error);
  }

  const { mutate, isPending, error } = useMutation({
    mutationFn: fetchRegister,
    onSuccess,
    onError,
  });

  return { mutate, isLoading: isPending, error };
}
