export enum MineActions {
  GetAmount = 'mine/getAmount',
  PlayAgain = 'mine/playAgain',
  VerifyAmount = 'mine/verifyAmount',
  SetHalfAmount = 'mine/setHalfAmount',
  SetDoubleAmount = 'mine/setDoubleAmount',
  SetMineQuantity = 'mine/setMineQuantity',
  Reset = 'mine/reset',
}

export interface MineProps {
  amount: string;
  mineQuantity: number;
}

export interface MineBasePayload {
  type: string;
}

export type MineHalfAmountPayload = MineBasePayload;
export type MineDoubleAmountPayload = MineBasePayload;
export type MineResetPayload = MineBasePayload;
export type MinePlayAgainPayload = MineBasePayload;

export interface MineBetAmountPayload extends MineBasePayload {
  payload: {
    amount: string;
  };
}

export interface MineBetVerifyAmountPayload extends MineBasePayload {
  payload: {
    amount: string;
  };
}

export interface MineQuantityChangePayload extends MineBasePayload {
  payload: {
    mineQuantity: number;
  };
}

export type MineStatePayload =
  | MineActions
  | MineProps
  | MineHalfAmountPayload
  | MineQuantityChangePayload
  | MinePlayAgainPayload
  | MineDoubleAmountPayload
  | MineBetAmountPayload;
