import { RoundStatus } from '@/models/round/status';
import { CrashGameType } from './enums/game-type.enum';
import { CrashActions, CrashProps, CrashStatePayload } from './props';

const DEFAULT_LINES = [0, 1.2, 1.4, 1.6];

const initialState: CrashProps = {
  cashOut: '',
  lines: [],
  totalBet: '0',
  errorCloseBet: false,
  loadingCloseBet: false,
  isSocketConnected: false,
  lastWinnerBets: [],
  gameType: CrashGameType.Classic,
  isTrendOpen: false,
  round: null,
};

export default function reducer(
  state: CrashProps = initialState,
  action: CrashStatePayload,
): CrashProps {
  switch (action.type) {
    case CrashActions.Set5xCashOut:
      return {
        ...state,
        cashOut: '5',
      };
    case CrashActions.Set10xCashOut:
      return {
        ...state,
        cashOut: '10',
      };
    case CrashActions.GetCashOut:
      return {
        ...state,
        cashOut: action.payload,
      };
    case CrashActions.DisableCashOut:
      return {
        ...state,
        cashOut: '',
      };

    case CrashActions.UpdateRound: {
      const newRound = action.payload.newRound;
      const oldRound = action.payload?.oldRound;
      const multiplier = Number(newRound.crashRound?.crashpoint);

      const result: number[] = [];

      if (result.length === 0) {
        result.push(0);
      }

      result.concat(state.lines);

      result.push(multiplier / 3);
      result.push(multiplier);

      if (!oldRound && newRound.status == RoundStatus.Open) {
        return {
          ...state,
          lines: result,
          round: newRound,
        };
      }

      if (oldRound?.id != newRound.id) {
        return {
          ...state,
          lines: DEFAULT_LINES,
          lastWinnerBets: [],
          round: newRound,
        };
      } else {
        return {
          ...state,
          lines: result
            .sort((a, b) => a - b)
            .map(number => Number(number.toFixed(2))),
          round: newRound,
        };
      }
    }

    case CrashActions.InitRound: {
      if (state.lines.length > 0) {
        return {
          ...state,
        };
      }

      const multiplier = Number(action.payload);

      const result: number[] = [];

      if (result.length === 0) {
        result.push(0);
      }

      result.concat(state.lines);

      result.push(multiplier / 3);
      result.push(multiplier);

      return {
        ...state,
        lines: result
          .sort((a, b) => a - b)
          .map(number => Number(number.toFixed(2))),
        lastWinnerBets: [],
      };
    }

    case CrashActions.HandleCloseBetError: {
      return {
        ...state,
        loadingCloseBet: false,
        errorCloseBet: true,
      };
    }
    case CrashActions.HandleCloseBetLoading: {
      return {
        ...state,
        loadingCloseBet: true,
        errorCloseBet: false,
      };
    }
    case CrashActions.HandleCloseBetSuccess: {
      return {
        ...state,
        loadingCloseBet: false,
        errorCloseBet: false,
      };
    }

    case CrashActions.HandleResetCloseBet: {
      return {
        ...state,
        loadingCloseBet: false,
        errorCloseBet: false,
      };
    }

    case CrashActions.SetSocketConnection: {
      return {
        ...state,
        isSocketConnected: action.payload,
      };
    }
    case CrashActions.SetLastWinnerBet:
      const isOnState = state.lastWinnerBets.some(
        bet => bet.id === action.payload?.id,
      );

      if (isOnState) {
        return {
          ...state,
        };
      }

      return {
        ...state,
        lastWinnerBets: state.lastWinnerBets.concat(action.payload),
      };

    case CrashActions.SetGameType:
      return {
        ...state,
        gameType: action.payload,
      };
    case CrashActions.SetTrendModal:
      return {
        ...state,
        isTrendOpen: action.payload,
      };
    case CrashActions.ToggleTrendModal:
      return {
        ...state,
        isTrendOpen: !state.isTrendOpen,
      };
    case CrashActions.Reset:
      return {
        ...state,
        round: null,
      };
    default:
      return state;
  }
}
