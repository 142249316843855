import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { WalletsQueryKeys } from '../../queryKeys';
import { GenericError } from '@/models/generic-error';
import { UseWalletsMeProps } from './props';
import { Wallet } from '@/models/wallet';
import { fetchWalletsMe } from './service';
import { AuthService } from '@/services/AuthService';

export function useWalletsMe(options?: UseWalletsMeProps) {
  const {
    enabled = true,
    refetchOnMount = true,
    refetchOnWindowFocus = true,
  } = options ?? {};

  const { isPending, error, data, refetch, fetchStatus, isRefetching } =
    useQuery<Wallet, AxiosError<GenericError>>({
      queryKey: [WalletsQueryKeys.Me],
      queryFn: fetchWalletsMe,
      enabled: enabled && AuthService.isAuthorized(),
      staleTime: Infinity,
      refetchOnMount,
      refetchOnWindowFocus,
    });

  return {
    isLoading: isPending && fetchStatus !== 'idle',
    error: error,
    wallet: data,
    refetch,
    isRefetching,
  };
}
