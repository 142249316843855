/* eslint-disable react-hooks/exhaustive-deps */
import { Socket } from 'socket.io-client';
import { useSocketIoFactory } from './useSocketIoFactory';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addNotificationToasty,
  setNotificationsSocketConnection,
} from '@/lib/store/notifications/actions';
import { Notification } from '@/models/notification/notification';
import { AnyAction, Dispatch } from 'redux';
import { QueryClient, useQueryClient } from '@tanstack/react-query';
import { RootState } from '@/lib/store';
import { NotificationTypeId } from '@/enums/notificationTypeId';
import { DepositQueryKeys } from '@/api/deposit/queryKeys';
import { NotificationGroupsQueryKey } from '@/api/notification-groups/queryKey';
import { UseNotificationGroupsMeQueryKey } from '@/api/notification-groups/queries/useNotificationGroupsMe/props';
import { PaginatedResponse } from '@/models/pagination/paginated-response.dto';
import { NotificationGroupWithUnreadCount } from '@/models/notification/notificationGroupWithUnreadCount';
import { NotificationGroup } from '@/models/notification/notificationGroup';
import { NotificationsQuerykey } from '@/api/notifications/queryKey';
import { NotificationGroupId } from '@/enums/notificationGroupId';
import { useAddNewRecordToInfiniteQuery } from '@starsoft/common/hooks';

export default function useNotificationsSocket() {
  const socket: Socket | null = useSocketIoFactory({
    gateway: 'notifications',
    isProtected: true,
  });
  const dispatch: Dispatch<AnyAction> = useDispatch();
  const queryClient: QueryClient = useQueryClient();
  const isNotificationModalOpen: boolean = useSelector(
    (state: RootState) => state.notifications.open,
  );
  const addNewNotificationToInfiniteQuery: (
    notification: Notification,
  ) => void = useAddNewRecordToInfiniteQuery<Notification>([
    NotificationsQuerykey.Me,
  ]);

  const onConnect: () => void = useCallback(() => {
    socket?.emit('join');
    dispatch(setNotificationsSocketConnection(true));
  }, [socket, dispatch]);

  const onDisconnect: () => void = useCallback(() => {
    dispatch(setNotificationsSocketConnection(false));
  }, [dispatch]);

  function notificationGroupsMeUpdater(
    notificationsGroups:
      | PaginatedResponse<NotificationGroupWithUnreadCount>
      | undefined,
    updatedNotificationGroup: NotificationGroup,
  ) {
    if (!notificationsGroups) {
      return notificationsGroups;
    }

    const updatedNotificationGroupsData: NotificationGroupWithUnreadCount[] =
      notificationsGroups.data.map(notificationGroup => {
        if (notificationGroup.id !== updatedNotificationGroup?.id) {
          return notificationGroup;
        }

        const updatedUnreadCount: number = notificationGroup.unreadCount + 1;

        return {
          ...notificationGroup,
          unreadCount: updatedUnreadCount,
        };
      });

    return {
      ...notificationsGroups,
      data: updatedNotificationGroupsData,
    };
  }

  const onNotificationReceived: (notification: Notification) => void =
    useCallback(
      (notification: Notification) => {
        dispatch(addNotificationToasty(notification));

        const notificationGroupsMeQueryKey: UseNotificationGroupsMeQueryKey = [
          NotificationGroupsQueryKey.Me,
          {
            page: 1,
            limit: 20,
            groupIds: [
              NotificationGroupId.Inbox,
              NotificationGroupId.Comissions,
            ],
          },
        ];

        queryClient.setQueriesData<
          PaginatedResponse<NotificationGroupWithUnreadCount>
        >(
          {
            queryKey: notificationGroupsMeQueryKey,
          },
          (
            notificationsGroup:
              | PaginatedResponse<NotificationGroupWithUnreadCount>
              | undefined,
          ) =>
            notificationGroupsMeUpdater(
              notificationsGroup,
              notification?.type?.group,
            ),
        );

        if (notification.type.id === NotificationTypeId.Deposit) {
          queryClient.invalidateQueries({
            queryKey: [DepositQueryKeys.ById],
          });
        }

        if (isNotificationModalOpen) {
          addNewNotificationToInfiniteQuery(notification);
        }
      },
      //eslint-disable-next-line
      [dispatch, queryClient, isNotificationModalOpen],
    );

  function setServerOnEvents() {
    if (!socket) return;

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('notificationReceive', onNotificationReceived);
  }

  useEffect(setServerOnEvents, [
    socket,
    onConnect,
    onDisconnect,
    onNotificationReceived,
  ]);
}
