import { ModalsKey } from '@/enums/modalsKey';
import { useModalNavigation } from '@/hooks/modals/useModalNavigation';
import { useMutation } from '@tanstack/react-query';
import { fetchGenerateRecoveryPasswordToken } from './service';
import { GenerateRecoveryPasswordTokenInputDto } from '@/services/dto/recovery-passwords/generate-recovery-password-token-input.dto';
import { Success } from '@/models/success';
import { useDispatch } from 'react-redux';
import { setRecoverEmail } from '@/lib/store/auth/actions';

export function useGenerateRecoveryPasswordToken() {
  const { navigateToModal } = useModalNavigation();
  const dispatch = useDispatch();

  function onSuccess(_: Success, input: GenerateRecoveryPasswordTokenInputDto) {
    navigateToModal(ModalsKey.RecoverPasswordReset);
    dispatch(setRecoverEmail(input.email));
  }

  const { mutate, isPending } = useMutation({
    mutationFn: fetchGenerateRecoveryPasswordToken,
    onSuccess,
  });

  return {
    generateRecoveryPasswordToken: mutate,
    isPending,
  };
}
