export enum CoinflipActions {
  HandleAnimate = 'coinflip/handleAnimate',
  FlipCoinAnimation = 'coinflip/flipCoinAnimation',
}

export interface CoinflipProps {
  animate: boolean;
}

export interface CoinflipBasePayload {
  type: string;
}

export type HandleCoinflipSidePayload = CoinflipBasePayload;
export type HandleAnimateCoin = CoinflipBasePayload;
export type AnimateCoinFlip = CoinflipBasePayload;

export type CoinflipStatePayload = CoinflipBasePayload;
