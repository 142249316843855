import styles from '../styles.module.scss';
import skeleton from './styles.module.scss';
import { Copy } from '@package/components';

export default function ProfileDrawerContentReferAndEarnSkeleton() {
  return (
    <div className={styles.card}>
      <div className={styles.card__holder} />
      <div className={styles.card__column}>
        <div className={styles.card__column__label}>
          <div className={skeleton.label} />
        </div>
        <Copy label="" textToCopy="" isLoading />
      </div>
    </div>
  );
}
