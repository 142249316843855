import { getColumnQuantity } from '@/utils/tower/getColumnQuantity';
import {
  TowerActions,
  TowerIncrementClickedCellsPayload,
  TowerProps,
  TowerQuantityChangePayload,
  TowerSetClickedCellsPayload,
} from './props';
import { GridDifficulty } from '@/models/bet/grid/difficulty';
import { LocalStorageService } from '@/services/localStorage';
import { LocalStorageKey } from '@/enums/localStorageKey';

const initialState: TowerProps = {
  difficulty: GridDifficulty.Easy,
  columnQuantity: 4,
  clickedCells: LocalStorageService.getLocalStorageItem(
    LocalStorageKey.TowerGrid,
  ),
};

export default function reducer(
  state: TowerProps = initialState,
  action: any,
): TowerProps {
  switch (action.type) {
    case TowerActions.SetDifficulty:
      const difficulty = (action as TowerQuantityChangePayload).payload
        .difficulty;

      return {
        ...state,
        difficulty: difficulty,
        columnQuantity: getColumnQuantity(difficulty),
      };
    case TowerActions.SetClickedCells:
      LocalStorageService.setLocalStorageItem(
        LocalStorageKey.TowerGrid,
        (action as TowerSetClickedCellsPayload).payload.clickedCells,
      );

      return {
        ...state,
        clickedCells: (action as TowerSetClickedCellsPayload).payload
          .clickedCells,
      };
    case TowerActions.IncrementClickedCells:
      const clickedCells = state.clickedCells.concat(
        (action as TowerIncrementClickedCellsPayload).payload.clickedCell,
      );
      LocalStorageService.setLocalStorageItem(
        LocalStorageKey.TowerGrid,
        clickedCells,
      );

      return {
        ...state,
        clickedCells,
      };
    default:
      return state;
  }
}
