import { Device } from '@/enums/device';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from '@starsoft/common/hooks';
import { setUserDevice } from '@/lib/store/casino/actions';

export function useDetectDevice() {
  const dispatch = useDispatch();
  const isMobile: boolean | undefined = useMediaQuery('(max-width: 768px)');
  const isTablet: boolean | undefined = useMediaQuery('(max-width: 1054px)');
  const isDesktop: boolean = !isMobile && !isTablet;
  const isServer: boolean =
    typeof isMobile === 'undefined' || typeof isTablet === 'undefined';

  function handleDetectDevice() {
    if (isServer) {
      return;
    }

    if (isMobile) {
      dispatch(setUserDevice(Device.Mobile));
    } else if (isTablet) {
      dispatch(setUserDevice(Device.Tablet));
    } else if (isDesktop) {
      dispatch(setUserDevice(Device.Desktop));
    }
  }

  useEffect(handleDetectDevice, [
    isMobile,
    isServer,
    isTablet,
    isDesktop,
    dispatch,
  ]);
}
