import { LocalStorageKey } from '@/enums/localStorageKey';
import { deleteCookie, getCookie, setCookie } from 'cookies-next';

export class AudioService {
  static play(audioSrc: string, volume = 1, type = 'ogg'): void {
    if (typeof window == 'undefined') {
      return;
    }

    if (AudioService.isGamesMuted()) {
      return;
    }

    document.body.insertAdjacentHTML(
      'beforeend',
      `<audio preload="true" 
              hidden 
              autoplay 
              class="game-sound"
              controls 
              volume = "0.3"
              onended="this.remove()"
              onplaying='this.volume = ${volume}'>
            <source src="${process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL}/audios/${audioSrc}" type="audio/${type}">
       </audio>`,
    );
  }

  static playInfinite(audioSrc: string, volume = 0.3, type = 'mp3') {
    if (typeof window == 'undefined') {
      return;
    }

    if (AudioService.isGamesMuted()) {
      return;
    }

    document.body.insertAdjacentHTML(
      'beforeend',
      `<audio preload="true" 
        hidden 
        autoplay 
        className="game-sound"
        controls 
        volume = "0.3"
        loop
        onplaying='this.volume = ${volume}'>
              <source src="${process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL}/audios/${audioSrc}" type="audio/${type}">
        </audio>`,
    );
  }

  static mineGem(): void {
    this.play(`mines/gem.mp3`);
  }

  static mineBomb(): void {
    this.play(`mines/bomb.mp3`);
  }

  static mineWin(): void {
    this.play(`mines/win.mp3`);
  }

  static mineHover(): void {
    this.play(`mines/hover.mp3`);
  }

  static mineEnterBet(): void {
    this.play(`mines/enter-bet.mp3`);
  }

  static rouletteSpin(): void {
    this.play(`roulette/roulette_spin.mp3`);
  }

  static winRoulette(): void {
    this.play(`roulette/win.mp3`);
  }

  static crazyRollerBackground(): void {
    this.play(`crazyroller/default.mp3`);
  }

  static crazyRollerExplosion(): void {
    this.play(`crazyroller/explosion.mp3`);
  }

  static crazyRollerWin(): void {
    this.play(`crazyroller/win.webm`);
  }

  static plinkoStart(): void {
    this.play(`plinko/start.ogg`);
  }

  static plinkoCollapse(): void {
    this.play(`plinko/collapse.ogg`);
  }

  static plinkoEnd(): void {
    this.play(`plinko/floor.ogg`);
  }

  static diceScroll(): void {
    this.play(`dice/rolling.mp3`);
  }

  static diceWin(): void {
    this.play(`dice/win.mp3`);
  }

  static optionsClick(): void {
    this.play(`zyoptions/sound_click.ogg`);
  }

  static towerBet(): void {
    this.play(`tower/bet.mp3`);
  }

  static towerWin(): void {
    this.play(`tower/cashout.mp3`);
  }

  static towerClick(): void {
    this.play(`tower/click.mp3`);
  }

  static towerLose(): void {
    this.play(`tower/lose.mp3`);
  }

  static towerBg(): void {
    this.playInfinite(`tower/tower-bg.mp3`);
  }

  static coinflipEnterBet(): void {
    this.play(`coinflip/enter-bet.mp3`);
  }

  static coinflipFlipCoin(): void {
    this.play(`coinflip/start.mp3`);
  }

  static coinflipWin(): void {
    this.play(`coinflip/win.mp3`);
  }

  static coinflipLose(): void {
    this.play(`coinflip/lose.mp3`);
  }

  static limboBg(): void {
    this.play(`limbo/bg.mp3`);
  }

  static limboFly(): void {
    this.play(`limbo/flying.mp3`);
  }

  static limboWin(): void {
    this.play(`limbo/win.mp3`);
  }

  static limboLose(): void {
    this.play(`limbo/lose.mp3`);
  }

  static slideStart(): void {
    this.play(`slide/start.mp3`);
  }

  static slideRoll(): void {
    this.play(`slide/slide.mp3`);
  }

  static slideStopped(multiply: string): void {
    this.play(`slide/${multiply}.mp3`);
  }

  static removeAudios(): void {
    const audios: any = document.querySelectorAll('audio');
    for (const audio of audios) {
      audio.remove();
    }
  }

  static haveAudios(): boolean {
    const el = document.body.getElementsByClassName('game-sound');

    return el.length > 0;
  }

  static setGamesMute(): void {
    if (typeof window === 'undefined') {
      return;
    }

    setCookie(LocalStorageKey.GamesMuted, 'true');
  }

  static setGamesUnmute(): void {
    if (typeof window === 'undefined') {
      return;
    }

    deleteCookie(LocalStorageKey.GamesMuted);
  }

  static toggleGamesMute(): void {
    if (AudioService.isGamesMuted()) {
      AudioService.setGamesUnmute();
    } else {
      AudioService.setGamesMute();
    }
  }

  static isGamesMuted(): boolean {
    if (typeof window === 'undefined') {
      return false;
    }

    return getCookie(LocalStorageKey.GamesMuted) === 'true';
  }
}
