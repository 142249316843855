import { BaseContent } from '..';
import useTranslation from 'next-translate/useTranslation';
import WalletService from '@/services/WalletService';
import { WithdrawalNotification } from '@/models/notification/notification';
import { NotificationContentProps } from '../props';
import { TransactionExtraParams } from '@/models/notification/notificationExtraParams';

export default function WithdrawContent({
  notification,
  type,
}: NotificationContentProps<WithdrawalNotification>) {
  const { t } = useTranslation('notify');
  const extra: TransactionExtraParams = notification.extra;

  return (
    <BaseContent
      icon="fa-solid fa-sack-dollar"
      title={t('withdraw_title')}
      description={t('withdraw_approved_description', {
        amount: WalletService.maskCurrency({
          value: extra.amount,
          coinId: extra.coinId,
        }),
      })}
      type={type}
      createdAt={notification?.createdAt ?? ''}
    />
  );
}
