import { GamePreferenceKey } from '@/enums/gamesPreferenceKey';
import {
  LimboActions,
  LimboBetAmountPayload,
  LimboBetVerifyAmountPayload,
  LimboEnterBetSuccessPayload,
  LimboPredictMultiplierPayload,
  LimboProps,
} from './props';
import { AudioService } from '@/services/AudioService';

const initialState: LimboProps = {
  bet: null,
  limboMultiplier: 1,
  amount: '',
  winChance: '',
  animate: false,
  predictMultiplier:
    typeof window !== 'undefined'
      ? localStorage.getItem(GamePreferenceKey.Multiplier) != null
        ? (localStorage.getItem(GamePreferenceKey.Multiplier) as string)
        : '2'
      : '2',
};

export default function reducer(
  state: LimboProps = initialState,
  action: any,
): LimboProps {
  switch (action.type) {
    case LimboActions.GetAmount:
      return {
        ...state,
        amount: (action as LimboBetAmountPayload).payload.amount,
      };
    case LimboActions.VerifyAmount: {
      if (Number((action as LimboBetVerifyAmountPayload).payload.amount) < 0) {
        return {
          ...state,
          amount: '',
        };
      }
      return state;
    }
    case LimboActions.SetHalfAmount:
      return {
        ...state,
        amount: (Number(state.amount) / 2).toFixed(8).toString(),
      };
    case LimboActions.SetDoubleAmount:
      return {
        ...state,
        amount: (Number(state.amount) * 2).toFixed(8).toString(),
      };
    case LimboActions.EnterBetSuccess:
      if (!AudioService.isGamesMuted()) {
        AudioService.limboFly();
      }

      return {
        ...state,
        bet: (action as LimboEnterBetSuccessPayload).payload.bet,
        animate: true,
        limboMultiplier: (action as LimboEnterBetSuccessPayload).payload.bet
          ?.crashpointBet?.crashpoint as number,
      };
    case LimboActions.HandleAnimate:
      return {
        ...state,
        animate: !state.animate,
      };
    case LimboActions.HandleChangePredictMultiplier:
      localStorage.setItem(
        GamePreferenceKey.Multiplier,
        (action as LimboPredictMultiplierPayload).payload.multiplier,
      );

      return {
        ...state,
        predictMultiplier: (action as LimboPredictMultiplierPayload).payload
          .multiplier,
      };
    case LimboActions.HandleReset:
      return initialState;
    default:
      return state;
  }
}
