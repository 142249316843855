import { useQuery } from '@tanstack/react-query';
import { fetchCryptoBalance } from './service';
import { AxiosError } from 'axios';
import { GenericError } from '@/models/generic-error';
import { CryptoBalanceOutputDto } from '@/services/dto/wallets/crypto-balance-output.dto';
import { WalletsQueryKeys } from '../../queryKeys';

export function useCryptoBalance() {
  const { data, error, refetch, isPending } = useQuery<
    CryptoBalanceOutputDto,
    AxiosError<GenericError>
  >({
    queryKey: [WalletsQueryKeys.CryptoBalance],
    queryFn: fetchCryptoBalance,
  });

  return {
    data,
    error,
    refetch,
    isLoading: isPending,
  };
}
