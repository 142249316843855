import {
  AffiliatesActions,
  AffiliatesStatePayload,
  AffiliatesStateProps,
} from './props';

const initialState: AffiliatesStateProps = {
  search: '',
};

export default function affiliatesReducer(
  state: AffiliatesStateProps = initialState,
  action: AffiliatesStatePayload,
): AffiliatesStateProps {
  switch (action.type) {
    case AffiliatesActions.SetSearch:
      return {
        ...state,
        search: action.payload,
      };
    default:
      return state;
  }
}
