import baseAPI from '@/api';
import { Success } from '@/models/success';
import { UseRewardsClaimRequestDto } from '@/services/dto/user-rewards/use-rewards-claim-request.dto';

export async function fetchUserRewardsClaim({
  userRewardId,
}: UseRewardsClaimRequestDto) {
  const response = await baseAPI.patch<Success>(
    `/user-rewards/claim/${userRewardId}`,
  );
  return response.data;
}
