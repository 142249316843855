import { useUserAccount } from '@/api/user';
import styles from './styles.module.scss';
import { CircularProgress, ErrorChip, Image } from '@package/components';
import { useModalNavigation } from '@/hooks/modals/useModalNavigation';
import { ModalsKey } from '@/enums/modalsKey';
import React from 'react';
import { NotificationsService } from '@/services/NotificationsService';
import ProfileDrawerContentHeaderSkeleton from './Skeleton';
import { useRankStatusByUser } from '@/api/rank-status';
import RankLabel from '@/components/RankLabel';
import Color from 'color';

export default function ProfileDrawerContentHeader() {
  const { navigateToModal } = useModalNavigation();

  const {
    rankStatus,
    isLoading: loadingRankStatus,
    error: errorRankStatus,
    refetch: refetchRankStatus,
  } = useRankStatusByUser();
  const {
    isLoading: loadingUserAccount,
    error: errorUserAccount,
    refetch: refetchUserAccount,
    user,
  } = useUserAccount();

  const isLoading = loadingUserAccount || loadingRankStatus;
  const error = errorRankStatus || errorUserAccount;

  function handleError() {
    if (errorUserAccount) {
      refetchUserAccount();
    }

    if (errorRankStatus) {
      refetchRankStatus();
    }
  }

  function handleClick() {
    navigateToModal(ModalsKey.UserProfile, `?userSlug=${user?.player?.slug}`);
  }

  if (error) {
    return <ErrorChip action={handleError} />;
  }

  if (isLoading) {
    return <ProfileDrawerContentHeaderSkeleton />;
  }

  return (
    <div className={styles.header} onClick={handleClick}>
      <div className={styles.header__wrapper}>
        <div className={styles.header__wrapper__icon__wrapper}>
          <CircularProgress
            progress={rankStatus?.progress}
            trailColor={rankStatus?.rank?.group?.color}
            pathColor={Color(rankStatus?.rank?.group?.color)
              .alpha(0.2)
              .toString()}
            className={styles.header__wrapper__progress}
          />
          <Image
            alt={`user-avatar-${user?.id}`}
            src={user?.player?.photo}
            width={80}
            height={80}
            className={styles.header__wrapper__icon}
          />
        </div>
        <div className={styles.header__wrapper__column}>
          <h6 className={styles.header__wrapper__column__title}>
            {user?.player?.username}
          </h6>
          <RankLabel rank={rankStatus?.rank} />
        </div>
      </div>
      <i className="fa-solid fa-chevron-right" />
    </div>
  );
}
