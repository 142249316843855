import useTranslation from 'next-translate/useTranslation';
import { useDispatch, useSelector } from 'react-redux';
import { setHiddenValue } from '@/lib/store/wallet/actions';
import { RootState } from '@/lib/store';
import { Switch } from '@package/components';
import { useWalletsMe } from '@/api/wallets';
import { HideSmallsSwitchProps } from './props';

export default function HideSmallsSwitch({ flexEnd }: HideSmallsSwitchProps) {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const hiddenSmalls: boolean = useSelector(
    (state: RootState) => state.wallet.hiddenSmalls,
  );
  const { isLoading } = useWalletsMe();

  function handleSwitchStatus() {
    dispatch(setHiddenValue());
  }

  return (
    <Switch
      label={t('switch_label')}
      isChecked={hiddenSmalls}
      onChange={handleSwitchStatus}
      isLoading={isLoading}
      flexEnd={flexEnd}
    />
  );
}
