import { Notification } from '@/models/notification/notification';

export enum NotificationRowType {
  Toasty = 'Toasty',
  Row = 'Row',
}
export interface NotificationRowProps {
  notification: Notification;
  type: NotificationRowType;
}
