import { FieldPath, FieldValues } from 'react-hook-form';

function get<T extends FieldValues>(object: T, path: FieldPath<T>) {
  const keys = path.split('.');
  let result = object;
  for (const key of keys) {
    result = result?.[key];
  }
  return result;
}

export function filterOptions<T extends FieldValues, V>(
  options: T[],
  value: V,
  filterBy: FieldPath<T>[],
): T[] {
  const filteredOptions: T[] = [];
  let formatedValue = `${value}`.trimEnd().trimStart();

  for (let i = 0; i < options.length; i++) {
    const option = options[i];

    for (let j = 0; j < filterBy.length; j++) {
      const filter = filterBy[j];
      const optionValue = get(option, filter);

      if (!optionValue) continue;

      if (
        `${optionValue}`.toLowerCase().startsWith(formatedValue.toLowerCase())
      ) {
        filteredOptions.unshift(option);
        break;
      }

      if (
        `${optionValue}`.toLowerCase().includes(formatedValue.toLowerCase())
      ) {
        filteredOptions.push(option);
        break;
      }
    }
  }

  return filteredOptions;
}
