import useTranslation from 'next-translate/useTranslation';
import { OAuthButtonProps } from './props';
import { ModalsKey } from '@/enums/modalsKey';
import { AuthService } from '@/services/AuthService';
import { Image } from '@starsoft/common/components';
import styles from './styles.module.scss';
import Link from '@/components/core/Link';
import { useModal } from '@/hooks/modals/useModal';

export default function OAuthButton({
  image,
  label,
  to,
  icon,
  pageRedirect,
}: OAuthButtonProps) {
  const { t } = useTranslation('login');
  const { isOpen: isRegisterPage } = useModal(ModalsKey.Register);
  const voucher = AuthService.getVoucher();

  if (pageRedirect) {
    return (
      <Link
        className={styles.link}
        href={`${to}${isRegisterPage && voucher ? `?voucher=${voucher}` : ''}`}
      >
        {icon && <i className={`${icon} ${styles.link__icon}`} />}
        {image && (
          <Image
            src={image}
            width={24}
            height={24}
            alt={t(label)}
            secondarySkeleton
          />
        )}
        {t(label)}
      </Link>
    );
  }

  return (
    <a
      className={styles.link}
      href={`${to}${isRegisterPage && voucher ? `?voucher=${voucher}` : ''}`}
    >
      {icon && <i className={`${icon} ${styles.link__icon}`} />}
      {image && (
        <Image
          src={image}
          width={24}
          height={24}
          alt={t(label)}
          secondarySkeleton
        />
      )}
      {t(label)}
    </a>
  );
}
