import baseAPI from '@/api';
import { Success } from '@/models/success';
import { ChangePasswordInputDto } from '@/services/dto/iam/change-password-input.dto';
import { AxiosResponse } from 'axios';

export async function fetchChangePassword({
  confirmationPassword,
  ...data
}: ChangePasswordInputDto): Promise<Success> {
  const response: AxiosResponse<Success> = await baseAPI.patch<Success>(
    '/iam/change-password',
    data,
  );
  return response.data;
}
