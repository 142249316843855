import useTranslation from 'next-translate/useTranslation';
import { ReferralVoucherInputProps } from './props';
import { useToggle } from '@starsoft/common/hooks';
import { Input } from '@starsoft/common/components';
import { useFormContext } from 'react-hook-form';
import { RegisterFormProps } from '@/types/register/props';
import styles from './styles.module.scss';
import { useEffect, useState } from 'react';

export default function ReferralVoucherInput({
  isError,
  isLoading,
  hasDefaultVoucher,
  voucherData,
}: ReferralVoucherInputProps) {
  const { register, resetField, watch } = useFormContext<RegisterFormProps>();
  const voucher = watch('voucher');
  const { t } = useTranslation('register');
  const [open, setOpen] = useState<boolean>(false);

  function onErase() {
    resetField('voucher', {
      defaultValue: null,
    });
    setOpen(false);
  }

  function onMount() {
    if (!voucher || !voucherData) {
      return;
    }

    setOpen(true);
  }

  useEffect(onMount, [voucher, voucherData, setOpen]);

  return open && typeof window != 'undefined' ? (
    <Input
      name="voucher"
      register={register}
      onErase={onErase}
      showEraserButton={!hasDefaultVoucher || isError}
      showSpinner={isLoading}
      readOnly={isLoading || (hasDefaultVoucher && !isError)}
      isError={isError}
      errorMessage={t('error_voucher_label')}
      placeholder={t('promotional_code')}
      hideLock={!hasDefaultVoucher || isError}
    />
  ) : (
    <div className={styles.container} onClick={() => setOpen(true)}>
      <span className={styles.container__label}>{t('promotional_code')}</span>
      <i
        className={`fa-sharp fa-solid fa-circle-plus ${styles.container__label}`}
      />
    </div>
  );
}
