import styled, { css } from 'styled-components';
import Color from 'color';

import { CircleProps, EmojiContainerProps } from './props';
import { Image } from '@starsoft/common/components';
import { getComputedColor } from '@/utils/theme';

export const Button = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  & i {
    margin-left: 10px;
    color: ${({ theme }) => theme.vars.palette.text.secondary};
    font-size: 14px;
    line-height: 14px;
  }

  &:hover,
  &:focus {
    opacity: 0.8;
  }
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  margin-right: 10px;

  & .username {
    font-size: 11px;
    font-weight: 500;
    line-height: 11px;
    text-transform: capitalize;
    color: ${({ theme }) => theme.vars.palette.text.secondary};

    @media (max-width: 768px) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 80px;
    }
  }

  & .balance {
    display: flex;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    margin-top: 5px;
    color: ${({ theme }) => theme.vars.palette.text.primary};
  }

  @media (max-width: 768px) {
    margin-right: 4px;
    & .balance {
      font-size: 12px;
      line-height: 12px;
    }
  }

  & .highlight {
    color: ${({ theme }) => theme.vars.palette.primary.main};
  }
`;

export const PhotoContainer = styled.div<EmojiContainerProps>`
  width: 100%;
  height: 100%;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) =>
    theme.vars.palette.mode == 'dark'
      ? Color(getComputedColor(theme.vars.palette.background.paper))
          .lighten(0.5)
          .toString()
      : Color(getComputedColor(theme.vars.palette.background.default))
          .darken(0.05)
          .toString()};

  ${({ isLarge }) =>
    isLarge &&
    css`
      width: 64px;
      height: 64px;
    `}

  ${({ isSmall, isFollowing }) =>
    isSmall &&
    isFollowing &&
    css`
      background-color: ${({ theme }) => theme.vars.palette.background.paper};
      position: relative;

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 28px;
        height: 28px;
        border-radius: 50%;
        border: 2px dashed ${({ theme }) => theme.vars.palette.primary.main};
      }
    `}

    

  ${({ isFollowing, isSmall, isLarge }) =>
    isFollowing &&
    !isSmall &&
    !isLarge &&
    css`
      background-color: ${({ theme }) =>
        Color(getComputedColor(theme.vars.palette.primary.main))
          .alpha(0.8)
          .toString()};
      position: relative;

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 45px;
        height: 45px;
        border-radius: 50%;
        border: 2px dashed ${({ theme }) => theme.vars.palette.primary.main};
      }

      &:hover {
        background-color: ${({ theme }) => theme.vars.palette.secondary.main};
      }
    `}


    ${({ isHeader }) =>
    isHeader &&
    css`
      background-color: ${({ theme }) => theme.vars.palette.background.paper};
      position: relative;

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 28px;
        height: 28px;
        border-radius: 50%;
        border: 2px solid ${({ theme }) => theme.vars.palette.primary.main};

        @media (max-width: 1225px) {
          width: 34px;
          height: 34px;
        }
      }
    `}
`;

export const Photo = styled(Image)<EmojiContainerProps>`
  width: 24px;
  height: 24px;
  border-radius: 50%;

  ${({ isLarge }) =>
    isLarge &&
    css`
      width: 64px;
      height: 64px;
    `}

  ${({ isSmall }) =>
    isSmall &&
    css`
      width: 24px;
      height: 24px;
    `}

${({ isLarge, isSmall }) =>
    !isLarge &&
    !isSmall &&
    css`
      @media (max-width: 1225px) {
        width: 30px;
        height: 30px;
      }
    `}
`;

export const Circle = styled.div<CircleProps>`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  border: 1px solid
    ${({ theme }) =>
      Color(getComputedColor(theme.vars.palette.text.secondary))
        .alpha(0.15)
        .toString()};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 16px;
`;
