export enum GamesAudioActions {
  HandleSoundChange = 'gamesAudio/handleInputChange',
  HandleMuteSounds = 'gamesAudio/handleMuteSounds',
}

export interface GamesAudioStateProps {
  sound: boolean;
}

export interface GamesAudioBasePayload {
  type: string;
}

export type GamesAudioStatePayload = GamesAudioStateProps;
