import { AuthService } from '@/services/AuthService';
import { useQuery } from '@tanstack/react-query';
import { UserTagManagerEventsQueryKeys } from '../../queryKeys';
import { fetchGtmEvents } from './service';
import { GTMService } from '@/services/GTMService';
import { UseGtmEventsQueryKeyProps } from './props';
import { useEffect } from 'react';

export function useUserTagManagerEvents() {
  const page = 1;
  const limit = 20;

  const isAuthorized = AuthService.isAuthorized();

  const queryKey: UseGtmEventsQueryKeyProps = [
    UserTagManagerEventsQueryKeys.Primary,
    { page, limit },
  ];

  const { data } = useQuery({
    queryKey,
    queryFn: fetchGtmEvents,
    refetchOnWindowFocus: false,
    enabled: isAuthorized,
  });

  function handleSendGTMEvents() {
    if (
      !data?.data ||
      data?.data?.length == 0 ||
      process.env.NODE_ENV == 'development'
    ) {
      return;
    }

    for (const { type, extra } of data.data) {
      GTMService.sendEvent(type, extra);
    }
  }

  useEffect(handleSendGTMEvents, [data]);
}
