import AuthModalsOptions from '@/components/AuthModal/Content';
import BonusOverviewModal from '@/components/Bonus/BonusOverviewModal';
import ProfileDrawer from '@/components/Profile/Drawer';
import { useResolvedTheme } from '@/hooks/theme/useResolvedTheme';
import { Setting } from '@package/models';
import { ComponentTypeEnum } from '@package/src/models/setting/layout/component/type';
import dynamic from 'next/dynamic';

const LanguageModal = dynamic(() => import('@/components/LanguageModal'), {
  ssr: false,
});
const GameSearchModal = dynamic(
  () => import('@/components/Home/V2/GameSearch/Modal'),
  { ssr: false },
);
const TrendTutorialModal = dynamic(
  () => import('@/components/Crash/TrendTutorialModal'),
  { ssr: false },
);
const ClaimRewardModal = dynamic(
  () => import('@/components/Bonus/ClaimRewardModal'),
  { ssr: false },
);

const SupportModal = dynamic(() => import('@/components/NewSupportModal'), {
  ssr: false,
});

const WithdrawalModal = dynamic(
  () => import('@/components/Transactions/Modals/Withdraw'),
  {
    ssr: false,
  },
);
const WithdrawVerifyModal = dynamic(
  () => import('@/components/Withdrawal/VerifyModal'),
  { ssr: false },
);
const CancelRegisterModal = dynamic(
  () => import('@/components/RegisterCancelModal'),
  { ssr: false },
);
const TermsModal = dynamic(() => import('@/components/Refer/TermsModal'), {
  ssr: false,
});
const LuckySpinModal = dynamic(() => import('@/components/LuckySpin'), {
  ssr: false,
});

const VipLevelModal = dynamic(
  () => import('@/components/VipClub/VipLevelModal'),
  { ssr: false },
);

const LastRoundsModal = dynamic(
  () => import('@/components/core/Games/LastRounds/Modal'),
  {
    ssr: false,
  },
);

const LastBetsModal = dynamic(
  () => import('@/components/core/Games/LastBets/Modal'),
  {
    ssr: false,
  },
);
const GameReportModal = dynamic(
  () => import('@/components/core/Games/GameReportModal'),
  {
    ssr: false,
  },
);

const ProfileModal = dynamic(() => import('@/components/ProfileModal'), {
  ssr: false,
});

const UnavailableBalanceModal = dynamic(
  () => import('@/components/core/Games/UnavailableBalanceModal/'),
  {
    ssr: false,
  },
);

const WalletModal = dynamic(() => import('@/components/WalletModal'), {
  ssr: false,
});

const CoinConversionModal = dynamic(
  () => import('@/components/CoinConversionModal'),
  { ssr: false },
);
const DepositModal = dynamic(
  () => import('@/components/Transactions/Modals/Deposit'),
  { ssr: false },
);

export default function MainLayoutModals({ setting }: { setting: Setting }) {
  const { components } = useResolvedTheme(setting);
  const authComponentId = components.get(ComponentTypeEnum.Login);

  return (
    <>
      <AuthModalsOptions id={authComponentId} />
      <CancelRegisterModal />
      <BonusOverviewModal />
      <LanguageModal />
      <GameSearchModal />
      <GameReportModal />
      <WithdrawalModal />
      <DepositModal />
      <WithdrawVerifyModal />
      <TermsModal />
      <TrendTutorialModal />
      <LuckySpinModal />
      <VipLevelModal />
      <ClaimRewardModal />
      <SupportModal />
      <LastRoundsModal />
      <LastBetsModal />
      <ProfileModal />
      <UnavailableBalanceModal />
      <ProfileDrawer />
      <WalletModal />
      <CoinConversionModal />
    </>
  );
}
