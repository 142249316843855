import { SupportMessage } from '@/models/support/message';
import {
  HandleChangeSearch,
  MessageIdUploadingPayload,
  SupportActions,
  SupportBasePayload,
  UpdateMessagePayload,
  UnreadMessagesRoomsIdPayload,
  SetInitalLoadingPayload,
} from './props';

export function toggleSupportModalSize(): SupportBasePayload<SupportActions.Expand> {
  return { type: SupportActions.Expand, payload: null };
}

export function handleChangeSearchValue(value: string): HandleChangeSearch {
  return {
    type: SupportActions.HandleChangeSearch,
    payload: value,
  };
}

export function setInitialLoading(state: boolean): SetInitalLoadingPayload {
  return {
    type: SupportActions.SetInitialLoading,
    payload: state,
  };
}

export function addMessage(message: SupportMessage): UpdateMessagePayload {
  return {
    type: SupportActions.UpdateMessages,
    payload: message,
  };
}

export function setMessageIdUploading(id: string): MessageIdUploadingPayload {
  return {
    type: SupportActions.SetMessageIdUploading,
    payload: id,
  };
}

export function removeMessageIdUploading(
  id: string,
): MessageIdUploadingPayload {
  return {
    type: SupportActions.HandleRemoveImageUploadProgress,
    payload: id,
  };
}

export function addUnreadMessageRoomId(
  roomId: number,
): UnreadMessagesRoomsIdPayload {
  return {
    type: SupportActions.AddUnreadMessageRoomId,
    payload: roomId,
  };
}

export function removeUnreadMessageRoomId(
  roomId: number,
): UnreadMessagesRoomsIdPayload {
  return {
    type: SupportActions.RemoveUnreadMessageRoomId,
    payload: roomId,
  };
}

export function resetUnreadMessagesRoomId(): SupportBasePayload<SupportActions.ResetMessagesRoomsId> {
  return {
    type: SupportActions.ResetMessagesRoomsId,
    payload: null,
  };
}
