import { Socket } from 'socket.io-client';
import { useSocketIoFactory } from '../useSocketIoFactory';
import { useCallback, useEffect } from 'react';
import { CreateRoomResponseDto } from '@/services/dto/support/create-room-response.dto';
import { useDispatch } from 'react-redux';
import { QueryClient, useQueryClient } from '@tanstack/react-query';
import {
  addUnreadMessageRoomId,
  resetUnreadMessagesRoomId,
} from '@/lib/store/support/actions';
import { SupportChatQueryKeys } from '@/api/support-chat/queryKeys';

export default function useSupportNotificationSocket() {
  const socket: Socket | null = useSocketIoFactory({
    gateway: 'support-chat',
    isProtected: true,
  });
  const dispatch = useDispatch();
  const queryClient: QueryClient = useQueryClient();

  const onChatNotification = useCallback(
    ({ roomId }: CreateRoomResponseDto) => {
      dispatch(addUnreadMessageRoomId(roomId));
      queryClient.invalidateQueries({
        queryKey: [SupportChatQueryKeys.Primary],
      });
    },
    [dispatch, queryClient],
  );

  const onConnect = useCallback(() => {
    socket?.emit('join');
  }, [socket]);

  function setServerOnEvents() {
    if (!socket) return;

    socket.on('connect', onConnect);
    socket.on('chat-notification', onChatNotification);
  }

  useEffect(setServerOnEvents, [socket, onConnect, onChatNotification]);

  function onUnmount() {
    return () => {
      dispatch(resetUnreadMessagesRoomId());
    };
  }

  useEffect(onUnmount, [dispatch]);
}
