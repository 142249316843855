import { RootState } from '@/lib/store';
import { useSelector } from 'react-redux';
import { platformAssets } from '@/utils/assets';
import Tab from '../Tab';
import Link from '@/components/core/Link';
import styles from './styles.module.scss';
import { Image } from '@starsoft/common/components';
import HeaderAuthButtons from './Buttons/Auth';
import HeaderCommonButtons from './Buttons/Common';
import BalanceButton from '../BalanceButton';
import GameSearchButton from './GameSearchButton';
import { useShowNavigation } from '@/hooks/useShowNavigation';
import SlotBackButton from './SlotBackButton';
import { AuthService } from '@/services/AuthService';

function Header() {
  const collapsed: boolean = useSelector(
    (state: RootState) => state.sideBar.collapsed,
  );
  const showNavigation = useShowNavigation();

  return (
    <header
      suppressHydrationWarning
      className={`${styles.container} ${collapsed ? styles['container--collapsed'] : ''}`}
    >
      <div className={styles.container__content}>
        <div className={styles.container__content__mobile}>
          {showNavigation ? (
            <Link href="/">
              <Image
                format="square"
                width={65}
                height={65}
                className={styles.container__content__mobile__logo}
                src={platformAssets.logo.icon}
                alt="mobile-logo"
              />
            </Link>
          ) : (
            <SlotBackButton />
          )}
        </div>

        <div className={styles.container__content__wrapper}>
          <div
            className={`${styles.container__content__tab_wrapper} ${!collapsed ? styles['container__content__tab_wrapper'] : ''}`}
          >
            <Tab />
          </div>
        </div>
        {AuthService.isAuthorized() && <BalanceButton />}

        <div className={styles.container__content__row}>
          <GameSearchButton />
          <HeaderAuthButtons />
          <HeaderCommonButtons />
        </div>
      </div>
    </header>
  );
}

export default Header;
