import styles from '../styles.module.scss';
import skeleton from './styles.module.scss';
import { Image } from '@package/components';
import React from 'react';
import RankLabel from '@/components/RankLabel';

export default function ProfileDrawerContentHeaderSkeleton() {
  return (
    <div className={styles.header}>
      <div className={styles.header__wrapper}>
        <div className={styles.header__wrapper__icon__wrapper}>
          <Image
            width={80}
            height={80}
            secondarySkeleton
            alt=""
            className={styles.header__wrapper__icon}
          />
        </div>
        <div className={styles.header__wrapper__column}>
          <h6 className={styles.header__wrapper__column__title}>
            <div className={skeleton.name} />
          </h6>
          <p className={styles.header__wrapper__column__id}>
            <RankLabel rank={{} as any} loading />
          </p>
        </div>
      </div>
      <div className={skeleton.icon} />
    </div>
  );
}
