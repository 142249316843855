import { pageSections } from '@/constants/sections';
import SidebarNotCollapsedItem from './Item';
import SupportSection from './SupportSection';
import ReferSection from './ReferSection';
import LogoutSection from './LogoutSection';
import VipClubSection from './VipClubSection';
import { StaggerAnimation } from '@starsoft/common/src/components/Animation/Stagger';
import TournamentSection from './TournamentSection';

export default function SidebarContentNotCollapsed() {
  return (
    <StaggerAnimation direction="column" stagger={0.1} staggerDirection="up">
      {pageSections.map((section, i) => (
        <SidebarNotCollapsedItem
          key={`sidebar_section_item_${i}`}
          section={section}
        />
      ))}

      <VipClubSection />
      <TournamentSection />
      <ReferSection />
      <SupportSection />
      <LogoutSection />
    </StaggerAnimation>
  );
}
