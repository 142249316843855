import { pages } from '@/constants/sections';
import SidebarContentCollapsedPage from './Page';
import ReferPage from './ReferPage';
import SupportPage from './SupportPage';
import LogoutPage from './LogoutPage';
import VipClubPage from './VipClubPage';
import { StaggerAnimation } from '@starsoft/common/src/components/Animation/Stagger';

export default function SidebarContentCollapsed() {
  return (
    <StaggerAnimation direction="column" stagger={0.25} staggerDirection="down">
      {pages.map(page => (
        <SidebarContentCollapsedPage
          key={`sidebar_collapsed_page_${page.label}`}
          page={page}
        />
      ))}
      <VipClubPage />
      <SidebarContentCollapsedPage
        page={{
          label: 'tournaments_label',
          icon: 'fa-solid fa-trophy',
          paths: ['/tournaments'],
        }}
        isHightligh
      />
      <ReferPage />
      <SupportPage />
      <LogoutPage />
    </StaggerAnimation>
  );
}
