import { usePathname } from '@/hooks/usePathname';
import { BackButton } from '@starsoft/common/components';
import { useRouter } from 'next/router';

export default function SlotBackButton() {
  const { query } = useRouter();
  const pathname: string = usePathname();
  const mode = query.mode;
  const { push } = useRouter();

  function handleGoBack() {
    push(pathname.replace(`/${mode}`, ''));
  }

  return <BackButton onClick={handleGoBack} />;
}
