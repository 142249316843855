import styled from 'styled-components';

export const Container = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.vars.palette.background.default};
  display: flex;
  z-index: 2;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -8px;
  right: -8px;
`;

export const Icon = styled.i`
  font-size: 10px;
  color: ${({ theme }) => theme.vars.palette.text.secondary};
`;
