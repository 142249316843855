import { Notification } from '@/models/notification/notification';
import { NotificationGroupId } from '@/enums/notificationGroupId';

export enum NotificationsActions {
  SetOpen = 'notifications/setOpen',
  SetTab = 'notifications/setCurrentTab',
  SetSocketConnection = 'notifications/setSocketConnection',
  AddNotification = 'notifications/addNotification',
  RemoveNotification = 'notifications/removeNotification',
}

export interface NotificationsStateProps {
  isSocketConnected: boolean;
  notificationsToasty: Notification[];
  tab: NotificationGroupId;
  open: boolean;
}

export interface NotificationsBasePayload<
  T extends NotificationsActions,
  V = null,
> {
  type: T;
  payload: V;
}

export type SetOpenPayload = NotificationsBasePayload<
  NotificationsActions.SetOpen,
  boolean
>;

export type SetTabPayload = NotificationsBasePayload<
  NotificationsActions.SetTab,
  NotificationGroupId
>;

export type SetSocketConnectionPayload = NotificationsBasePayload<
  NotificationsActions.SetSocketConnection,
  boolean
>;

export type AddNotificationPayload = NotificationsBasePayload<
  NotificationsActions.AddNotification,
  Notification
>;

export type RemoveNotificationPayload = NotificationsBasePayload<
  NotificationsActions.RemoveNotification,
  number
>;

export type NotificationsPayload =
  | SetOpenPayload
  | SetTabPayload
  | SetSocketConnectionPayload
  | AddNotificationPayload
  | RemoveNotificationPayload;
