import baseAPI from '@/api';
import { AuthRegisterResponseDto } from '@/services/dto/auth/auth-register-response.dto';
import { RegisterFormProps } from '@/types/register/props';

export async function fetchRegister({
  email,
  password,
  voucher,
}: RegisterFormProps) {
  const response = await baseAPI.post<AuthRegisterResponseDto>('iam/register', {
    email,
    voucher,
    password,
  });

  return response.data;
}
