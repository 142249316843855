import { useForm } from 'react-hook-form';
import styles from './styles.module.scss';
import { GenerateRecoveryPasswordTokenInputDto } from '@/services/dto/recovery-passwords/generate-recovery-password-token-input.dto';
import { useModalNavigation } from '@/hooks/modals/useModalNavigation';
import { ModalsKey } from '@/enums/modalsKey';
import { Button, Input } from '@package/components';
import useTranslation from 'next-translate/useTranslation';
import { useGenerateRecoveryPasswordToken } from '@/api/recovery-passwords';

export default function GenerateRecoveryPasswordToken() {
  const { t } = useTranslation();
  const { watch, register, handleSubmit } =
    useForm<GenerateRecoveryPasswordTokenInputDto>();
  const { navigateToModal } = useModalNavigation();
  const { generateRecoveryPasswordToken, isPending } =
    useGenerateRecoveryPasswordToken();
  const email: string | undefined = watch('email');

  function handleBack() {
    navigateToModal(ModalsKey.Login);
  }

  function onSubmit(data: GenerateRecoveryPasswordTokenInputDto) {
    if (!data?.email) {
      return;
    }

    generateRecoveryPasswordToken(data);
  }

  return (
    <form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
      <Input
        name="email"
        type="text"
        placeholder={t('register:email_placeholder')}
        register={register}
        registerOptions={{
          required: true,
        }}
      />

      <div className={styles.container__buttons}>
        <Button type="button" isSecondary onClick={handleBack}>
          {t('modal:return_button')}
        </Button>

        <Button type="submit" isloading={isPending} disabled={!email}>
          {t('common:advance')}
        </Button>
      </div>
    </form>
  );
}
