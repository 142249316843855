import styled, { css } from 'styled-components';
import Color from 'color';
import { TabItemProps, TabProps } from './props';
import Link from '@/components/core/Link';
import { getComputedColor } from '@/utils/theme';

export const TabContainer = styled.div<TabProps>`
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 8px;

  ${({ isSecondary }) =>
    isSecondary &&
    css`
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
      overflow-x: unset;
    `}

  @media(max-width: 768px) {
    width: 100%;
  }
`;

export const TabItem = styled(Link)<TabItemProps>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  position: relative;
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme, selected }) =>
    selected
      ? theme.vars.palette.text.primary
      : theme.vars.palette.text.primary} !important;
  background: ${({ theme, selected }) =>
    selected
      ? Color(getComputedColor(theme.vars.palette.background.paper))
          .lighten(0.3)
          .toString()
      : 'transparent'};
  border: 1px solid
    ${({ theme, selected }) =>
      selected
        ? Color(getComputedColor(theme.vars.palette.background.paper))
            .lighten(0.3)
            .toString()
        : 'transparent'};
  border-radius: ${({ theme }) => theme.vars.shape.borderRadius};
  cursor: pointer;
  transition:
    color 0.25s ease-in-out,
    border 0.25s ease-in-out,
    background-color 0.25s ease-in-out;
  height: 40px;
  padding: 0 8px;
  pointer-events: ${({ active }) => (active ? 'all' : 'none')};
  opacity: ${({ active }) => (active ? 1 : 0.7)};
  white-space: nowrap;

  &:hover {
    color: ${({ theme }) => theme.vars.palette.text.primary};
    background: ${({ theme }) =>
      Color(getComputedColor(theme.vars.palette.background.paper))
        .lighten(0.3)
        .toString()};
    border: 1px solid
      ${({ theme }) =>
        Color(getComputedColor(theme.vars.palette.background.paper))
          .lighten(0.3)
          .toString()};

    & i {
      color: ${({ theme }) => theme.vars.palette.primary.main};
    }
  }

  & i {
    transition: color 0.25s ease-in-out;
    color: ${({ theme, selected }) =>
      selected
        ? theme.vars.palette.primary.main
        : theme.vars.palette.text.secondary};
  }

  ${({ isSecondary }) =>
    isSecondary &&
    css`
      padding: 0;
    `}
`;

export const CommingSoon = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  color: ${({ theme }) => theme.vars.palette.primary.main};
  font-size: 12px;
  font-weight: 700;
`;
