import { useCallback, useEffect, useState } from 'react';
import { BadgeProps } from '../props';
import { Progress } from './styles';

export default function BadgeProgress({ reward }: BadgeProps) {
  const [progress, setProgress] = useState(0);

  const getProgress = useCallback(() => {
    if (!reward?.userReward) {
      return 100;
    }

    const now: number = +new Date();
    const createdAt: number = +new Date(reward?.userReward.createdAt);

    const totalDuration: number =
      +new Date(reward?.userReward.redeemableAt) - createdAt;
    const elapsedTime: number = now - createdAt;
    const percentagePassed: number = (elapsedTime / totalDuration) * 100;

    return Math.min(100, percentagePassed);
  }, [reward]);

  function handleInterval() {
    setProgress(getProgress());

    const timer = setInterval(() => {
      setProgress(getProgress());
    }, 60 * 1000);

    return () => {
      clearInterval(timer);
    };
  }

  useEffect(handleInterval, [reward, getProgress]);

  return <Progress progress={progress} />;
}
