import { ModalsKey } from '@/enums/modalsKey';
import { useModalNavigation } from '@/hooks/modals/useModalNavigation';
import { useMutation } from '@tanstack/react-query';
import { fetchRecoveryPasswordReset } from './service';
import { NotificationsService } from '@/services/NotificationsService';

export function useRecoveryPasswordReset() {
  const { navigateToModal } = useModalNavigation();

  function onSuccess() {
    NotificationsService.success({
      title: 'recover_password_title',
      message: 'change_password_success_text',
    });
    navigateToModal(ModalsKey.Login);
  }

  const { mutate, isPending } = useMutation({
    mutationFn: fetchRecoveryPasswordReset,
    onSuccess,
  });

  return {
    reset: mutate,
    isPending,
  };
}
