import baseAPI from '@/api';
import { Success } from '@/models/success';
import { SendVerificationCodeByEmailInputDto } from '@/services/dto/iam/send-verification-code-by-email-input.dto';
import { AxiosResponse } from 'axios';

export async function fetchSendIamCodeByEmail(
  data: SendVerificationCodeByEmailInputDto,
): Promise<Success> {
  const response: AxiosResponse<Success> = await baseAPI.post<Success>(
    '/iam/send-code-by-email',
    data,
  );
  return response.data;
}
