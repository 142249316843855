import useTranslation from 'next-translate/useTranslation';
import { ProfileDrawerContentNavigationItemProps } from './props';
import styles from './styles.module.scss';
import Link from '@/components/core/Link';
import { useIsPageActive } from '@/hooks/useIsPageActive';
import { ModalsKey } from '@/enums/modalsKey';
import useTransactionsNavigation from '@/hooks/useTransacionsNavigation';

export default function ProfileDrawerContentNavigationItem({
  item,
}: ProfileDrawerContentNavigationItemProps) {
  const { t } = useTranslation('common');
  const isActive: boolean = useIsPageActive(item);
  const { getModalSearchParams } = useTransactionsNavigation();

  if (item?.hidden) {
    return null;
  }

  return (
    <Link
      className={`${styles.item} ${isActive ? styles['item--selected'] : ''}`}
      privateRoute={item.isProtected}
      href={
        [ModalsKey.Deposit, ModalsKey.Withdraw].includes(item?.modal)
          ? getModalSearchParams()
          : item.paths?.[0]
      }
      modal={item.modal}
      onClick={() => item?.clickHandler?.()}
    >
      <div className={styles.item__wrapper}>
        <div className={styles.item__icon}>
          <i className={item?.icon} />
        </div>
        <div className={styles.item__label}>{t(item?.label)}</div>
      </div>
      <div className={styles.item__chevron}>
        <i className="fa-solid fa-chevron-right" />
      </div>
    </Link>
  );
}
