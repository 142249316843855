import { useMemo } from 'react';
import styles from './styles.module.scss';
import newNavigation from '@/constants/sections/pages/profile-poppover-pages';
import ProfileDrawerContentNavigationItem from '../Item';

export default function ProfileDrawerContentNavigationCommon() {
  const Paths = useMemo(
    () =>
      newNavigation.map(page => (
        <ProfileDrawerContentNavigationItem
          item={page}
          key={`profile-navigation-${page?.icon}`}
        />
      )),
    [],
  );

  return <div className={styles.container}>{Paths}</div>;
}
