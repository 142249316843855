import { FollowMode } from '@/services/dto/follow/follow-mode';
import {
  InvestmentStateProps,
  InvestmentActions,
  SetInvestmentCoinPayload,
  SetInvestmentFromOptionPayload,
  SetInvestmentToOptionPayload,
  SetInvestmentAmountPayload,
  SetInvestmentMarginPayload,
  SetFollowModePayload,
  SetSearchPayload,
  SetHasSeenTutorialPayload,
  SetTutorialStepPayload,
} from './props';
import { ListOptions } from './enum/listOptions.enum';
import { LocalStorageKey } from '@/enums/localStorageKey';
import { followBetSteps } from '@/constants/follow-bet-steps';

const options = [
  {
    label: ListOptions.Main,
    value: false,
  },
  {
    label: ListOptions.Investment,
    value: true,
  },
];

const initialState: InvestmentStateProps = {
  showForm: false,
  investmentCoin: null,
  listOptions: options,
  from: options[0],
  to: options[1],
  switch: [],
  mode: FollowMode.All,
  amount: '',
  margin: '100',
  search: '',
  betAmount: '0.20',
  hasSeenTutorial:
    typeof window !== 'undefined'
      ? !!localStorage.getItem(LocalStorageKey.HasSeenFollowBetTutorial)
      : false,
  tutorialStep: 0,
};

export default function investmentReducer(
  state: InvestmentStateProps = initialState,
  action: any,
): InvestmentStateProps {
  switch (action.type) {
    case InvestmentActions.ShowInvestmentForm:
      return {
        ...state,
        showForm: !state.showForm,
      };
    case InvestmentActions.SetInvestmentCoin:
      return {
        ...state,
        investmentCoin: (action as SetInvestmentCoinPayload).payload.coin,
      };
    case InvestmentActions.HandleFromOption: {
      const from = (action as SetInvestmentFromOptionPayload).payload.option;

      return {
        ...state,
        to: state.to == from ? state.from : state.to,
        from,
      };
    }
    case InvestmentActions.HandleToOption: {
      const to = (action as SetInvestmentToOptionPayload).payload.option;

      return {
        ...state,
        from: state.from == to ? state.to : state.from,
        to,
      };
    }
    case InvestmentActions.SwitchOptions: {
      const switchArr = [state.from, state.to];

      return {
        ...state,
        from: switchArr[1],
        to: switchArr[0],
      };
    }
    case InvestmentActions.SetAmount: {
      return {
        ...state,
        amount: (action as SetInvestmentAmountPayload).payload.amount,
      };
    }
    case InvestmentActions.SetMargin: {
      const margin = (action as SetInvestmentMarginPayload).payload.margin;

      if (+margin >= 100) {
        return {
          ...state,
          margin: '100',
        };
      }

      return {
        ...state,
        margin,
      };
    }

    case InvestmentActions.IncrementMargin: {
      if (Number(state.margin) + 1 > 100) {
        return {
          ...state,
          margin: '100',
        };
      }
      return {
        ...state,
        margin: (Number(state.margin) + 1).toFixed(2),
      };
    }
    case InvestmentActions.DecrementMargin: {
      if (Number(state.margin) - 1 < 0) {
        return {
          ...state,
          margin: '0',
        };
      }
      return {
        ...state,
        margin: (Number(state.margin) - 1).toFixed(2),
      };
    }
    case InvestmentActions.SetFollowMode:
      return {
        ...state,
        mode: (action as SetFollowModePayload).payload.mode,
      };

    case InvestmentActions.SetSearch:
      return {
        ...state,
        search: (action as SetSearchPayload).payload.search,
      };

    case InvestmentActions.SetBetAmount:
      return {
        ...state,
        betAmount: (action as SetInvestmentAmountPayload).payload.amount,
      };
    case InvestmentActions.SetHasSeenTutorial: {
      if (!state.hasSeenTutorial) {
        localStorage.setItem(LocalStorageKey.HasSeenFollowBetTutorial, 'true');
      } else {
        localStorage.removeItem(LocalStorageKey.HasSeenFollowBetTutorial);
      }

      return {
        ...state,
        hasSeenTutorial: (action as SetHasSeenTutorialPayload).payload,
      };
    }
    case InvestmentActions.SetTutorialStep: {
      const step = (action as SetTutorialStepPayload).payload;

      if (step > followBetSteps.length - 1) {
        return {
          ...state,
        };
      }

      return {
        ...state,
        tutorialStep: step,
      };
    }

    default:
      return state;
  }
}
