import { UserTagManagerEventsQueryKeys } from '@/api/user-tag-manager-events/queryKeys';
import { CookiesKey } from '@/enums/cookiesKey';
import { useQueryClient } from '@tanstack/react-query';
import { deleteCookie, hasCookie } from 'cookies-next';
import { useCallback } from 'react';

export function useAccountCallback() {
  const queryClient = useQueryClient();

  const callback = useCallback(() => {
    if (!hasCookie(CookiesKey.Register)) {
      return;
    }

    queryClient.invalidateQueries({
      queryKey: [UserTagManagerEventsQueryKeys.Primary],
    });

    deleteCookie(CookiesKey.Register);
  }, [queryClient]);

  return callback;
}
