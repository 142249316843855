import { QueryClient, useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect } from 'react';
import { Socket } from 'socket.io-client';
import { useUpdateWalletAndAccounts } from '../useUpdateWalletAndAccounts';
import { useSocketIoFactory } from './useSocketIoFactory';
import { AccountUpdateDto } from '@/services/dto/accounts/account-update.dto';

export default function useAccountsSocket() {
  const socket: Socket | null = useSocketIoFactory({
    gateway: 'accounts',
    isProtected: true,
  });
  const onUpdate: (data: AccountUpdateDto) => void =
    useUpdateWalletAndAccounts();
  const queryClient: QueryClient = useQueryClient();

  const onConnect = useCallback(() => {
    socket?.emit('join');
  }, [socket]);

  function setServerOnEvents() {
    if (!socket) return;

    socket.on('connect', onConnect);
    socket.on('update', onUpdate);
  }

  useEffect(setServerOnEvents, [socket, queryClient, onConnect, onUpdate]);
}
