export enum GameID {
  Crash = 1,
  Double = 2,
  Mines = 3,
  Dice = 4,
  Tower = 5,
  Coinflip = 6,
  Limbo = 7,
  Slide = 8,
  Frost = 9,
  CrashTrenball = 10,
  Splash = 11,
}
