import { ModalsKey } from '@/enums/modalsKey';
import { PageProps } from '@/types/page/props';

export const navigationSettingsPaths: Array<PageProps> = [
  {
    label: 'tab_language',
    paths: [],
    modal: ModalsKey.Language,
    icon: 'fa-solid fa-globe',
  },
  {
    label: 'tab_currency',
    paths: [],
    modal: ModalsKey.CoinConversion,
    icon: 'fa-solid fa-dollar-sign',
  },
  // {
  //   label: 'tab_theme',
  //   paths: [],
  //   modal: ModalsKey.Language,
  //   icon: 'fa-solid fa-sun',
  // },
];
