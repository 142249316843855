import { Chain } from '@/models/Chain';
import { Coin } from '@/models/coin';

export enum DepositActions {
  SetCoin = 'deposit/set-coin',
  SetChain = 'deposit/set-chain',
}

export interface DepositProps {
  currentCoin: Coin | null;
  currentChain: Chain | null;
}

export interface DepositBasePayload<T extends DepositActions, K> {
  type: T;
  payload: K;
}

export interface DepositSetCoinPayload
  extends DepositBasePayload<DepositActions.SetCoin, Coin | null> {}

export interface DepositSetChainPayload
  extends DepositBasePayload<DepositActions.SetChain, Chain | null> {}

export type DepositStatePayload =
  | DepositSetCoinPayload
  | DepositSetChainPayload;
