import { Reward } from '@/models/userReward/reward';
import { UserRewardStatus } from '@/models/userReward/user-reward-status.enum';
import { ModalsKey } from '@/enums/modalsKey';
import { RewardId } from '@/enums/reward-id';
import { useUserAccount } from '@/api/user/queries';
import { useCallback } from 'react';
import { useModalNavigation } from './modals/useModalNavigation';
import { useCountdown } from '@starsoft/common/src/hooks/useCountdown';

export function useRewardState(reward?: Reward) {
  const { user } = useUserAccount();
  const isLuckyWheel = reward?.id === RewardId.LuckyWheel;
  const isDisabled = !reward?.userReward && !isLuckyWheel;
  const { getModalLink } = useModalNavigation();
  const { hours, minutes, seconds } = useCountdown(
    reward?.userReward?.redeemableAt as string,
    !reward?.userReward ||
      (!!reward?.userReward &&
        reward?.userReward?.status !== UserRewardStatus.Pending),
  );

  const getLink = useCallback(() => {
    if (!user) {
      return getModalLink(ModalsKey.Login);
    }

    switch (reward?.id) {
      case RewardId.LuckyWheel:
        return getModalLink(
          ModalsKey.LuckySpin,
          `?reward-id=${reward?.id}&mode=1`,
        );
      default:
        return getModalLink(ModalsKey.ClaimReward, `?reward-id=${reward?.id}`);
    }
  }, [getModalLink, user, reward]);

  return {
    isDisabled,
    timer: {
      hours,
      minutes,
      seconds,
    },
    getLink,
  };
}
