import { useMemo, useState } from 'react';
import { UseBonusRewardTypesStatisticsQueryKeyProps } from './props';
import { BonusRewardTypesQueryKeys } from '../../queryKeys';
import { useQuery } from '@tanstack/react-query';
import { PaginatedResponse } from '@/models/pagination/paginated-response.dto';
import { fetchBonusRewardTypesStatistics } from './service';
import { AxiosError } from 'axios';
import { GenericError } from '@/models/generic-error';
import { PaginatedRequest } from '@/models/pagination/paginated-request';
import { BonusRewardType } from '@/models/bonusReward/bonusRewardType';
import { usePaginatedQuery } from '@starsoft/common/hooks';

export function useBonusRewardTypesStatistics() {
  const [page, setPage] = useState<number>(1);

  const filter: PaginatedRequest = {
    limit: 50,
    page,
  };

  const queryKey: UseBonusRewardTypesStatisticsQueryKeyProps = [
    BonusRewardTypesQueryKeys.Statistics,
    filter,
  ];

  const { data, isPending, error, refetch } = useQuery<
    PaginatedResponse<BonusRewardType>,
    AxiosError<GenericError>,
    PaginatedResponse<BonusRewardType>,
    UseBonusRewardTypesStatisticsQueryKeyProps
  >({
    queryKey,
    queryFn: fetchBonusRewardTypesStatistics,
    staleTime: 20 * 1000,
  });

  const { fetchNextPage, fetchPreviousPage, setCurrentPage } =
    usePaginatedQuery({
      data,
      page,
      setPage,
    });

  const parsedData = useMemo(() => data?.data.flatMap(v => v), [data]);

  const totalBonus = useMemo(
    () =>
      parsedData?.reduce((prev, bonus) => Number(prev) + bonus.bonusAmount, 0),
    [parsedData],
  );

  return {
    bonusRewardTypesStatistics: parsedData,
    totalBonus,
    isLoadingBonusRewardTypesStatistics: isPending,
    errorBonusRewardTypesStatistics: error,
    refetchBonusRewardTypesStatistics: refetch,
    fetchNextPage,
    fetchPreviousPage,
    setCurrentPage,
  };
}
