import { AuthStep } from '@/enums/auth/authStep';
import { ModalsKey } from '@/enums/modalsKey';
import { useCurrentModal } from '@/hooks/modals/useCurrentModal';
import { useSearchParams } from '@package/hooks';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { AuthSearchParamValues } from './props';

export default function useAuthSearchParams() {
  const search: URLSearchParams = useSearchParams();
  const step: AuthStep = (search.get('step') as AuthStep) ?? AuthStep.Email;
  const { push } = useRouter();
  const modal: ModalsKey | undefined = useCurrentModal();

  const handleSetAuthSearchParams = useCallback(
    <K extends keyof AuthSearchParamValues>(
      searchKey: K,
      value: AuthSearchParamValues[K],
    ) => {
      search.set(searchKey, value);
      push({
        search: search.toString(),
        hash: modal,
      });
    },
    [search, push, modal],
  );

  return {
    handleSetAuthSearchParams,
    step,
  };
}
