import { GenericError } from '@/models/generic-error';
import { Bet } from '@/models/bet';
import { Round } from '@/models/round';
import { DiceSide } from '@/models/bet/dice/side';

export enum DiceActions {
  EnterBet = 'dice/enterBet',
  EnterBetSuccess = 'dice/enterBetSuccess',
  EnterBetError = 'dice/enterBetError',
  GetAmount = 'dice/getAmount',
  VerifyAmount = 'dice/verifyAmount',

  SetWinChange = 'dice/setWinChange',
  GetMultipliers = 'dice/getMultipliers',
  GetMultipliersSuccess = 'dice/getMultipliersSuccess',
  GetMultipliersError = 'dice/getMultipliersError',
  HandleChangeMultiplier = 'dice/handleChangeMultiplier',
  HandleChangeWinChance = 'dice/handleChangeWinChance',
  HandleChangeDiceSide = 'dice/handleChangeDiceSide',
  HandleAnimate = 'dice/handleAnimate',
}

// export enum DiceSide {
//   UP = 'up',
//   DOWN = 'down',
// }
export interface MultipliersProps {
  [key: number]: number;
}

export interface DiceProps {
  loading: boolean;
  betLoading: boolean;
  error: GenericError | false;
  bet: Bet | null;
  amount: string;
  round: Round | null;
  winChance: number;
  animate: boolean;
  multipliers: MultipliersProps;
  multiplier: number;
  lastPosition: number | null;
  currentPosition: number;
  profitMultiplier: number;
  diceSide: DiceSide;
}

export interface DiceBasePayload {
  type: string;
}

export type HandleDiceSidePayload = DiceBasePayload;

export type DiceGetMultipliers = DiceBasePayload;

export interface DiceGetMultipliersSuccessPayload extends DiceBasePayload {
  payload: {
    multipliers: MultipliersProps;
  };
}

export interface DiceHandleWinChance extends DiceBasePayload {
  payload: {
    winChance: number;
  };
}

export interface DiceHandleChangeMultiplier extends DiceBasePayload {
  payload: {
    multiplier: number;
  };
}

export interface DiceGetMultipliersFailPayload extends DiceBasePayload {
  payload: GenericError;
}

export interface DiceBetAmountPayload extends DiceBasePayload {
  payload: {
    amount: string;
  };
}

export interface DiceBetVerifyAmountPayload extends DiceBasePayload {
  payload: {
    amount: string;
  };
}

export interface DiceEnterBetPayload extends DiceBasePayload {
  payload: {
    amount: string;
    multiplier: number;
    side: DiceSide;
    coinId: number;
  };
}

export interface DiceEnterBetFailPayload extends DiceBasePayload {
  payload: GenericError;
}

export interface DiceEnterBetSuccessPayload extends DiceBasePayload {
  payload: {
    bet: Bet;
  };
}

export type DiceStatePayload =
  | DiceBasePayload
  | DiceBetAmountPayload
  | DiceBetVerifyAmountPayload
  | DiceEnterBetPayload
  | DiceEnterBetFailPayload
  | DiceEnterBetSuccessPayload;
