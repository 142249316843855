import SidebarContentCollapsedPage from '../Page';

export default function VipClubPage() {
  return (
    <SidebarContentCollapsedPage
      page={{
        label: 'vip_club_title',
        icon: 'fa-solid fa-crown',
        paths: [`/vip-club`],
        isProtected: false,
      }}
      isHightligh
    />
  );
}
