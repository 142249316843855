import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { PropsWithChildren } from 'react';
import { themeConfig } from '@/styles/theme/theme';

export default function ThemeProvider({ children }: PropsWithChildren) {
  const theme = themeConfig;
  return (
    <CssVarsProvider defaultMode="dark" theme={theme}>
      <StyledThemeProvider theme={theme as any}>{children}</StyledThemeProvider>
    </CssVarsProvider>
  );
}
