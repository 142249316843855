import { GamePreferenceKey } from '@/enums/gamesPreferenceKey';
import {
  GamesPreferencesActions,
  GamesPreferencesStateProps,
  HandleChangeAmountPayload,
  HandleChangeMultiplierPayload,
} from './props';

const initialState: GamesPreferencesStateProps = {
  amount: Number(
    typeof window !== 'undefined'
      ? localStorage.getItem(GamePreferenceKey.Amount) != null
        ? (localStorage.getItem(GamePreferenceKey.Amount) as string)
        : 0
      : 0,
  ),
  multiplier:
    typeof window !== 'undefined'
      ? localStorage.getItem(GamePreferenceKey.Multiplier) != null
        ? (localStorage.getItem(GamePreferenceKey.Multiplier) as string)
        : '2'
      : '2',
};

export default function gamePreferencesReducer(
  state: GamesPreferencesStateProps = initialState,
  action: any,
): GamesPreferencesStateProps {
  switch (action.type) {
    case GamesPreferencesActions.HandleChangeAmount:
      localStorage.setItem(
        GamePreferenceKey.Amount,
        `${(action as HandleChangeAmountPayload).payload.amount}`,
      );
      return {
        ...state,
        amount: (action as HandleChangeAmountPayload).payload.amount,
      };
    case GamesPreferencesActions.HandleChangeMultiplier:
      return {
        ...state,
        multiplier: (action as HandleChangeMultiplierPayload).payload
          .multiplier,
      };
    case GamesPreferencesActions.SetHalfAmount:
      return {
        ...state,
        amount: state.amount / 2,
      };

    case GamesPreferencesActions.SetDoubleAmount:
      return {
        ...state,
        amount: state.amount * 2,
      };
    default:
      return state;
  }
}
