import useTranslation from 'next-translate/useTranslation';
import styles from './styles.module.scss';
import { useWalletsMe } from '@/api/wallets';
import { Button, ErrorCard, Image } from '@package/components';
import { WalletService } from '@package/services';
import Link from '@/components/core/Link';
import { ModalsKey } from '@/enums/modalsKey';
import ProfileDrawerContentBalanceSkeleton from './Skeleton';
import useTransactionsNavigation from '@/hooks/useTransacionsNavigation';

export default function ProfileDrawerContentBalance() {
  const { t } = useTranslation('common');
  const { t: tAccount } = useTranslation('account');
  const { wallet, error, refetch, isLoading } = useWalletsMe();
  const { getModalSearchParams } = useTransactionsNavigation();

  if (error) {
    return <ErrorCard error={error} refetch={refetch} />;
  }

  if (isLoading) {
    return <ProfileDrawerContentBalanceSkeleton />;
  }

  return (
    <div className={styles.card}>
      <div className={styles.card__row}>
        <div className={styles.card__label}>
          <Image
            className={styles.card__label__coin}
            format="square"
            width={14}
            height={14}
            src={`${process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL}/coins/rounded/${
              wallet?.currentAccount?.coin?.image ?? 'brl.svg'
            }`}
            alt={wallet?.currentAccount?.coin?.name}
          />
          {tAccount('balance_in_coin', {
            symbol: wallet?.currentAccount?.coin?.symbol,
          })}
        </div>
        <div className={styles.card__title}>
          {WalletService.maskCurrency({
            value: wallet?.currentAccount?.balance,
            coinId: wallet?.currentAccount?.coin?.id,
          })}
        </div>
      </div>

      <div className={styles.card__row}>
        <Link
          modal={ModalsKey.Deposit}
          className={styles.card__row__link}
          href={getModalSearchParams()}
        >
          <Button isSmall>
            <i className="fa-solid fa-pig" />
            {t('tab_deposit')}
          </Button>
        </Link>
        <Link
          modal={ModalsKey.Withdraw}
          className={styles.card__row__link}
          href={getModalSearchParams()}
        >
          <Button isSmall className={styles.card__row__button}>
            <i className="fa-solid fa-money-from-bracket fa-swap-opacity" />
            {t('tab_withdraw')}
          </Button>
        </Link>
      </div>
    </div>
  );
}
