import { Coin } from '@package/models';
import { QueryFunctionContext } from '@tanstack/react-query';
import { UseCoinByIdQueryKey } from './props';
import { AxiosResponse } from 'axios';
import baseAPI from '@/api';

export async function fetchCoinById({
  queryKey,
}: QueryFunctionContext<UseCoinByIdQueryKey>): Promise<Coin> {
  const id: number = queryKey[1];

  const response: AxiosResponse<Coin> = await baseAPI.get<Coin>(`/coins/${id}`);

  return response.data;
}
