import { RouletteColor } from '@/models/roulette/color';
import {
  DoubleActions,
  DoubleBetProps,
  DoubleChangeCollorPayload,
  DoubleSetSocketConnectionPayload,
  DoubleUpdateRoundPayload,
} from './props';

const initialState: DoubleBetProps = {
  prizeList: [],
  closedRound: false,
  color: RouletteColor.White,
  round: null,
  isSocketConnected: false,
};

export default function reducer(
  state: DoubleBetProps = initialState,
  action: any,
): DoubleBetProps {
  switch (action.type) {
    case DoubleActions.UpdateRound: {
      return {
        ...state,
        closedRound: false,
        round: (action as DoubleUpdateRoundPayload).payload,
      };
    }
    case DoubleActions.HandleStoppedRoulette:
      return {
        ...state,
        closedRound: true,
      };
    case DoubleActions.ChangeColor:
      return {
        ...state,
        color: (action as DoubleChangeCollorPayload).payload.color,
      };
    case DoubleActions.Reset:
      return {
        ...state,
        round: null,
        closedRound: false,
        color: RouletteColor.White,
      };
    case DoubleActions.SetSocketConnection: {
      return {
        ...state,
        isSocketConnected: (action as DoubleSetSocketConnectionPayload).payload
          .isSocketConnected,
      };
    }
    default:
      return state;
  }
}
