import {
  WithdrawBonusActions,
  WithdrawBonusStateProps,
  WithdrawBonusRequestFailPayload,
  WithdrawBonusAmountPayload,
  WithdrawBonusRequestSuccessPayload,
} from './props';

const initialState: WithdrawBonusStateProps = {
  loading: false,
  error: false,
  withdraw: null,
  amount: '',
};

export default function reducer(
  state: WithdrawBonusStateProps = initialState,
  action: any,
): WithdrawBonusStateProps {
  switch (action.type) {
    case WithdrawBonusActions.Request:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case WithdrawBonusActions.GetAmount:
      return {
        ...state,
        loading: false,
        amount: (action as WithdrawBonusAmountPayload).payload.amount,
      };
    case WithdrawBonusActions.RequestFail:
      return {
        ...state,
        loading: false,
        error: (action as WithdrawBonusRequestFailPayload).payload,
      };
    case WithdrawBonusActions.RequestSuccess:
      return {
        ...state,
        loading: false,
        withdraw: (action as WithdrawBonusRequestSuccessPayload).payload,
      };
    case WithdrawBonusActions.ResetWithdraw:
      return {
        ...state,
        loading: false,
        error: false,
        withdraw: null,
      };
    default:
      return state;
  }
}
