import { useEffect } from 'react';

function useUnregisterAllServiceWorkers() {
  function handleClearSW() {
    if (typeof window == 'undefined' || typeof navigator == 'undefined') {
      return;
    }

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then(registrations => {
        registrations.forEach(registration => {
          registration.unregister();
        });
      });
    }
  }
  useEffect(handleClearSW, []);
}

export default useUnregisterAllServiceWorkers;
