import { useQuery } from '@tanstack/react-query';
import { CoinsQueryKey } from '../../queryKeys';
import { UseCoinByIdProps, UseCoinByIdQueryKey } from './props';
import { Coin, GenericError } from '@package/models';
import { AxiosError } from 'axios';
import { fetchCoinById } from './service';

export function useCoinById({ id }: UseCoinByIdProps) {
  const queryKey: UseCoinByIdQueryKey = [CoinsQueryKey.ById, id as number];

  const { data, isPending, error, refetch, fetchStatus } = useQuery<
    Coin,
    AxiosError<GenericError>,
    Coin,
    UseCoinByIdQueryKey
  >({
    queryKey,
    queryFn: fetchCoinById,
    staleTime: 60 * 1000,
    enabled: !!id,
  });

  return {
    coin: data,
    isPending: isPending && fetchStatus !== 'idle',
    error,
    refetch,
  };
}
