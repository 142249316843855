import { AudioService } from '@/services/AudioService';
import { GamesAudioActions, GamesAudioStatePayload } from './props';

const initialState: GamesAudioStatePayload = {
  sound: !AudioService.isGamesMuted(),
};

export default function reducer(
  state: GamesAudioStatePayload = initialState,
  action: any,
): GamesAudioStatePayload {
  switch (action.type) {
    case GamesAudioActions.HandleSoundChange:
      if (state.sound) {
        AudioService.removeAudios();
      }

      AudioService.toggleGamesMute();

      return {
        ...state,
        sound: state.sound ? false : true,
      };
    case GamesAudioActions.HandleMuteSounds: {
      AudioService.setGamesMute();

      return {
        ...state,
        sound: false,
      };
    }

    default:
      return state;
  }
}
