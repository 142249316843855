import styles from './styles.module.scss';
import { useBonusRewardTypesStatistics } from '@/api/bonus-reward-type/queries';
import { bonusRewardTypeColumns } from '@/constants/bonusRewardTypeColumns';
import { ErrorCard, TableGrid } from '@package/components';
import { useLottiePlayer } from '@package/hooks';
import useTranslation from 'next-translate/useTranslation';

export default function BonusOverviewModalContent() {
  const { t } = useTranslation('bonus');

  const { lottieAnimationRef } = useLottiePlayer({
    path: `${process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL}/lotties/gift-box-success.json`,
  });
  const {
    bonusRewardTypesStatistics,
    isLoadingBonusRewardTypesStatistics,
    errorBonusRewardTypesStatistics,
    refetchBonusRewardTypesStatistics,
  } = useBonusRewardTypesStatistics();

  if (errorBonusRewardTypesStatistics) {
    return (
      <div className={styles.container}>
        <ErrorCard
          error={errorBonusRewardTypesStatistics}
          refetch={refetchBonusRewardTypesStatistics}
        />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.container__lottie} ref={lottieAnimationRef} />
      <div className={styles.container__wrapper}>
        <div className={styles.container__title}>
          {t('bonus_overview_title')}
        </div>
        <div className={styles.container__description}>
          {t('bonus_overview_description')}
        </div>
      </div>
      <TableGrid
        rows={bonusRewardTypesStatistics ?? []}
        isLoading={isLoadingBonusRewardTypesStatistics}
        columns={bonusRewardTypeColumns}
      />
    </div>
  );
}
