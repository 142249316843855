export class LocalStorageService {
  static getLocalStorageItem<T>(key: string): T | null {
    if (typeof window === 'undefined') {
      return null;
    }

    const item = localStorage.getItem(key);

    if (!item) {
      return null;
    }

    return JSON.parse(item);
  }

  static setLocalStorageItem<T>(key: string, value: T) {
    if (typeof window === 'undefined') {
      return;
    }

    localStorage.setItem(key, JSON.stringify(value));
  }
}
