export enum GamesPreferencesActions {
  HandleChangeAmount = 'games-preferences/handle-change-amount',
  HandleChangeMultiplier = 'games-preferences/handle-change-multiplier',
  SetHalfAmount = 'games-preferences/set-half-amount',
  SetDoubleAmount = 'games-preferences/set-double-amount',
}

export interface GamesPreferencesStateProps {
  amount: number;
  multiplier: string;
}

export interface GamesPreferencesBasePayload {
  type: string;
}

export type SetHalfAmountPayload = GamesPreferencesBasePayload;
export type SetDoubleAmountPayload = GamesPreferencesBasePayload;

export interface HandleChangeAmountPayload extends GamesPreferencesBasePayload {
  payload: {
    amount: number;
  };
}

export interface HandleChangeMultiplierPayload
  extends GamesPreferencesBasePayload {
  payload: {
    multiplier: string;
  };
}

export type GamePreferencesStatePayload =
  | GamesPreferencesStateProps
  | HandleChangeAmountPayload
  | HandleChangeMultiplierPayload;
