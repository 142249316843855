import { GiphyActions, GiphyProps, GiphyStatePayload } from './props';

const initialState: GiphyProps = {
  search: '',
};

export default function reducer(
  state: GiphyProps = initialState,
  action: GiphyStatePayload,
): GiphyProps {
  switch (action.type) {
    case GiphyActions.SetSearch:
      return {
        search: action.payload,
      };
    default:
      return state;
  }
}
