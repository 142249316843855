import { Button } from '@starsoft/common/components';
import styles from './styles.module.scss';
import { ModalsKey } from '@/enums/modalsKey';
import { useUserAccount } from '@/api/user/queries';
import Link from '@/components/core/Link';
import useTranslation from 'next-translate/useTranslation';
import { AuthService } from '@/services/AuthService';
import { IconButton } from '@starsoft/common/components';
import { useModalNavigation } from '@/hooks/modals/useModalNavigation';

export default function HeaderAuthButtons() {
  const { isLoading } = useUserAccount();
  const { t } = useTranslation('common');
  const { navigateToModal } = useModalNavigation();

  function onClick() {
    navigateToModal(ModalsKey.Language);
  }

  const isLogged = AuthService.isAuthorized();

  if (isLogged) {
    return null;
  }

  if (!isLogged && isLoading) {
    return Array.from({ length: 2 }).map((_, idx) => (
      <div key={`button-skeleton-${idx}`} className={styles.button__skeleton} />
    ));
  }

  return (
    <>
      <Link modal={ModalsKey.Login}>
        <Button isSecondary isSmall>
          {t('login_button')}
        </Button>
      </Link>
      <Link modal={ModalsKey.Register}>
        <Button isSmall>{t('register_button')}</Button>
      </Link>
      <div className={styles['desktop__only']}>
        <IconButton onClick={onClick}>
          <i className="fad fa-globe fa-swap-opacity" />
        </IconButton>
      </div>
    </>
  );
}
