import LogoutButton from '@/components/ProfileDropdown/LogoutButton';
import ProfileDrawerContentHeader from './Header';
import styles from './styles.module.scss';
import ProfileDrawerContentBalance from './Balance';
import ProfileDrawerContentNavigation from './Navigation/Common';
import ProfileDrawerContentReferAndEarn from './ReferAndEarn';
import { StaggerAnimation } from '@package/components';
import ProfileDrawerContentNavigationSettings from './Navigation/Settings';
import SupportSection from '@/components/SideBar/Content/NotCollapsed/SupportSection';
import ProfileDrawerContentHeaderCloseButton from './Header/CloseButton';
import { useDetectContainerVerticalScroll } from '@package/hooks';
import JoinCommunityCard from './JoinCommunity';

export default function ProfileDrawerContent() {
  const { hasScrolled, ref } = useDetectContainerVerticalScroll();

  return (
    <div className={styles.container}>
      <ProfileDrawerContentHeaderCloseButton hasScrolled={hasScrolled} />
      <ProfileDrawerContentHeader />
      <StaggerAnimation
        direction="column"
        stagger={0.15}
        staggerDirection="up"
        className={styles.container__content}
        ref={ref}
      >
        <ProfileDrawerContentBalance />
        <ProfileDrawerContentNavigation />
        <ProfileDrawerContentReferAndEarn />
        <ProfileDrawerContentNavigationSettings />
        <SupportSection />
        {/* <JoinCommunityCard /> */}
      </StaggerAnimation>
      <LogoutButton />
    </div>
  );
}
