import {
  SupportActions,
  SupportStatePayload,
  SupportStateProps,
} from './props';

const initialState: SupportStateProps = {
  expanded: false,
  search: '',
  loadingSocketConnection: true,
  messagesIdUploading: [],
  unreadMessagesRoomsId: [],
};

export default function supportReducer(
  state: SupportStateProps = initialState,
  action: SupportStatePayload,
): SupportStateProps {
  switch (action.type) {
    case SupportActions.Expand: {
      return { ...state, expanded: !state.expanded };
    }
    case SupportActions.HandleChangeSearch: {
      return {
        ...state,
        search: action.payload,
      };
    }
    case SupportActions.SetInitialLoading: {
      return {
        ...state,
        loadingSocketConnection: action.payload,
      };
    }
    case SupportActions.UpdateMessages: {
      const hasMessagesUploading = state.messagesIdUploading.length > 0;

      if (!hasMessagesUploading) {
        return {
          ...state,
        };
      }

      const updatedMessagesUploading = [...state.messagesIdUploading];
      updatedMessagesUploading.shift();

      return {
        ...state,
        messagesIdUploading: updatedMessagesUploading,
      };
    }

    case SupportActions.HandleRemoveImageUploadProgress: {
      const filteredUploadMessagesId = state.messagesIdUploading.filter(
        id => id !== action.payload,
      );

      return {
        ...state,
        messagesIdUploading: filteredUploadMessagesId,
      };
    }

    case SupportActions.SetMessageIdUploading:
      return {
        ...state,
        messagesIdUploading: state.messagesIdUploading.concat(action.payload),
      };

    case SupportActions.AddUnreadMessageRoomId: {
      const isInArray = state.unreadMessagesRoomsId.findIndex(
        id => id === action.payload,
      );

      if (isInArray !== -1) {
        return state;
      }

      return {
        ...state,
        unreadMessagesRoomsId: state.unreadMessagesRoomsId.concat(
          action.payload,
        ),
      };
    }

    case SupportActions.RemoveUnreadMessageRoomId: {
      const updatedUnreadMessagesRoomId = state.unreadMessagesRoomsId.filter(
        id => id !== action.payload,
      );

      return {
        ...state,
        unreadMessagesRoomsId: updatedUnreadMessagesRoomId,
      };
    }

    case SupportActions.ResetMessagesRoomsId: {
      return {
        ...state,
        unreadMessagesRoomsId: [],
      };
    }
    default: {
      return state;
    }
  }
}
