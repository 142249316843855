import { QueryFunctionContext } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { PaginatedResponse } from '@/models/pagination/paginated-response.dto';
import baseAPI from '@/api';
import { UseGtmEventsQueryKeyProps } from './props';
import { UserTagManagerEvents } from '@/models/user-tag-manager-events';

export async function fetchGtmEvents({
  queryKey,
}: QueryFunctionContext<UseGtmEventsQueryKeyProps>): Promise<
  PaginatedResponse<UserTagManagerEvents>
> {
  const { limit, page } = queryKey[1];

  const response: AxiosResponse<PaginatedResponse<UserTagManagerEvents>> =
    await baseAPI.get<PaginatedResponse<UserTagManagerEvents>>(
      '/user-tag-manager-events/me',
      {
        params: {
          limit,
          page,
        },
      },
    );

  return response.data;
}
