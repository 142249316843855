import { GridDifficulty } from '@/models/bet/grid/difficulty';

export enum MissionUncrossableActions {
  SetDifficulty = 'mission-uncrossable/set-dificulty',
  SetWalking = 'mission-uncrossable/toggle-modal',
  SetFireEvent = 'mission-uncrossable/fire-event',
  RestartAnimation = 'mission-uncrossable/restart-animation',
  ResetBoard = 'mission-uncrossable/reset-board',
}

export type MissionUncrossableProps = {
  difficulty: GridDifficulty;
  walking: boolean;
  showTomato: boolean;
  restartBoard: boolean;
};

export interface MissionUncrossableBasePayload<
  T extends MissionUncrossableActions,
  V = null,
> {
  type: T;
  payload: V;
}

export interface SetDifficultyPayload
  extends MissionUncrossableBasePayload<
    MissionUncrossableActions.SetDifficulty,
    GridDifficulty
  > {}

export interface ShowSplashAnimationPayload
  extends MissionUncrossableBasePayload<
    MissionUncrossableActions.RestartAnimation,
    boolean
  > {}

export interface SetWalkingStatePayload
  extends MissionUncrossableBasePayload<MissionUncrossableActions.SetWalking> {}
export interface ResetMissionUncrossableBoardPayload
  extends MissionUncrossableBasePayload<
    MissionUncrossableActions.ResetBoard,
    boolean
  > {}

export type MissionUncrossableStatePayload =
  | SetDifficultyPayload
  | ShowSplashAnimationPayload
  | ResetMissionUncrossableBoardPayload
  | SetWalkingStatePayload;
