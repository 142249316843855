import {
  UsersNetworkTypeActions,
  UsersNetworkTypeStateProps,
  UsersNetworkCurrentLevelPayload,
} from './props';

const initialState: UsersNetworkTypeStateProps = {
  depth: 1,
};

export default function reducer(
  state: UsersNetworkTypeStateProps = initialState,
  action: any,
): UsersNetworkTypeStateProps {
  switch (action.type) {
    case UsersNetworkTypeActions.ChangeCurrentLevel:
      return {
        ...state,
        depth: (action as UsersNetworkCurrentLevelPayload).payload.depth,
      };
    default:
      return state;
  }
}
