import useTranslation from 'next-translate/useTranslation';
import SidebarNotCollapsedItemSection from '../Item/Section';
import { useRouter } from 'next/router';

export default function VipClubSection() {
  const { t } = useTranslation('common');
  const { push } = useRouter();

  function handleNavigate() {
    push(`/vip-club`);
  }

  return (
    <SidebarNotCollapsedItemSection
      label={t('vip_club_title')}
      icon="fa-solid fa-crown"
      isHighligh
      onClick={handleNavigate}
    />
  );
}
