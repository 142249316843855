import { AuthService } from '@/services/AuthService';
import {
  CasinoActions,
  CasinoGameMode,
  CasinoStatePayload,
  CasinoStateProps,
} from './props';
import { LocalStorageKey } from '@/enums/localStorageKey';

//The search history will be in the following format '1,2,3,4'
const localStorageSearchHistory: string =
  typeof window !== 'undefined'
    ? (localStorage.getItem(LocalStorageKey.SearchHistory) ?? '')
    : '';
const localStorageSearchHistoryArray: string[] = !!localStorageSearchHistory
  ? localStorageSearchHistory.split(',')
  : [];

const initialState: CasinoStateProps = {
  searchGame: '',
  searchProvider: '',
  mode:
    typeof window !== 'undefined'
      ? localStorage.getItem(LocalStorageKey.GameMode) !== null
        ? (localStorage.getItem(LocalStorageKey.GameMode) as CasinoGameMode)
        : CasinoGameMode.Demo
      : CasinoGameMode.Demo,

  useWideViewport: false,
  providersList: [],
  openSearch: false,
  searchHistory: localStorageSearchHistoryArray,
  modalSearch: '',
  device: undefined,
};

export default function reducer(
  state: CasinoStateProps = initialState,
  action: CasinoStatePayload,
): CasinoStateProps {
  switch (action.type) {
    case CasinoActions.HandleInputChange:
      return {
        ...state,
        searchGame: action.payload,
      };

    case CasinoActions.HandleChangeViewport:
      return {
        ...state,
        useWideViewport: !state.useWideViewport,
      };

    case CasinoActions.HandleChangeGameMode: {
      if (state.mode == CasinoGameMode.Demo) {
        localStorage.setItem(LocalStorageKey.GameMode, CasinoGameMode.Real);
        return {
          ...state,
          mode: CasinoGameMode.Real,
        };
      } else {
        localStorage.setItem(LocalStorageKey.GameMode, CasinoGameMode.Demo);
        return {
          ...state,
          mode: CasinoGameMode.Demo,
        };
      }
    }
    case CasinoActions.HandleProviderFilter: {
      const id: number = action.payload;

      if (state.providersList.includes(id)) {
        return {
          ...state,
          providersList: state.providersList.filter(item => item != id),
        };
      } else {
        return {
          ...state,
          providersList: state.providersList.concat([id]),
        };
      }
    }
    case CasinoActions.HandleResetAllParams:
      return {
        ...state,
        searchGame: '',
        providersList: [],
        searchProvider: '',
      };

    case CasinoActions.SetGameMode:
      return {
        ...state,
        mode: action.payload,
      };
    case CasinoActions.HandleInputProviderChange:
      return {
        ...state,
        searchProvider: action.payload,
      };

    case CasinoActions.HandleChangeModeToReal: {
      return {
        ...state,
        mode:
          AuthService.isAuthorized() && state.mode === CasinoGameMode.Demo
            ? CasinoGameMode.Real
            : state.mode,
      };
    }

    case CasinoActions.HandleResetSearchProvider:
      return {
        ...state,
        searchProvider: '',
      };

    case CasinoActions.HandleResetSearchGame:
      return {
        ...state,
        searchGame: '',
      };

    case CasinoActions.ToggleSearch:
      return {
        ...state,
        openSearch: !state.openSearch,
      };

    case CasinoActions.SetSearchHistory: {
      if (state.searchHistory.includes(action.payload)) {
        return {
          ...state,
        };
      }

      const updatedSearchHistory = state.searchHistory.concat(action.payload);

      if (typeof window !== 'undefined') {
        const updatedLocalStorageSearchHistory = updatedSearchHistory.join(',');
        localStorage.setItem(
          LocalStorageKey.SearchHistory,
          updatedLocalStorageSearchHistory,
        );
      }

      return {
        ...state,
        searchHistory: updatedSearchHistory,
      };
    }

    case CasinoActions.ResetSearchHistory: {
      if (typeof window !== 'undefined') {
        localStorage.removeItem(LocalStorageKey.SearchHistory);
      }

      return {
        ...state,
        searchHistory: [],
      };
    }

    case CasinoActions.RemoveSearchHistoryItem: {
      const index = action.payload.index;

      const filteredSearchHistory = [...state.searchHistory];
      filteredSearchHistory.splice(index, 1);

      if (typeof window !== 'undefined') {
        const updatedLocalStorageSearchHistory =
          filteredSearchHistory.join(',');
        localStorage.setItem(
          LocalStorageKey.SearchHistory,
          updatedLocalStorageSearchHistory,
        );
      }

      return {
        ...state,
        searchHistory: filteredSearchHistory,
      };
    }

    case CasinoActions.SetModalSearch: {
      return {
        ...state,
        modalSearch: action.payload,
      };
    }
    case CasinoActions.SetUserDevice:
      return {
        ...state,
        device: action.payload,
      };

    default:
      return state;
  }
}
