import { useFormContext } from 'react-hook-form';
import { RegisterFormProps } from '@/types/register/props';
import { PasswordInput } from '@package/components';
import { useEffect } from 'react';

export default function AuthModalRegisterPasswordContent(): JSX.Element {
  const {
    watch,
    register,
    formState: { touchedFields, dirtyFields },
    setFocus,
  } = useFormContext<RegisterFormProps>();
  const password = watch('password');

  function onMount() {
    setFocus('password');
  }

  useEffect(onMount, [setFocus]);

  return (
    <PasswordInput
      register={register}
      name="password"
      isTouched={touchedFields?.password}
      isDirty={dirtyFields?.password}
      password={password}
    />
  );
}
