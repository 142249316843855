import { NotificationRowProps, NotificationRowType } from './props';
import { memo, useMemo } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useCountUp } from 'use-count-up';
import { useDispatch } from 'react-redux';
import NotificationRowContent from './Content';
import styles from './styles.module.scss';
import { removeNotificationToasty } from '@/lib/store/notifications/actions';
import { NOTIFICATION_DURATION } from '@/services/NotificationsService';

function NotificationRow({ notification, type }: NotificationRowProps) {
  const startTime = useMemo(
    () => notification && new Date(notification?.createdAt).getTime(),
    [notification],
  );
  const endTime = useMemo(
    () =>
      notification &&
      new Date(notification?.createdAt).getTime() - NOTIFICATION_DURATION,
    [notification],
  );
  const dispatch = useDispatch();

  function handleRemoveNotification(): void {
    dispatch(removeNotificationToasty(notification?.id));
  }

  const { value } = useCountUp({
    start: startTime,
    end: endTime,
    duration: 10,
    updateInterval: 1,
    easing: 'linear',
    isCounting: type === NotificationRowType.Toasty,
    onComplete: handleRemoveNotification,
  });

  return (
    <div
      className={`${styles.container} ${type === NotificationRowType.Row ? styles['container--row'] : styles['container--toasty']}`}
    >
      <NotificationRowContent notification={notification} type={type} />
      {type == NotificationRowType.Toasty && (
        <div className={styles['container__close-button']}>
          <i
            onClick={handleRemoveNotification}
            className={`${styles['container__close-button__icon']} fa-solid fa-xmark`}
          />
          <div className={styles['container__close-button__progress']}>
            <CircularProgressbar
              value={value as number}
              styles={buildStyles({ pathTransition: '1.7s' })}
              strokeWidth={8}
              minValue={endTime}
              maxValue={startTime}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default memo(NotificationRow);
