import { ModalsKey } from '@/enums/modalsKey';
import { ModalLayout } from '@package/components';
import useTranslation from 'next-translate/useTranslation';
import BonusOverviewModalContent from './Content';

export default function BonusOverviewModal() {
  const { t } = useTranslation('bonus');
  return (
    <ModalLayout modal={ModalsKey.BonusDetails} title={t('bonus_details')}>
      <BonusOverviewModalContent />
    </ModalLayout>
  );
}
