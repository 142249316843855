import { GridDifficulty } from '@/models/bet/grid/difficulty';

export enum TowerActions {
  SetDoubleAmount = 'tower/setDoubleAmount',
  SetDifficulty = 'tower/setDificulty',
  IncrementSelectedList = 'tower/incrementSelectedList',
  Reset = 'tower/reset',
  HandleAnimate = 'tower/handleAnimate',
  SetClickedCells = 'tower/set-clicked-cells',
  IncrementClickedCells = 'tower/increment-clicked-cells',
}
export interface TowerProps {
  difficulty: GridDifficulty;
  columnQuantity: number;
  clickedCells: number[];
}

export interface TowerBasePayload {
  type: string;
}

export interface TowerQuantityChangePayload extends TowerBasePayload {
  payload: {
    difficulty: GridDifficulty;
  };
}
export interface TowerSetClickedCellsPayload extends TowerBasePayload {
  payload: {
    clickedCells: number[];
  };
}

export interface TowerIncrementClickedCellsPayload extends TowerBasePayload {
  payload: {
    clickedCell: number;
  };
}

export type TowerStatePayload =
  | TowerActions
  | TowerProps
  | TowerSetClickedCellsPayload
  | TowerQuantityChangePayload;
