import { Coin } from '@/models/coin';
import { FollowMode } from '@/services/dto/follow/follow-mode';
import { ListOptions } from './enum/listOptions.enum';

export enum InvestmentActions {
  ShowInvestmentForm = 'investment/showInvestmentForm',
  SetInvestmentCoin = 'investment/setInvestmentCoin',
  HandleFromOption = 'investment/handleFromOption',
  HandleToOption = 'investment/handleToOption',
  SwitchOptions = 'investment/switchOptions',
  SetAmount = 'investment/setAmount',
  SetMargin = 'investment/setMargin',
  IncrementMargin = 'investment/incrementMargin',
  DecrementMargin = 'investment/decrementMargin',
  SetFollowMode = 'investment/setFollowMode',
  SetSearch = 'investment/setSearch',
  SetBetAmount = 'investment/setBetAmount',
  SetHasSeenTutorial = 'investment/hasSeenTutorial',
  SetTutorialStep = 'investment/setTutorialStep',
}

export interface InvestmentOption {
  label: ListOptions;
  value: boolean;
}

export interface InvestmentStateProps {
  showForm: boolean;
  investmentCoin: Coin | null;
  listOptions: InvestmentOption[];
  from: InvestmentOption;
  to: InvestmentOption;
  switch: InvestmentOption[];
  mode: FollowMode;
  amount: string;
  margin: string;
  search: string;
  betAmount: string;
  hasSeenTutorial: boolean;
  tutorialStep: number;
}

export interface InvestmentBasePayload {
  type: string;
}

export type ShowInvestmentFormPayload = InvestmentBasePayload;
export type SwitchInvestmentOptionPayload = InvestmentBasePayload;

export type IncrementMarginPayload = InvestmentBasePayload;
export type DecrementMarginPayload = InvestmentBasePayload;

export interface SetFollowModePayload extends InvestmentBasePayload {
  payload: {
    mode: FollowMode;
  };
}

export interface SetInvestmentCoinPayload extends InvestmentBasePayload {
  payload: {
    coin: Coin;
  };
}

export interface SetInvestmentMarginPayload extends InvestmentBasePayload {
  payload: {
    margin: string;
  };
}
export interface SetInvestmentAmountPayload extends InvestmentBasePayload {
  payload: {
    amount: string;
  };
}

export interface SetInvestmentFromOptionPayload extends InvestmentBasePayload {
  payload: {
    option: InvestmentOption;
  };
}

export interface SetInvestmentToOptionPayload extends InvestmentBasePayload {
  payload: {
    option: InvestmentOption;
  };
}

export interface SetSearchPayload extends InvestmentBasePayload {
  payload: {
    search: string;
  };
}

export interface SetHasSeenTutorialPayload extends InvestmentBasePayload {
  payload: boolean;
}

export interface SetTutorialStepPayload extends InvestmentBasePayload {
  payload: number;
}

export type InvestmentStatePayload =
  | InvestmentBasePayload
  | SetInvestmentCoinPayload
  | ShowInvestmentFormPayload
  | SetSearchPayload
  | SetHasSeenTutorialPayload
  | SetTutorialStepPayload;
