import SidebarContentCollapsedPage from '../Page';

export default function ReferPage() {
  return (
    <SidebarContentCollapsedPage
      page={{
        label: 'reference_code',
        icon: 'fa-solid fa-gift fa-swap-opacity',
        isProtected: true,
        paths: ['/affiliates/panel'],
      }}
      isHightligh
    />
  );
}
