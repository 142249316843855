import { CircularProgress } from '@starsoft/common/components';
import styled from 'styled-components';

export const Progress = styled(CircularProgress)`
  width: 18px;
  height: 18px;
  position: absolute;
  top: -8px;
  z-index: 2;
  right: -8px;

  &::after {
    content: '';
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.vars.palette.background.default};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
