import { Round } from '@/models/round';
import { Nullable } from '@starsoft/common/interfaces';

export enum SlideAction {
  GetTargetMultiplier = 'slide/getTargetMultiplier',
  SetRound = 'slide/setRoundUpdate',
  HandleStoppedSlide = 'slide/handleStoppedSlide',
  Reset = 'slide/reset',
  SetSocketConnection = 'slide/socketConnection',
}

export interface SlideProps {
  numberCarousel: number[];
  resultMultiplier: Nullable<number>;
  chosenMultiplier: string;
  init: boolean;
  round: Round | null;
  prizeIndex: number;
  closedRound: boolean;
  isSocketConnected: boolean;
}

export interface SlideBasePayload<T extends SlideAction, V = null> {
  type: T;
  payload: V;
}

export type GetChosenMultiplierPaylod = SlideBasePayload<
  SlideAction.GetTargetMultiplier,
  string
>;

export type SetRoundPayload = SlideBasePayload<SlideAction.SetRound, Round>;

export type SetSocketConnectionPayload = SlideBasePayload<
  SlideAction.SetSocketConnection,
  boolean
>;

export type SlideStatePayload =
  | SlideBasePayload<SlideAction.HandleStoppedSlide>
  | SlideBasePayload<SlideAction.Reset>
  | GetChosenMultiplierPaylod
  | SetRoundPayload
  | SetSocketConnectionPayload;
