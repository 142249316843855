import { collapseSideBar } from '@/lib/store/side-bar/actions';
import { RootState } from '@/lib/store';
import { useDispatch, useSelector } from 'react-redux';
import { ToolTip as CustomToolTip } from '@starsoft/common/components';
import useTranslation from 'next-translate/useTranslation';
import styles from './styles.module.scss';

export default function CollapseButton() {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();

  const collapsedSidebar: boolean = useSelector(
    (state: RootState) => state.sideBar.collapsed,
  );

  function onMinimizeButtonClick() {
    dispatch(collapseSideBar(!collapsedSidebar));
  }

  return (
    <div className={styles.container}>
      <CustomToolTip
        titleComponent={t(
          collapsedSidebar ? 'expand_side_bar' : 'minimize_side_bar',
        )}
      >
        {collapsedSidebar ? (
          <button
            className={styles['container__icon-button']}
            onClick={onMinimizeButtonClick}
          >
            <i className={`${styles['container__icon']} fa-solid fa-bars`} />
          </button>
        ) : (
          <button
            className={styles['container__button']}
            onClick={onMinimizeButtonClick}
          >
            <i className={`${styles['container__icon']} fa-solid fa-bars`} />
          </button>
        )}
      </CustomToolTip>
    </div>
  );
}
