import {
  CollapseSideBarPayload,
  SetClickedSportCollapsedPayload,
  SideBarActions,
  SidebarBasePayload,
} from './props';

export function collapseSideBar(toggle: boolean): CollapseSideBarPayload {
  return { type: SideBarActions.Collapse, payload: toggle };
}

export function setClickedSportCollapsed(
  sportSlug: string,
): SetClickedSportCollapsedPayload {
  return {
    type: SideBarActions.SetClickedSportCollapsed,
    payload: {
      sportSlug,
    },
  };
}

export function removeClickedSportCollapsed(
  sportSlug: string,
): SetClickedSportCollapsedPayload {
  return {
    type: SideBarActions.RemoveClickedSportCollapsed,
    payload: {
      sportSlug,
    },
  };
}

export function toggleOpenSidebar(): SidebarBasePayload {
  return {
    type: SideBarActions.ToggleOpenSidebar,
  };
}
