import { Nullable } from '@starsoft/common/interfaces';
import { RouletteColor } from '@/models/roulette/color';
import { Round } from '@/models/round';

export enum DoubleActions {
  HandleStoppedRoulette = 'double/handleStoppedRoulette',
  ChangeColor = 'double/changeColor',
  Reset = 'double/reset',
  SetSocketConnection = 'double/socketConnection',
  UpdateRound = 'double/updateRound',
}

export type PrizeListProps = {
  id: string;
  prize: {
    value: number;
    color: RouletteColor;
    cardNumber: number;
  };
  image: string | null;
};

export interface DoubleBetProps {
  prizeList: PrizeListProps[];
  color: RouletteColor | null;
  closedRound: boolean;
  isSocketConnected: boolean;
  round: Nullable<Round>;
}

export interface DoubleBetBasePayload {
  type: string;
}

export type DoubleUpdateLastRoundsRequest = DoubleBetBasePayload;

export type DoubleHandleStopPayload = DoubleBetBasePayload;
export type DoubleResetPayload = DoubleBetBasePayload;

export interface DoubleUpdateRoundPayload extends DoubleBetBasePayload {
  payload: Round;
}
export interface DoubleChangeCollorPayload extends DoubleBetBasePayload {
  payload: {
    color: RouletteColor;
  };
}

export interface DoubleSetSocketConnectionPayload extends DoubleBetBasePayload {
  payload: {
    isSocketConnected: boolean;
  };
}

export type DoubleStatePayload =
  | DoubleUpdateLastRoundsRequest
  | DoubleSetSocketConnectionPayload;
