import { NotificationGroupWithUnreadCount } from '@/models/notification/notificationGroupWithUnreadCount';
import useTranslation from 'next-translate/useTranslation';
import styles from './styles.module.scss';
import { memo } from 'react';
import { TabProps } from '@starsoft/common/src/components/Tabs/Tab/props';

function NotificationModalTab({
  currentTab,
  setTab,
  tab,
}: TabProps<NotificationGroupWithUnreadCount>) {
  const { t } = useTranslation('common');
  const isActive: boolean = currentTab === tab?.value;
  const unreadCount: number = tab?.value?.unreadCount as number;

  return (
    <button
      className={`${styles.button} ${isActive ? styles['button--active'] : ''}`}
      onClick={() => setTab(tab.value)}
    >
      {t(tab?.value?.name as string)}

      <div className={styles.button__indicator}>
        {unreadCount > 99 ? '99+' : unreadCount}
      </div>
    </button>
  );
}

export default memo(NotificationModalTab);
