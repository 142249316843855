import { FollowBetTutorialStep } from '@/components/Gamblers/TutorialModal/Content/Layout/props';

export const followBetSteps: FollowBetTutorialStep[] = [
  {
    title: 'follow_bet_introduction_title',
    description: 'follow_bet_introduction_description',
    image: '1.gif',
  },
  {
    title: 'follow_bet_profile_title',
    description: 'follow_bet_profile_description',
    image: '2.gif',
  },
  {
    title: 'follow_bet_multi_wallet_title',
    description: 'follow_bet_multi_wallet_description',
    image: '3.gif',
  },
  {
    title: 'follow_bet_results_title',
    description: 'follow_bet_results_description',
    image: '4.gif',
  },
  {
    title: 'follow_bet_end_title',
    description: 'follow_bet_end_description',
    image: '5.gif',
  },
];
