import baseAPI from '@/api';
import { Success } from '@/models/success';
import { RecoveryPasswordResetInputDto } from '@/services/dto/recovery-passwords/recovery-password-reset-input.dto';
import { AxiosResponse } from 'axios';

export async function fetchRecoveryPasswordReset(
  data: RecoveryPasswordResetInputDto,
): Promise<Success> {
  const response: AxiosResponse<Success> = await baseAPI.patch<Success>(
    '/recovery-passwords/reset',
    data,
  );
  return response.data;
}
