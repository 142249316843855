import { RankLabelProps } from '../props';
import styles from '../styles.module.scss';
import skeleton from './styles.module.scss';

export default function RankLabelSkeleton({
  onlyRankImage,
}: Pick<RankLabelProps, 'onlyRankImage'>) {
  return (
    <div className={styles.container}>
      <div className={skeleton.icon} />

      {!onlyRankImage && <div className={skeleton.label} />}
    </div>
  );
}
