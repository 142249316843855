import { CurrencyButtonSkeletonProps } from './props';
import styles from '../styles.module.scss';
import skeleton from './styles.module.scss';

export default function CurrencyButtonSkeleton({
  noEffects,
}: CurrencyButtonSkeletonProps) {
  return (
    <div
      className={`${styles.container} ${noEffects ? styles['container--without-effect'] : ''} ${styles['container--loading']}`}
    >
      <div className={styles.container__coin}>
        <div className={skeleton.coin} />
        <div className={skeleton.text} />
      </div>

      <div className={styles.container__balance}>
        <div className={skeleton.value} />
        <div className={skeleton.text} />
      </div>
    </div>
  );
}
