import { useMutation } from '@tanstack/react-query';
import { UseSendIamCodeByEmailProps } from './props';
import { fetchSendIamCodeByEmail } from './service';

export function useSendIamCodeByEmail(props?: UseSendIamCodeByEmailProps) {
  const { externalOnSuccess: externalSuccess }: UseSendIamCodeByEmailProps =
    props ?? {};
  const { mutate, isPending, isSuccess } = useMutation({
    mutationFn: fetchSendIamCodeByEmail,
    onSuccess: externalSuccess,
  });

  return {
    sendIamCodeByEmail: mutate,
    isPending,
    isSuccess,
  };
}
