import {
  ChatMessagePayload,
  LiveChatActions,
  LiveChatStatePayload,
  LiveChatStateProps,
} from './props';

const initialState: LiveChatStateProps = {
  open: false,
  chat: [],
  error: null,
  loading: true,
  counter: 0,
  timeOutEndDate: null,
};

export default function sideBarReducer(
  state: LiveChatStateProps = initialState,
  action: LiveChatStatePayload,
): LiveChatStateProps {
  switch (action.type) {
    case LiveChatActions.Toggle: {
      return {
        ...state,
        open: !state.open,
        counter: 0,
      };
    }
    case LiveChatActions.SetInitialLoading: {
      return {
        ...state,
        loading: false,
      };
    }
    case LiveChatActions.UpdateChat: {
      const newMessage = (action as ChatMessagePayload).payload.message;
      const filteredChat =
        state.chat.length > 30
          ? state.chat.filter((_, index) => index !== 0)
          : state.chat;

      filteredChat.push(newMessage);

      return {
        ...state,
        chat: [...filteredChat],
        counter: state.counter + 1,
      };
    }
    case LiveChatActions.ResetCounter: {
      return {
        ...state,
        counter: 0,
      };
    }

    case LiveChatActions.SetTimeOutEndDate: {
      return {
        ...state,
        timeOutEndDate: new Date(+new Date() + 30 * 1000), // currentTime + 30s,
      };
    }
    default: {
      return state;
    }
  }
}
