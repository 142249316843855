import { BaseContent } from '..';
import useTranslation from 'next-translate/useTranslation';
import WalletService from '@/services/WalletService';
import { ComissionNotification } from '@/models/notification/notification';
import { NotificationContentProps } from '../props';
import { ComissionExtraParams } from '@/models/notification/notificationExtraParams';

export default function BonusContent({
  notification,
  type,
}: NotificationContentProps<ComissionNotification>) {
  const { t } = useTranslation('notify');
  const extra: ComissionExtraParams = notification.extra;

  return (
    <BaseContent
      icon="fa-solid fa-gift-card"
      title={t('notification_bonus_title')}
      description={t('notification_bonus_description', {
        amount: WalletService.maskCurrency({
          value: extra.amount,
          coinId: extra.coinId,
        }),
        affiliate: extra.username,
      })}
      createdAt={notification?.createdAt as string}
      type={type}
    />
  );
}
