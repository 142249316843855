import { useClickOutside, useModal, useToggle } from '@package/hooks';
import styles from './styles.module.scss';
import { AnimatePresence } from 'framer-motion';
import { m as motion } from 'framer-motion';
import { profileDrawerVariant } from './variants';
import { ModalsKey } from '@/enums/modalsKey';
import { useUserAccount } from '@/api/user';
import ProfileDrawerContent from './Content';

export default function ProfileDrawer() {
  const { isOpen, onClose, handleContainer } = useModal(
    ModalsKey.ProfileDetails,
  );
  const { isAuthorized } = useUserAccount();

  function handleClose() {
    onClose();
  }

  const { current } = useClickOutside({
    onClickOutside: handleClose,
    open: isOpen,
  });

  if (!isAuthorized) {
    return null;
  }

  return (
    <AnimatePresence mode="wait">
      {isOpen && (
        <div className={styles.container__overlay} onClick={handleClose}>
          <motion.div
            variants={profileDrawerVariant}
            initial="hidden"
            animate="open"
            onClick={handleContainer}
            exit="hidden"
            className={styles.container}
            ref={current}
          >
            <ProfileDrawerContent />
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
}
