import useTranslation from 'next-translate/useTranslation';
import { useDispatch, useSelector } from 'react-redux';
import {
  collapseWallet,
  toggleConvertToFiat,
} from '@/lib/store/wallet/actions';
import { RootState } from '@/lib/store';
import { Switch } from '@package/components';
import { useWalletsMe } from '@/api/wallets';
import { useModalNavigation } from '@/hooks/modals/useModalNavigation';
import { ModalsKey } from '@/enums/modalsKey';

export default function ConvertToCoinSwitch() {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const convertToFiat: boolean = useSelector(
    (state: RootState) => state.wallet.convertToCoin,
  );
  const { navigateToModal } = useModalNavigation();
  const { isLoading } = useWalletsMe();

  function handleSwitchStatus() {
    if (!convertToFiat) {
      dispatch(collapseWallet());
      navigateToModal(ModalsKey.CoinConversion);
    }

    dispatch(toggleConvertToFiat());
  }

  return (
    <Switch
      label={t('see_in_fiat')}
      isChecked={convertToFiat}
      onChange={handleSwitchStatus}
      isLoading={isLoading}
    />
  );
}
