import {
  MineActions,
  MineProps,
  MineBetAmountPayload,
  MineBetVerifyAmountPayload,
  MineQuantityChangePayload,
} from './props';

const initialState: MineProps = {
  amount: '',
  mineQuantity: 2,
};

export default function reducer(
  state: MineProps = initialState,
  action: any,
): MineProps {
  switch (action.type) {
    case MineActions.Reset:
      return initialState;
    case MineActions.GetAmount:
      return {
        ...state,
        amount: (action as MineBetAmountPayload).payload.amount,
      };
    case MineActions.VerifyAmount: {
      if (Number((action as MineBetVerifyAmountPayload).payload.amount) < 0) {
        return {
          ...state,
          amount: '10',
        };
      }
      return state;
    }
    case MineActions.SetHalfAmount:
      return {
        ...state,
        amount: (Number(state.amount) / 2).toFixed(2).toString(),
      };
    case MineActions.SetDoubleAmount:
      return {
        ...state,
        amount: (Number(state.amount) * 2).toFixed(2).toString(),
      };
    case MineActions.SetMineQuantity:
      return {
        ...state,
        mineQuantity: (action as MineQuantityChangePayload).payload
          .mineQuantity,
      };
    case MineActions.PlayAgain:
      return {
        ...state,
      };
    default:
      return state;
  }
}
