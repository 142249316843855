import { SidebarNotCollapsedItemSectionProps } from './props';
import styles from './styles.module.scss';

export default function SidebarNotCollapsedItemSection({
  icon,
  label,
  onClick,
  isOpen,
  shouldShowChevron,
  color,
  isHighligh,
}: SidebarNotCollapsedItemSectionProps) {
  return (
    <div
      className={`${styles.container} ${isOpen ? styles['container--open'] : ''} ${isHighligh ? styles['container--highlight'] : ''}`}
      onClick={onClick}
    >
      <div className={styles.container__row}>
        <i className={`${styles.container__icon} ${icon}`} style={{ color }} />
        {label}
      </div>

      {shouldShowChevron && (
        <button
          className={`${styles.container__chevron} ${isOpen ? styles['container__chevron--open'] : ''}`}
        >
          <i className="fas fa-chevron-right" />
        </button>
      )}
    </div>
  );
}
