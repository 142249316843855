import styled, { css } from 'styled-components';
import Skeleton from '../core/Skeleton';
import { EmojiContainerProps } from './props';

export const SkeletonRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SkeletonAvatar = styled(Skeleton)<EmojiContainerProps>`
  width: 40px;
  height: 40px;
  border-radius: 50px;

  ${({ isLarge }) =>
    isLarge &&
    css`
      width: 64px;
      height: 64px;
    `}

  ${({ isSmall }) =>
    isSmall &&
    css`
      width: 24px;
      height: 24px;
    `}
`;
