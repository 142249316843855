import { PublicChatMessage } from '@/models/public-chat/public-chat-message';

export type LiveChatStateProps = {
  open: boolean;
  loading: boolean;
  error: string | null;
  chat: PublicChatMessage[];
  counter: number;
  timeOutEndDate: null | Date;
};

export enum LiveChatActions {
  Toggle = 'liveChat/toggle',
  SetInitialLoading = 'liveChat/setInitialLoading',
  UpdateChat = 'liveChat/updateChat',
  ResetCounter = 'liveChat/resetCounter',
  SetTimeOutEndDate = 'liveChat/setTimeOutEndDate',
}

export interface LiveChatBasePayload {
  type: string;
}

export interface ChatMessagePayload extends LiveChatBasePayload {
  payload: {
    message: PublicChatMessage;
  };
}

export type LiveChatStatePayload = LiveChatBasePayload | ChatMessagePayload;
