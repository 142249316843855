import React from 'react';
import LoginLayout from '../Layout';
import AuthModalContentLoginSteps from './Steps';

export default function LoginModal() {
  return (
    <LoginLayout>
      <AuthModalContentLoginSteps />
    </LoginLayout>
  );
}
