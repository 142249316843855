import { GenericError } from '@/models/generic-error';
import { Withdraw } from '@/models/withdraw';

export enum WithdrawBonusActions {
  Request = 'withdrawBonus/requestWithdraw',
  ResetWithdraw = 'withdrawBonus/resetWithdraw',
  GetAmount = 'withdrawBonus/getAmount',
  RequestFail = 'withdrawBonus/requestWithdrawResponseFail',
  SendConfirmationCode = 'withdrawBonus/sendConfirmationCode',
  RequestSuccess = 'withdrawBonus/requestWithdrawResponseSuccess',
}

export interface WithdrawBonusStateProps {
  loading: boolean;
  error: GenericError | false;
  withdraw: Withdraw | null;
  amount: string | null;
}

export interface WithdrawBonusBasePayload {
  type: string;
}

export type WithdrawBonusResetRequest = WithdrawBonusBasePayload;
export type WithdrawBonusRequestSendSms = WithdrawBonusBasePayload;

export interface WithdrawBonusRequestPayload extends WithdrawBonusBasePayload {
  payload: {
    pixKey: string;
    code: string;
  };
}

export interface WithdrawBonusAmountPayload extends WithdrawBonusBasePayload {
  payload: {
    amount: string;
  };
}

export interface WithdrawBonusRequestFailPayload
  extends WithdrawBonusBasePayload {
  payload: GenericError;
}

export interface WithdrawBonusRequestSuccessPayload
  extends WithdrawBonusBasePayload {
  payload: Withdraw;
}

export type WithdrawStatePayload =
  | WithdrawBonusRequestPayload
  | WithdrawBonusRequestFailPayload
  | WithdrawBonusRequestSuccessPayload;
