export const logoSideBarVariants = {
  open: {
    x: 0,
    opacity: 1,
    transition: { duration: 0.25 },
  },
  closed: {
    x: -70,
    opacity: 0,
    transition: { duration: 0.25 },
  },
};

export const logoSideBarVariantsMobile = {
  open: {
    opacity: 0,
    transition: { duration: 0.25 },
  },
  closed: {
    opacity: 1,
    transition: { duration: 0.25 },
  },
};
