import { FormProvider, useForm } from 'react-hook-form';
import useTranslation from 'next-translate/useTranslation';
import { NotificationsService } from '@/services/NotificationsService';
import { ModalsKey } from '@/enums/modalsKey';
import Link from '@/components/core/Link';
import { useLogin } from '@/api/iam';
import styles from './styles.module.scss';
import { Animation } from '@starsoft/common/components';
import { Button } from '@starsoft/common/components';
import { AnimatePresence } from 'framer-motion';
import { AuthLoginRequestDto } from '@/services/dto/auth/auth-login-request.dto';
import { PropsWithChildren, useEffect } from 'react';
import { AuthService } from '@/services/AuthService';
import Requires2faContent from './Requires2fa';
import useAuthSearchParams from '@/hooks/auth/useAuthSearchParams';
import { AuthStep } from '@/enums/auth/authStep';
import OAuthRow from '../../OAuth';
import useAuthForm from '@/hooks/auth/useAuthForm';

export default function LoginLayout({ children }: PropsWithChildren) {
  const methods = useForm<AuthLoginRequestDto>();
  const { handleSubmit, reset, watch } = methods;
  const { mutate, isLoading, requires2fa, onClose } = useLogin();
  const { t } = useTranslation('login');
  const { step, handleSetAuthSearchParams } = useAuthSearchParams();
  const { isInvalidForm, isPasswordStep } = useAuthForm({ watch });

  const onSubmit = ({ email, password, code }: AuthLoginRequestDto) => {
    switch (step) {
      case AuthStep.Email:
        handleSetAuthSearchParams('step', AuthStep.Password);
        break;
      case AuthStep.Password:
        const invalid: boolean =
          email.trim().length == 0 || password.trim().length == 0;

        if (invalid) {
          NotificationsService.error({
            title: 'form_error_title',
            message: 'form_error_description',
          });
          return;
        }

        mutate({ email, password, code });
        break;
    }
  };

  const onError = () => {
    NotificationsService.error({
      title: 'form_error_title',
      message: 'form_error_description',
    });
  };

  function handleResetOtpCode() {
    if (!requires2fa) {
      reset({ code: undefined });
    }
  }

  useEffect(handleResetOtpCode, [requires2fa, reset]);

  return (
    <Animation type="fadeInLeft" key={step}>
      <FormProvider {...methods}>
        <div className={styles.container}>
          <form
            className={styles.container__form}
            id="login-form"
            onSubmit={handleSubmit(onSubmit, onError)}
          >
            <AnimatePresence mode="wait">
              {requires2fa && (
                <Requires2faContent onClose={onClose} isLoading={isLoading} />
              )}
            </AnimatePresence>
            {children}

            {!isPasswordStep && (
              <Link
                className={styles.container__form__link}
                modal={ModalsKey.RecoverPassword}
              >
                {t('forgot_password')}
              </Link>
            )}

            <Button
              type="submit"
              form="login-form"
              isloading={isLoading || AuthService.isAuthorized()}
              disabled={isInvalidForm}
            >
              {t(
                step == AuthStep.Email
                  ? 'common:cancel_register_back'
                  : 'submit_label',
              )}
            </Button>
          </form>
          {!isPasswordStep && (
            <>
              <OAuthRow direction="column" />
              <span className={styles.container__text}>
                {t('register_text')}
                <Link
                  modal={ModalsKey.Register}
                  className={styles.container__text__highlight}
                >
                  {t('register_label')}
                </Link>
              </span>
            </>
          )}
        </div>
      </FormProvider>
    </Animation>
  );
}
