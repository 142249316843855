import { BetType } from '@/models/bet/type';

export enum DatePickerActions {
  SetDateFilter = 'datePicker/setDateFilter',
  ResetDatePicker = 'datePicker/resetDatePicker',
  SetBetType = 'datePicker/setBetType',
}

export interface DatePickerStateProps {
  startDate: Date | null;
  endDate: Date | null;
  betType: BetType;
}

export interface DatePickerBasePayload<T extends DatePickerActions, V = null> {
  type: T;
  payload: V;
}

export type SetDateFilterPayload = DatePickerBasePayload<
  DatePickerActions.SetDateFilter,
  {
    startDate: Date | null;
    endDate: Date | null;
  }
>;

export type SetBetTypePayload = DatePickerBasePayload<
  DatePickerActions.SetBetType,
  BetType
>;

export type SetBetTypeStatePayload =
  | SetDateFilterPayload
  | SetBetTypePayload
  | DatePickerBasePayload<DatePickerActions.ResetDatePicker>;
