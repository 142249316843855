import { AccountQueryKeys } from '@/api/account/queryKeys';
import { WalletsQueryKeys } from '@/api/wallets/queryKeys';
import { Account } from '@/models/account';
import { Wallet } from '@/models/wallet';
import { AccountUpdateDto } from '@/services/dto/accounts/account-update.dto';
import { QueryClient, QueryKey, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

export function useUpdateWalletAndAccounts(): (data: AccountUpdateDto) => void {
  const queryClient: QueryClient = useQueryClient();

  function updateAccount(
    accounts: Account[],
    updatedAccount: Account,
  ): Account[] {
    return accounts.map(account =>
      account.id === updatedAccount.id ? updatedAccount : account,
    );
  }

  const updateWallet = useCallback(
    (wallet: Wallet | undefined, account: Account): Wallet | undefined => {
      if (!wallet) {
        queryClient.invalidateQueries({
          queryKey: [WalletsQueryKeys.Me],
        });
        return wallet;
      }

      return {
        ...wallet,
        accounts: updateAccount(wallet.accounts, account),
        currentAccount:
          wallet.currentAccount.id === account.id
            ? account
            : wallet.currentAccount,
      };
    },
    [queryClient],
  );

  const updateAccounts = useCallback(
    (
      accounts: Account[] | undefined,
      account: Account,
      queryKey: QueryKey,
    ): Account[] | undefined => {
      if (!accounts) {
        queryClient.invalidateQueries({ queryKey });
        return accounts;
      }

      return updateAccount(accounts, account);
    },
    [queryClient],
  );

  const handleQueryDataUpdate = useCallback(
    (account: Account) => {
      queryClient.setQueriesData<Wallet>(
        { queryKey: [WalletsQueryKeys.Me] },
        wallet => updateWallet(wallet, account),
      );
      queryClient.setQueriesData<Account[]>(
        { queryKey: [AccountQueryKeys.Cryptos] },
        accounts =>
          updateAccounts(accounts, account, [AccountQueryKeys.Cryptos]),
      );
      queryClient.setQueriesData<Account[]>(
        { queryKey: [AccountQueryKeys.Fiats] },
        accounts => updateAccounts(accounts, account, [AccountQueryKeys.Fiats]),
      );
      queryClient.setQueriesData<Account>(
        { queryKey: [AccountQueryKeys.ByCoin, { coinId: account.coin.id }] },
        account,
      );
    },
    [queryClient, updateAccounts, updateWallet],
  );

  const onUpdate = useCallback(
    ({ account, delay }: AccountUpdateDto) => {
      if (!!delay) {
        setTimeout(() => handleQueryDataUpdate(account), delay);
        return;
      }

      handleQueryDataUpdate(account);
    },
    [handleQueryDataUpdate],
  );

  return onUpdate;
}
