import NotificationModalHeader from './Header';
import { useUserAccount } from '@/api/user/queries';
import NotificationContentModal from './Content';
import styles from './styles.module.scss';
import NotificationModalTabs from './Tabs';

export default function NotificationsModal() {
  const { user } = useUserAccount();

  if (!user) {
    return null;
  }

  return (
    <div className={styles.container}>
      <NotificationModalHeader />
      <NotificationModalTabs />
      <NotificationContentModal />
    </div>
  );
}
