import baseAPI from '@/api';
import { CryptoBalanceOutputDto } from '@/services/dto/wallets/crypto-balance-output.dto';
import { AxiosResponse } from 'axios';

export async function fetchCryptoBalance(): Promise<CryptoBalanceOutputDto> {
  const response: AxiosResponse<CryptoBalanceOutputDto> =
    await baseAPI.get<CryptoBalanceOutputDto>('wallets/crypto-balance');

  return response.data;
}
