import { AuthStep } from '@/enums/auth/authStep';
import useAuthSearchParams from '@/hooks/auth/useAuthSearchParams';
import LoginEmailContent from './Email';
import LoginPasswordContent from './Password';

export default function AuthModalContentLoginSteps() {
  const { step } = useAuthSearchParams();

  switch (step) {
    case AuthStep.Email:
      return <LoginEmailContent />;
    case AuthStep.Password:
      return <LoginPasswordContent />;
  }
}
