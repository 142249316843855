import { NotificationGroupsMeInputDto } from '@/services/dto/notification-groups/notification-groups-me-input.dto';
import {
  UseNotificationGroupsMeProps,
  UseNotificationGroupsMeQueryKey,
} from './props';
import { NotificationGroupsQueryKey } from '../../queryKey';
import { useQuery } from '@tanstack/react-query';
import { PaginatedResponse } from '@/models/pagination/paginated-response.dto';
import { NotificationGroupWithUnreadCount } from '@/models/notification/notificationGroupWithUnreadCount';
import { AxiosError } from 'axios';
import { GenericError } from '@/models/generic-error';
import { fetchNotificationGroupsMe } from './service';
import { useMemo } from 'react';
import { AuthService } from '@/services/AuthService';
import { NotificationGroupId } from '@/enums/notificationGroupId';

export function useNotificationGroupsMe(
  options?: UseNotificationGroupsMeProps,
) {
  const {
    groupIds = [NotificationGroupId.Inbox, NotificationGroupId.Comissions],
    refetchOnMount,
    enabled = true,
  } = options ?? {};
  const filter: NotificationGroupsMeInputDto = {
    groupIds,
    page: 1,
    limit: 20,
  };
  const queryKey: UseNotificationGroupsMeQueryKey = [
    NotificationGroupsQueryKey.Me,
    filter,
  ];

  const {
    data: notificationGroups,
    isPending,
    error,
    refetch,
    fetchStatus,
  } = useQuery<
    PaginatedResponse<NotificationGroupWithUnreadCount>,
    AxiosError<GenericError>,
    PaginatedResponse<NotificationGroupWithUnreadCount>,
    UseNotificationGroupsMeQueryKey
  >({
    queryKey,
    queryFn: fetchNotificationGroupsMe,
    staleTime: Infinity,
    refetchOnMount,
    enabled: AuthService.isAuthorized() && enabled,
  });

  function getTotalUnreadCount(): number {
    const notificationsGroupsUnreadCount: number[] =
      notificationGroups?.data?.map(
        (notificationGroup: NotificationGroupWithUnreadCount) =>
          notificationGroup.unreadCount,
      ) ?? [];

    let unreadCount: number = 0;

    for (let notificationGroupUnreadCount of notificationsGroupsUnreadCount) {
      unreadCount += notificationGroupUnreadCount;
    }

    return unreadCount;
  }

  const unreadCount = useMemo(getTotalUnreadCount, [notificationGroups]);

  return {
    error,
    refetch,
    isPending: isPending && fetchStatus !== 'idle',
    unreadCount,
    notificationGroups,
  };
}
