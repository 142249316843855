import { SupportMessage } from '@/models/support/message';

export type SupportStateProps = {
  expanded: boolean;
  search: string;
  loadingSocketConnection: boolean;
  messagesIdUploading: string[];
  unreadMessagesRoomsId: number[];
};

export enum SupportActions {
  Expand = 'support/expand',
  HandleChangeSearch = 'support/handleChangeSearch',
  SetInitialLoading = 'support/setInitialLoading',
  UpdateMessages = 'support/updateMessages',
  SetMessageIdUploading = 'support/setMessageIdUploading',
  HandleRemoveImageUploadProgress = 'support/removeImageUploadProgress',
  AddUnreadMessageRoomId = 'support/addUnreadMessageRoomId',
  RemoveUnreadMessageRoomId = 'support/removeUnreadMessageRoomId',
  ResetMessagesRoomsId = 'support/resetMessagesRoomsId',
}

export interface SupportBasePayload<T extends SupportActions, V = null> {
  type: T;
  payload: V;
}

export type HandleChangeSearch = SupportBasePayload<
  SupportActions.HandleChangeSearch,
  string
>;

export type UpdateMessagePayload = SupportBasePayload<
  SupportActions.UpdateMessages,
  SupportMessage
>;

export type MessageIdUploadingPayload = SupportBasePayload<
  | SupportActions.SetMessageIdUploading
  | SupportActions.HandleRemoveImageUploadProgress,
  string
>;

export type UnreadMessagesRoomsIdPayload = SupportBasePayload<
  | SupportActions.AddUnreadMessageRoomId
  | SupportActions.RemoveUnreadMessageRoomId,
  number
>;

export type SetInitalLoadingPayload = SupportBasePayload<
  SupportActions.SetInitialLoading,
  boolean
>;

export type SupportStatePayload =
  | HandleChangeSearch
  | UpdateMessagePayload
  | MessageIdUploadingPayload
  | UnreadMessagesRoomsIdPayload
  | SetInitalLoadingPayload
  | SupportBasePayload<SupportActions.Expand>
  | SupportBasePayload<SupportActions.ResetMessagesRoomsId>;
