import { PageSection } from '@/types/page/props';
import originals from './pages/originals';
import casino from './pages/casino';
import navigation from './pages/navigation';
import sports from './pages/sports';
import { originalsSection } from './originals';
import { casinoSection } from './casino';
import { navigationSection } from './navigation';
import { sportsSection } from './sports';

export const pageSections: PageSection[] = [
  originalsSection,
  casinoSection,
  sportsSection,
  navigationSection,
];

export const pages = [originals, casino, sports, navigation].flat();
