import { RootState } from '@/lib/store';
import { Account } from '@package/models';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export function useCoinFiltering(accounts: Account[]) {
  const hiddenSmalls: boolean = useSelector(
    (state: RootState) => state.wallet.hiddenSmalls,
  );

  function handleCoinsFiltering() {
    let baseAccounts: Account[] = [...(accounts ?? [])];

    if (hiddenSmalls) {
      baseAccounts = baseAccounts.filter(account => account.balance > 0);
    }

    return baseAccounts;
  }

  return useMemo(handleCoinsFiltering, [accounts, hiddenSmalls]);
}
