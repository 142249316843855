import styles from '../styles.module.scss';
import contentStyles from '../Content/BaseContent/styles.module.scss';
import skeleton from './styles.module.scss';

export default function NotificationRowSkeleton() {
  return (
    <div className={`${styles.container} ${styles['container--row']}`}>
      <div className={skeleton.box} />
      <div
        className={`${contentStyles.column} ${contentStyles['column--row']}`}
      >
        <div className={skeleton.title} />
        <div className={skeleton.description} />
        <div className={skeleton.date} />
      </div>
    </div>
  );
}
