import baseAPI from '@/api';
import { AuthLoginRequestDto } from '@/services/dto/auth/auth-login-request.dto';
import { AuthLoginResponseDto } from '@/services/dto/auth/auth-login-response.dto';

export async function fetchLogin({
  email,
  password,
  code,
}: AuthLoginRequestDto) {
  const response = await baseAPI.post<AuthLoginResponseDto>('iam/login', {
    email,
    password,
    code,
  });

  return response.data;
}
