import axios from 'axios';
import { AuthService } from '../services/AuthService';
import { CookiesKey } from '@/enums/cookiesKey';
import { getCookie, hasCookie } from 'cookies-next';
import Router from 'next/router';

const baseAPI = axios.create({
  baseURL: process.env.NEXT_PUBLIC_BASE_API + '/v1/',
  withCredentials: true,
  timeout: 10000,
});

baseAPI.interceptors.request.use(request => {
  if (!request.headers) {
    return request;
  }

  // if (!AuthService.isAuthorized()) {
  //   baseAPI.defaults.baseURL = process.env.NEXT_PUBLIC_BASE_API + '/v1/';
  // }

  const langCookie = getCookie(CookiesKey.Lang);

  if (langCookie) {
    request.headers['x-lang'] = !!langCookie
      ? langCookie.slice(0, 2)
      : langCookie;
  }

  if (hasCookie(CookiesKey.Token)) {
    request.headers['authorization'] = getCookie(CookiesKey.Token);
  }

  if (hasCookie(CookiesKey.RefreshToken)) {
    request.headers['x-refresh-token'] = getCookie(CookiesKey.RefreshToken);
  }

  return request;
});

baseAPI.interceptors.response.use(
  function (response) {
    AuthService.refreshAuthorization();
    if (response?.data?.accessToken) {
      AuthService.setToken(response.data.accessToken);
      baseAPI.defaults.headers['autorization'] = response.data.accessToken;
    }

    if (response?.data?.refreshToken) {
      AuthService.setRefreshToken(response.data.refreshToken);
      baseAPI.defaults.headers['x-refresh-token'] = response.data.refreshToken;
    }

    // if (
    //   response?.request?.responseURL?.includes('/users/me') &&
    //   response?.data?.id == 11
    // ) {
    //   baseAPI.defaults.baseURL =
    //     process.env.NEXT_PUBLIC_SOFTSWISS_API_URL + '/v1/';
    // }

    return response;
  },
  function handleError(error) {
    const method: string = error.response?.config?.method ?? '';

    if (
      error?.response?.data?.statusCode == 401 &&
      method.toUpperCase() === 'GET'
    ) {
      AuthService.logOut();
      Router.prefetch('/');
      Router.push('/');
      return Promise.reject(error);
    }

    return Promise.reject(error);
  },
);

export default baseAPI;
