import {
  useInfiniteQueryCallbacks,
  useInfiniteQueryReset,
} from '@package/hooks';
import { CoinsQueryKey } from '../../queryKeys';
import { UseCoinsQueryKey } from './props';
import { InfiniteData, useInfiniteQuery } from '@tanstack/react-query';
import { Coin, GenericError, PaginatedResponse } from '@package/models';
import { AxiosError } from 'axios';
import { fetchCoins } from './service';
import { useMemo } from 'react';

export function useCoins() {
  const queryKey: UseCoinsQueryKey = [
    CoinsQueryKey.Primary,
    {
      limit: 50,
    },
  ];

  const { getNextPageParam, getPreviousPageParam } =
    useInfiniteQueryCallbacks();
  useInfiniteQueryReset(queryKey);

  const { data, ...infinityQuery } = useInfiniteQuery<
    PaginatedResponse<Coin>,
    AxiosError<GenericError>,
    InfiniteData<PaginatedResponse<Coin>>,
    UseCoinsQueryKey
  >({
    queryKey,
    initialPageParam: 1,
    queryFn: fetchCoins,
    getNextPageParam,
    getPreviousPageParam,
    staleTime: 20 * 1000,
  });

  const formattedData: Coin[] = useMemo(
    () => data?.pages?.map(page => page?.data)?.flat() as Coin[],
    [data],
  );

  return {
    ...infinityQuery,
    coins: formattedData,
  };
}
