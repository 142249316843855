import { DepositActions, DepositProps, DepositStatePayload } from './props';

const initialState: DepositProps = {
  currentCoin: null,
  currentChain: null,
};

export default function reducer(
  state: DepositProps = initialState,
  action: DepositStatePayload,
): DepositProps {
  switch (action.type) {
    case DepositActions.SetCoin:
      return {
        ...state,
        currentCoin: action.payload,
      };
    case DepositActions.SetChain:
      return {
        ...state,
        currentChain: action.payload,
      };
    default:
      return state;
  }
}
