import NotificationRow from '../../NotificationRow';
import NotificationRowSkeleton from '../../NotificationRow/Skeleton';
import { NotificationRowType } from '../../NotificationRow/props';
import { useMemo } from 'react';
import { useNotificationsMe } from '@/api/notifications';
import { RootState } from '@/lib/store';
import { NotificationGroupId } from '@/enums/notificationGroupId';
import { useSelector } from 'react-redux';
import styles from './styles.module.scss';
import { Notification } from '@/models/notification/notification';
import { useInfiniteScroll } from '@starsoft/common/hooks';
import { ErrorCard, NoDataComponent } from '@starsoft/common/components';

export default function NotificationContentModal() {
  const tab: NotificationGroupId = useSelector(
    (state: RootState) => state.notifications.tab,
  );
  const {
    notifications,
    isPending,
    isFetchingNextPage,
    hasNextPage,
    error,
    fetchNextPage,
    refetch,
  } = useNotificationsMe({
    groupId: tab,
    refetchOnMount: 'always',
  });
  const { ref: scrollContainerRef } = useInfiniteScroll({
    hasMore: hasNextPage ?? false,
    isLoading: isPending || isFetchingNextPage,
    loadMore: () => fetchNextPage(),
  });

  const Notifications = useMemo(
    () =>
      notifications?.map(notification => (
        <NotificationRow
          key={'notification-' + notification.id}
          notification={notification as Notification}
          type={NotificationRowType.Row}
        />
      )),
    [notifications],
  );

  const Skeletons = useMemo(
    () =>
      Array.from({ length: 8 }).map((_, index) => (
        <NotificationRowSkeleton key={`notification-skeleton-${index}`} />
      )),
    [],
  );

  function handleError() {
    refetch?.();
  }

  if (error) {
    return <ErrorCard error={error} refetch={handleError} />;
  }

  if (!isPending && notifications?.length === 0) {
    return (
      <div className={styles.container}>
        <NoDataComponent />
      </div>
    );
  }

  return (
    <div className={styles.container} ref={scrollContainerRef}>
      {Notifications}
      {isPending && Skeletons}
    </div>
  );
}
