import baseAPI from '@/api';
import { ChangeCurrentCoinRequestDto } from '@/services/dto/wallets/change-current-coin-request.dto';
import { ChangeCurrentCoinResponseDto } from '@/services/dto/wallets/change-current-coin-response.dto';

export async function selectCoin({ coinId }: ChangeCurrentCoinRequestDto) {
  const response = await baseAPI.patch<ChangeCurrentCoinResponseDto>(
    'wallets/select-coin',
    {
      coinId,
    },
  );

  return response.data;
}
