import { SidebarContentCollapsedPageProps } from './props';
import useTranslation from 'next-translate/useTranslation';
import styles from './styles.module.scss';
import Link from '@/components/core/Link';
import { Image as CoreImage } from '@starsoft/common/components';
import { ToolTip as CustomToolTip } from '@starsoft/common/components';
import { useIsPageActive } from '@/hooks/useIsPageActive';
import useTransactionsNavigation from '@/hooks/useTransacionsNavigation';
import { ModalsKey } from '@/enums/modalsKey';

export default function SidebarContentCollapsedPage({
  page,
  isHightligh,
  shouldNotHighlight,
}: SidebarContentCollapsedPageProps) {
  const { t } = useTranslation('common');
  const isActive: boolean = useIsPageActive(page);
  const { getModalSearchParams } = useTransactionsNavigation();

  return (
    <CustomToolTip
      titleComponent={t(page.label, {
        provider: process.env.NEXT_PUBLIC_BET_NAME,
        app_provider: process.env.NEXT_PUBLIC_BET_NAME,
      })}
    >
      <div>
        <Link
          className={`${styles.container} ${isActive && !shouldNotHighlight ? styles['container--current'] : ''} ${isHightligh ? styles['container--highlight'] : ''}`}
          privateRoute={page.isProtected}
          href={
            [ModalsKey.Deposit, ModalsKey.Withdraw].includes(page?.modal)
              ? getModalSearchParams()
              : page.paths?.[0]
          }
          modal={page?.modal}
          onClick={() => page?.clickHandler?.()}
        >
          {page?.isImage ? (
            <CoreImage
              className={styles.container__image}
              src={page.icon}
              alt={t(page.label)}
              format="square"
              width={20}
              height={20}
            />
          ) : (
            <i className={`${styles.container__icon} ${page.icon}`} />
          )}
        </Link>
      </div>
    </CustomToolTip>
  );
}
