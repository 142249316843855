import baseAPI from '@/api';
import { Wallet } from '@/models/wallet';
import { AxiosResponse } from 'axios';

export async function fetchWalletsMe(): Promise<Wallet> {
  const response: AxiosResponse<Wallet> =
    await baseAPI.get<Wallet>('wallets/me');
  const wallet: Wallet = response.data;

  return wallet;
}
