import { Tabs } from '@/models/fortune-double-tabs';
import {
  FortuneDoubleActions,
  FortuneDoubleProps,
  FortuneDoubleStatePayload,
  FortuneDoubleUpdateRoundPayload,
} from './props';
import { RouletteColor } from '@/models/roulette/color';

const initialState: FortuneDoubleProps = {
  prizeList: [],
  closedRound: false,
  color: RouletteColor.White,
  isSocketConnected: false,
  activeTab: Tabs.Tutorial,
  currentStep: 0,
  hasSeenBefore: false,
  round: null,
};

export default function reducer(
  state: FortuneDoubleProps = initialState,
  action: FortuneDoubleStatePayload,
): FortuneDoubleProps {
  switch (action.type) {
    case FortuneDoubleActions.UpdateRound: {
      return {
        ...state,

        closedRound: false,
        round: (action as FortuneDoubleUpdateRoundPayload).payload.round,
      };
    }
    case FortuneDoubleActions.HandleStoppedRoulette:
      return {
        ...state,
        closedRound: true,
      };
    case FortuneDoubleActions.ChangeColor:
      return {
        ...state,
        color: action.payload.color,
      };
    case FortuneDoubleActions.Reset:
      return {
        ...state,
        round: null,
        closedRound: false,
        color: RouletteColor.White,
      };
    case FortuneDoubleActions.SetSocketConnection: {
      return {
        ...state,
        isSocketConnected: action.payload.isSocketConnected,
      };
    }
    case FortuneDoubleActions.SetActiveTab: {
      return {
        ...state,
        activeTab: action.payload.activeTab,
      };
    }
    case FortuneDoubleActions.SetCurrentStep: {
      return {
        ...state,
        currentStep: action.payload.currentStep,
      };
    }
    case FortuneDoubleActions.SetHasSeenBefore: {
      return {
        ...state,
        hasSeenBefore: action.payload.hasSeenBefore,
      };
    }
    default:
      return state;
  }
}
