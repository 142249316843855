import {
  AddParticlePayload,
  AddToPointPayload,
  ParticleActions,
  ParticleState,
  PlinkoStatePayload,
} from './props';

const initialState: ParticleState = {
  particles: [],
};

export default function particleReducer(
  state: ParticleState = initialState,
  action: PlinkoStatePayload,
): ParticleState {
  switch (action.type) {
    case ParticleActions.ADD_PARTICLE:
      return {
        ...state,
        particles: [
          ...state.particles,
          (action as AddParticlePayload).payload.particle,
        ],
      };
    case ParticleActions.REMOVE_PARTICLE:
      return {
        ...state,
        particles: state.particles.filter(
          (_, index) => index !== state.particles.length - 1,
        ),
      };
    case ParticleActions.ADD_PARTICLE_TO_POINT:
      const lastParticle = state.particles?.[state.particles.length - 1];
      const filteredParticles = state.particles.filter(
        (_, index) => index !== state.particles.length - 1,
      );

      if (lastParticle) {
        return {
          ...state,
          particles: [
            ...filteredParticles,
            {
              ...lastParticle,
              to: (action as AddToPointPayload).payload.toPoint,
            },
          ],
        };
      }
      return state;
    default:
      return state;
  }
}
