import useTranslation from 'next-translate/useTranslation';
import { RankLabelProps } from './props';
import styles from './styles.module.scss';
import RankLabelSkeleton from './Skeleton';
import { Image } from '@starsoft/common/components';

export default function RankLabel({
  rank,
  loading = false,
  onlyRankImage,
}: RankLabelProps) {
  const { t } = useTranslation('common');

  const baseUrl = `${process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL}/ranks/medal/`;

  if (loading) {
    return <RankLabelSkeleton onlyRankImage={onlyRankImage} />;
  }

  return (
    <div className={styles.container}>
      <Image
        className={styles.container__image}
        width={20}
        height={20}
        src={`${baseUrl}${rank?.group?.image}`}
        alt="rank"
        secondarySkeleton
      />

      {!onlyRankImage && (
        <span className={styles.container__label}>
          {rank?.name ?? t('no_rank_label')}
        </span>
      )}
    </div>
  );
}
