import { IAddBetNotification } from './props';
import { AlertType } from '@/models/notification/alert/alert-type.enum';
import games from '@/constants/games';
import { getStore } from '@/lib/store';
import {
  addNotificationToasty,
  removeNotificationToasty,
} from '@/lib/store/notifications/actions';
import { generateRandomId } from '@/utils/generateRandomId';
import { Notification } from '@/models/notification/notification';
import { NotificationGroupId } from '@/enums/notificationGroupId';
import { NotificationTypeId } from '@/enums/notificationTypeId';
import { AlertExtraParams } from '@/models/notification/alert';
import { Bet } from '@/models/bet';
import { Game } from '@/models/game';

export const NOTIFICATION_DURATION: number = 10000;
export class NotificationsService {
  static addNotification(notification: Notification) {
    const store = getStore();

    store.dispatch(addNotificationToasty(notification));

    setTimeout(() => {
      store.dispatch(removeNotificationToasty(notification.id));
    }, NOTIFICATION_DURATION);
  }

  static addBetNotification({ gameId, ...bet }: IAddBetNotification) {
    const id: number = generateRandomId();
    const game = games.get(gameId);

    const betWithGame: Bet = {
      ...bet,
      game: game as unknown as Game,
    };
    const notification: Notification = {
      id,
      pinned: false,
      type: {
        id: NotificationTypeId.GameBet,
        group: {
          id: NotificationGroupId.Inbox,
          name: 'Inbox',
        },
        name: 'GameBet',
      },
      extra: betWithGame,
      createdAt: `${new Date()}`,
    };

    NotificationsService.addNotification(notification);
  }

  static remove(id: number): void {
    const store = getStore();
    store.dispatch(removeNotificationToasty(id));
  }

  private static addAlert(alertExtraParams: AlertExtraParams) {
    const id: number = generateRandomId();

    const notification: Notification = {
      id,
      pinned: false,
      type: {
        id: NotificationTypeId.Alert,
        group: {
          id: NotificationGroupId.Inbox,
          name: 'Inbox',
        },
        name: 'Alert',
      },
      extra: alertExtraParams,
      createdAt: `${new Date()}`,
    };

    NotificationsService.addNotification(notification);
  }

  static info({ title, message, params }: Omit<AlertExtraParams, 'type'>) {
    NotificationsService.addAlert({
      title,
      message,
      params,
      type: AlertType.Info,
    });
  }

  static error({ title, message, params }: Omit<AlertExtraParams, 'type'>) {
    NotificationsService.addAlert({
      title,
      message,
      params,
      type: AlertType.Error,
    });
  }

  static success({ title, message, params }: Omit<AlertExtraParams, 'type'>) {
    NotificationsService.addAlert({
      title,
      message,
      params,
      type: AlertType.Success,
    });
  }
}
