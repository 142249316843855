import useTranslation from 'next-translate/useTranslation';
import OAuthButton from './Button';
import styles from './styles.module.scss';
import { OAuthProps } from './props';

export default function OAuthRow({ direction = 'row' }: OAuthProps) {
  const { t: tRegister } = useTranslation('register');

  return (
    <div className={`${styles.container} `}>
      <span className={styles.container__text}>
        {tRegister('register_label_binding')}
      </span>

      <div
        className={`${styles.container__grid} ${direction == 'column' ? styles['container__grid--column'] : ''}`}
      >
        <OAuthButton
          to={process.env.NEXT_PUBLIC_GOOGLE_OAUTH_URL as string}
          image={`${process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL}/artworks/google-icon.svg`}
          label="google_label"
        />

        <OAuthButton
          to={process.env.NEXT_PUBLIC_DISCORD_OAUTH_URL as string}
          image={`${process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL}/artworks/discord-icon.svg`}
          label="discord_label"
        />
      </div>
    </div>
  );
}
