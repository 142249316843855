import baseAPI from '@/api';
import { Success } from '@/models/success';
import { GenerateRecoveryPasswordTokenInputDto } from '@/services/dto/recovery-passwords/generate-recovery-password-token-input.dto';
import { AxiosResponse } from 'axios';

export async function fetchGenerateRecoveryPasswordToken(
  data: GenerateRecoveryPasswordTokenInputDto,
): Promise<Success> {
  const response: AxiosResponse<Success> = await baseAPI.post<Success>(
    '/recovery-passwords/generate-token',
    data,
  );
  return response.data;
}
