import { GameSlug } from '@/enums/gameSlug';
import { PageProps } from '@/types/page/props';

const baseGameUrl: string = '/games/tag/originals';

export default <PageProps[]>[
  {
    label: 'tab_fortune_double',
    paths: [`${baseGameUrl}/${GameSlug.Frost}`],
    icon: 'fa-solid fa-teddy-bear',
  },
  {
    label: 'tab_splash',
    paths: [`${baseGameUrl}/${GameSlug.Splash}`],
    icon: 'fa-solid fa-tomato',
  },
  {
    label: 'tab_multiply',
    paths: [`${baseGameUrl}/${GameSlug.Double}`],
    icon: 'fa-solid fa-cards',
  },
  {
    label: 'tab_mines',
    paths: [`${baseGameUrl}/${GameSlug.Mines}`],
    icon: 'fa-solid fa-bomb',
  },
  {
    label: 'tab_smash_racing',
    paths: [`${baseGameUrl}/${GameSlug.Crash}`],
    icon: 'fa-solid fa-rocket-launch',
  },
  {
    label: 'tab_dice',
    paths: [`${baseGameUrl}/${GameSlug.Dice}`],
    icon: 'fa-solid fa-dice',
  },
  {
    label: 'tab_coinflip',
    paths: [`${baseGameUrl}/${GameSlug.Coinflip}`],
    icon: 'fa-sharp fa-solid fa-coin-blank',
  },
  {
    label: 'tab_tower',
    paths: [`${baseGameUrl}/${GameSlug.Tower}`],
    icon: 'fa-solid fa-monkey',
  },
  {
    label: 'tab_limbo',
    paths: [`${baseGameUrl}/${GameSlug.Limbo}`],
    icon: 'fa-solid fa-shuttle-space fa-rotate-270',
  },
  {
    label: 'tab_slide',
    paths: [`${baseGameUrl}/${GameSlug.Slide}`],
    icon: 'fa-solid fa-rectangle-vertical-history',
  },
];
