import { useFormContext } from 'react-hook-form';
import useTranslation from 'next-translate/useTranslation';
import { Input } from '@starsoft/common/components';
import { AuthLoginRequestDto } from '@/services/dto/auth/auth-login-request.dto';
import { useEffect } from 'react';

export default function LoginPasswordContent() {
  const { register, setFocus } = useFormContext<AuthLoginRequestDto>();
  const { t } = useTranslation('login');

  function onMount() {
    setFocus('password');
  }

  useEffect(onMount, [setFocus]);

  return (
    <Input
      type="password"
      placeholder={t('password_placeholder')}
      name="password"
      register={register}
      registerOptions={{
        required: true,
      }}
    />
  );
}
