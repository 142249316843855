export enum ParticleActions {
  ADD_PARTICLE = 'particle/add_particle',
  REMOVE_PARTICLE = 'particle/remove_particle',
  ADD_PARTICLE_TO_POINT = 'particle/add_particle_to_point',
}

export interface Point {
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface Particle {
  id: string;
  from: Point;
  to: Point | null;
}

export interface ParticleState {
  particles: Particle[];
}

export interface ParticleBasePayload {
  type: ParticleActions;
}

export interface AddParticlePayload extends ParticleBasePayload {
  payload: {
    particle: Particle;
  };
}

export interface AddToPointPayload extends ParticleBasePayload {
  payload: {
    toPoint: Point;
  };
}

export type PlinkoStatePayload = ParticleBasePayload | AddParticlePayload;
