import { Suspense } from 'react';
import { useRankStatusByUser } from '@/api/rank-status';
import {
  Column,
  Container,
  Progress,
  ProgressContainer,
  Username,
} from './styles';
import { useUserAccount } from '@/api/user/queries';
import { UserRankCardSkeleton } from './Skeleton';
import EmojiAvatar from '../EmojiAvatar';
import Skeleton from '../core/Skeleton';
import { usePathname } from '@/hooks/usePathname';
import { ErrorChip } from '@starsoft/common/components';
import Link from '../core/Link';
import { ModalsKey } from '@/enums/modalsKey';

export function UserRankCard() {
  const { isLoading, rankStatus, error, refetch } = useRankStatusByUser();
  const { user } = useUserAccount();
  const pathname: string = usePathname();

  if (error) {
    return <ErrorChip action={refetch} />;
  }

  if (isLoading) {
    return <UserRankCardSkeleton />;
  }

  return (
    <Container as={Link} modal={ModalsKey.ProfileDetails} privateRoute>
      <EmojiAvatar isHeader onClick={() => {}} />

      <Column>
        <Username>{user?.player?.username}</Username>
        <ProgressContainer>
          <Suspense
            fallback={<Skeleton style={{ width: '100%', height: '8px' }} />}
          >
            <Progress
              progress={Number(rankStatus?.progress ?? 0)}
              rankColor={rankStatus?.rank?.group?.color ?? null}
            />
          </Suspense>
        </ProgressContainer>
      </Column>
    </Container>
  );
}
