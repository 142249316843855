import { ModalsKey } from '@/enums/modalsKey';
import { useModal } from '@/hooks/modals/useModal';
import { AuthService } from '@/services/AuthService';
import { useModalNavigation } from '@/hooks/modals/useModalNavigation';
import { useRouter } from 'next/router';
import AuthModalLayout from '../Layout';
import { authModalOptions } from './options';
import { useCallback } from 'react';
import { Animation } from '@package/components';
import useAuthSearchParams from '@/hooks/auth/useAuthSearchParams';
import { AuthStep } from '@/enums/auth/authStep';

export default function AuthModalsOptions({ id }: { id: number }) {
  const { asPath } = useRouter();
  const { navigateToModal } = useModalNavigation();
  const currentRoute = asPath?.split('#')?.[1];
  const { key, title, component } = authModalOptions?.[currentRoute] ?? {};
  const { isOpen, handleContainer, onClose } = useModal(key);
  const { step } = useAuthSearchParams();

  const showBackButton =
    [ModalsKey.Login, ModalsKey.Register].includes(currentRoute as ModalsKey) &&
    step != AuthStep.Email;

  const handleCloseModal = useCallback(() => {
    if (key == ModalsKey.Register) {
      navigateToModal(ModalsKey.CancelRegister);
      return;
    }

    onClose();
  }, [key, onClose, navigateToModal]);

  const shouldOpenModal = !AuthService.isAuthorized() && isOpen;

  function getDescription(): string {
    switch (key) {
      case ModalsKey.RecoverPassword:
        return 'recover_password_description';
      case ModalsKey.RecoverPasswordReset:
        return 'recover_password_reset_description';
      default:
        return '';
    }
  }

  return (
    <AuthModalLayout
      title={title}
      closeModalOnClickOutside={handleCloseModal}
      handleContainer={handleContainer}
      id={id}
      description={getDescription()}
      open={shouldOpenModal}
      isFullHeight
      withBackButton={showBackButton}
    >
      <Animation type={key == ModalsKey.Login ? 'fadeInRight' : 'fadeInLeft'}>
        {component}
      </Animation>
    </AuthModalLayout>
  );
}
