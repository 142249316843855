import { QueryFunctionContext } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import baseAPI from '@/api';
import { PaginatedResponse } from '@/models/pagination/paginated-response.dto';
import { UseBonusRewardTypesStatisticsQueryKeyProps } from './props';
import { BonusRewardType } from '@/models/bonusReward/bonusRewardType';

export async function fetchBonusRewardTypesStatistics({
  queryKey,
}: QueryFunctionContext<UseBonusRewardTypesStatisticsQueryKeyProps>) {
  const { page, limit } = queryKey[1];

  const response: AxiosResponse<PaginatedResponse<BonusRewardType>> =
    await baseAPI.get<PaginatedResponse<BonusRewardType>>(
      '/bonus-reward-types/statistics',
      {
        params: {
          page,
          limit,
        },
      },
    );

  return response.data;
}
