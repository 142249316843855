import { useTimeAgo } from '@starsoft/common/hooks';
import { BaseContentProps } from './props';
import { NotificationRowType } from '../../props';
import { Button } from '@starsoft/common/components';
import useTranslation from 'next-translate/useTranslation';
import styles from './styles.module.scss';
import Link from '@/components/core/Link';

export function BaseContent({
  title,
  description,
  icon,
  createdAt,
  type,
  isError,
  showViewButton,
  actionLink = '',
  onActionClick,
}: BaseContentProps) {
  const { t } = useTranslation('data-grid');
  const formattedDate = useTimeAgo({
    date: new Date(createdAt),
  });
  const isRow = type === NotificationRowType.Row;

  function handleActionClick() {
    onActionClick?.();
  }

  return (
    <>
      <div
        className={`${styles.box} ${isError ? styles['box--error'] : ''} ${isRow ? styles['box--row'] : ''}`}
      >
        <i className={icon} />
      </div>
      <div className={styles.row}>
        <div
          className={`${styles.column} ${isRow ? styles['column--row'] : ''}`}
        >
          <h6 className={styles.column__title}>{title}</h6>
          <p className={styles.column__description}>{description}</p>
          <span className={styles.column__time}>{formattedDate}</span>
        </div>

        {showViewButton && type === NotificationRowType.Row && (
          <Link
            className={styles.row__link}
            href={actionLink}
            onClick={handleActionClick}
          >
            <Button isSmall>{t('table_affiliates_see_button')}</Button>
          </Link>
        )}
      </div>
    </>
  );
}
