import { GridDifficulty } from '@/models/bet/grid/difficulty';

import {
  MissionUncrossableProps,
  MissionUncrossableActions,
  MissionUncrossableStatePayload,
} from './props';

const initialState: MissionUncrossableProps = {
  difficulty: GridDifficulty.Easy,
  walking: false,
  showTomato: true,
  restartBoard: true,
};

export default function reducer(
  state: MissionUncrossableProps = initialState,
  action: MissionUncrossableStatePayload,
): MissionUncrossableProps {
  switch (action.type) {
    case MissionUncrossableActions.SetDifficulty:
      return {
        ...state,
        difficulty: action.payload,
      };
    case MissionUncrossableActions.SetWalking:
      return {
        ...state,
        walking: !state.walking,
      };
    case MissionUncrossableActions.RestartAnimation:
      return {
        ...state,
        showTomato: action.payload,
      };
    case MissionUncrossableActions.ResetBoard:
      return {
        ...state,
        restartBoard: action.payload,
      };
    default:
      return state;
  }
}
