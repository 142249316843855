import { useMemo } from 'react';
import { usePathname } from '../usePathname';
import { CasinoGameMode } from '@/lib/store/casino/props';

export function useShowNavigation() {
  const pathname: string = usePathname();

  const showNavigation = useMemo(
    () =>
      [CasinoGameMode.Real, CasinoGameMode.Demo].some(segment =>
        pathname.endsWith(segment),
      ),
    [pathname],
  );

  return !showNavigation;
}
