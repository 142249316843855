import { usePathname, useSearchParams } from '@package/hooks';
import { UseRegisterVoucherProps } from './props';
import { AuthService } from '@/services/AuthService';
import { ModalsKey } from '@/enums/modalsKey';
import { useEffect } from 'react';

export default function useRegisterVoucher({
  setValue,
}: UseRegisterVoucherProps) {
  const search: URLSearchParams = useSearchParams();
  const pathname: string = usePathname();

  const searchVoucher = search.get('voucher');
  const parsedSearchVoucher = searchVoucher
    ?.replace(`${pathname}`, '')
    ?.replace(ModalsKey.Register, '')
    ?.replaceAll('/', '');
  const cookieVoucher = AuthService.getVoucher();

  const coallescedVoucher = parsedSearchVoucher ?? cookieVoucher;

  function handleUpdateCookieVoucher() {
    if (parsedSearchVoucher) {
      AuthService.setVoucher(parsedSearchVoucher);
    }
    setValue('voucher', coallescedVoucher);
  }

  useEffect(handleUpdateCookieVoucher, [
    parsedSearchVoucher,
    setValue,
    coallescedVoucher,
  ]);

  return {
    voucher: coallescedVoucher,
  };
}
