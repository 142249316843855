import useTranslation from 'next-translate/useTranslation';
import styles from './styles.module.scss';
import { useLogout } from '@/api/iam';

export default function LogoutButton() {
  const { t } = useTranslation('common');
  const { mutate } = useLogout();

  return (
    <div className={styles.container} onClick={mutate}>
      <i
        className={`fa-solid fa-right-from-bracket ${styles.container__icon}`}
      />

      <div className={styles.container__relative}>
        <span className={styles.container__label}>
          {t('logout_button', {
            app_provider: process.env.NEXT_PUBLIC_BET_NAME,
          })}
        </span>
      </div>
    </div>
  );
}
