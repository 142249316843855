import { Transition, Variants } from 'framer-motion';

const transition: Transition = { duration: 0.3, ease: 'circIn' };

export const slideInRight: Variants = {
  hidden: {
    x: 200,
    pointerEvents: 'none',
    transition,
  },
  visible: {
    x: 0,
    transition,
    pointerEvents: 'all',
  },
};
