import { useMutation } from '@tanstack/react-query';
import { fetchLogin } from './service';
import { AuthLoginResponseDto } from '@/services/dto/auth/auth-login-response.dto';
import { ErrorService } from '@/services/ErrorService';
import { AxiosError } from 'axios';
import { GenericError } from '@/models/generic-error';
import { useLoginCallback } from '@/hooks/useLogInCallback';
import React, { useEffect, useState } from 'react';

export function useLogin() {
  const [requires2fa, setRequires2fa] = useState<boolean>(false);
  const logIn = useLoginCallback();

  function onSuccess(data: AuthLoginResponseDto) {
    if ('requires2FA' in data) {
      setRequires2fa(true);
    } else {
      logIn(data);
    }
  }

  function handleUnmount2fa() {
    return () => {
      setRequires2fa(false);
    };
  }

  function onClose(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    e.stopPropagation();
    setRequires2fa(false);
  }

  useEffect(handleUnmount2fa, []);

  function onError(error: AxiosError<GenericError>) {
    ErrorService.onError(error);
  }

  const { mutate, isPending, error } = useMutation({
    mutationFn: fetchLogin,
    onSuccess,
    onError,
  });

  return {
    mutate,
    isLoading: isPending,
    error,
    requires2fa,
    onClose,
  };
}
