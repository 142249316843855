import tabItems from './items';
import { CommingSoon, TabContainer, TabItem } from './styles';
import { ItemProps, TabProps } from './props';
import useTranslation from 'next-translate/useTranslation';
import { usePathname } from '@/hooks/usePathname';

function Tab({ isSecondary }: TabProps) {
  const pathname: string = usePathname();
  const { t } = useTranslation('common');

  return (
    <TabContainer isSecondary={isSecondary}>
      {tabItems.map((item: ItemProps) => {
        return (
          <TabItem
            key={`tab_item_${item.path}`}
            isSecondary={isSecondary}
            href={item.path}
            selected={
              pathname.includes(item.path) ||
              item.activePaths.some(path => pathname.includes(path))
            }
            active={item.active}
          >
            {!item.active && <CommingSoon>{t('slots_soon')}</CommingSoon>}
            <i className={item.icon} style={{ color: item?.iconColor }} />
            <span>{t(item.name)}</span>
          </TabItem>
        );
      })}
    </TabContainer>
  );
}

export default Tab;
