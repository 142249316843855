import baseAPI from '@/api';
import { ReferralVoucher } from '@/models/referral-voucher';
import { ReferralVoucherByCodeRequest } from '@/services/dto/referral-vouchers/referral-voucher-by-code-request.dto';
import { QueryFunctionContext } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

export async function fetchReferralVoucherByCode({
  queryKey,
}: QueryFunctionContext): Promise<ReferralVoucher> {
  const { voucher } = queryKey[1] as ReferralVoucherByCodeRequest;

  const response: AxiosResponse<ReferralVoucher> =
    await baseAPI.get<ReferralVoucher>('referral-vouchers/by-code', {
      params: {
        voucher,
      },
    });

  return response.data;
}
