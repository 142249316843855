module.exports = {
  locales: ['zh', 'ru', 'pt', 'fr', 'es', 'en', 'de', 'id'],
  defaultLocale: 'en',
  pages: {
    '*': [
      'common',
      'notify',
      'support',
      'account',
      'seo',
      'login',
      'register',
      'withdraw',
      'deposit',
      'modal',
      'redirect',
      'refer',
      'bonus',
      'range-picker',
      'game-tag',
      'data-grid',
      'chip',
    ],
    '/': ['common', 'data-grid', 'chip'],
    '/term/[term]': ['terms'],
    '/vip-club': ['vip-club'],
    '/tournaments': ['tournaments'],
    '/tournaments/[slug]': ['tournaments'],
    '/tournaments/[slug]/[tab]': ['tournaments'],
    '/sportsbook-betting-rules': ['terms'],
    '/games/tag/[tag]': ['casino'],
    '/games/tag/originals/coinflip-originals': [
      'casino',
      'coinflip',
      'game-report',
      'smash-racing',
    ],
    '/games/tag/originals/double-originals': [
      'roulette',
      'game-report',
      'smash-racing',
    ],
    '/games/tag/originals/crash-originals': ['game-report', 'smash-racing'],
    '/games/tag/originals/frost-originals': [
      'roulette',
      'game-report',
      'smash-racing',
    ],
    '/games/tag/originals/dice-originals': [
      'dice',
      'game-report',
      'smash-racing',
    ],
    '/games/tag/originals/limbo-originals': [
      'limbo',
      'game-report',
      'smash-racing',
    ],
    '/games/tag/originals/mines-originals': [
      'mines',
      'game-report',
      'smash-racing',
    ],
    '/games/tag/originals/splash-originals': [
      'mission-uncrossable',
      'game-report',
      'smash-racing',
    ],
    '/games/providers': ['casino'],
    '/games/providers/[slug]': ['casino'],
    '/games/tag/originals/slide-originals': [
      'slide',
      'game-report',
      'smash-racing',
    ],
    '/games/tag/[tag]/[gameSlug]': ['casino'],
    '/games/tag/originals/tower-originals': [
      'tower',
      'game-report',
      'smash-racing',
    ],
    '/gamblers/[slug]': ['sportsbook'],
    '/forgot-password': ['password-recover'],
    '/failure-redirect': ['failure-redirect'],
    '/reset-password': ['password-recover'],
    '/reset-password-success': ['password-recover'],
    '/reset-password-verify': ['password-recover'],
    '/bonus-history': ['account'],
    '/copy-history': ['game-history', 'sportsbook', 'account'],
    '/account/deposit-history': ['deposit-history', 'account'],
    '/account/game-history': ['game-history', 'sportsbook', 'account'],
    '/account/withdraw-history': ['withdraw-history', 'account'],
    '/account': ['redirect', 'account'],
    '/account/verification': ['redirect', 'account', 'verification'],
    '/account/preferences': ['redirect', 'account', 'verification'],
    '/account/security': ['account', 'verification'],
    '/account/wallet': ['account'],
    '/account/password': ['verification', 'account'],
    '/affiliates/[tab]': ['affiliates'],
  },
};
