import { useFormContext } from 'react-hook-form';
import { RegisterFormProps } from '@/types/register/props';
import AuthModalContentRegisterEmailInput from '../../../Common/EmailInput';
import useAuthForm from '@/hooks/auth/useAuthForm';

export default function AuthModalRegisterEmailContent(): JSX.Element {
  const { watch } = useFormContext<RegisterFormProps>();
  const { isValidEmail } = useAuthForm({ watch });

  return <AuthModalContentRegisterEmailInput isValidEmail={isValidEmail} />;
}
