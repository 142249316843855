import { useMutation } from '@tanstack/react-query';
import { UseChangePasswordProps } from './props';
import { fetchChangePassword } from './service';

export function useChangePassword(props?: UseChangePasswordProps) {
  const { externalOnSuccess: externalSuccess }: UseChangePasswordProps =
    props ?? {};
  const { mutate, isPending } = useMutation({
    mutationFn: fetchChangePassword,
    onSuccess: externalSuccess,
  });

  return {
    changePassword: mutate,
    isPending,
  };
}
