import { BaseContent } from '..';
import useTranslation from 'next-translate/useTranslation';
import WalletService from '@/services/WalletService';
import { NotificationContentProps } from '../props';
import { DepositNotification } from '@/models/notification/notification';
import { TransactionExtraParams } from '@/models/notification/notificationExtraParams';

export default function DepositContent({
  notification,
  type,
}: NotificationContentProps<DepositNotification>) {
  const { t } = useTranslation('common');
  const extra: TransactionExtraParams = notification.extra;

  return (
    <BaseContent
      icon="fa-solid fa-piggy-bank"
      title={t('notification_deposit_title')}
      description={t('notification_deposit_description', {
        amount: WalletService.maskCurrency({
          value: extra.amount,
          coinId: extra.coinId,
        }),
      })}
      type={type}
      createdAt={notification?.createdAt ?? ''}
    />
  );
}
