import { GameID } from '@/enums/gameId';
import { GameSlug } from '@/enums/gameSlug';
import { GamesProps } from '@/types/games/props';

const baseURL = process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL;

const baseGameUrl: string = '/games/tag/originals';

export default new Map<GameID, GamesProps>([
  [
    GameID.Frost,
    {
      id: GameID.Frost,
      name: 'Frost',
      icon: 'fa-solid fa-teddy-bear',
      image: `${baseURL}/games/300x300/originals/fortune-double.webp`,
      path: `${baseGameUrl}/${GameSlug.Frost}`,
      description: 'fortune_double_description',
    },
  ],
  [
    GameID.Slide,
    {
      id: GameID.Slide,
      name: 'Slide',
      icon: 'fa-solid fa-rectangle-vertical-history',
      image: `${baseURL}/games/300x300/originals/slide.webp`,
      path: `${baseGameUrl}/${GameSlug.Slide}`,
      description: 'slide_description',
    },
  ],
  [
    GameID.Limbo,
    {
      id: GameID.Limbo,
      name: 'Limbo',
      icon: 'fa-solid fa-shuttle-space fa-rotate-270',
      image: `${baseURL}/games/300x300/originals/limbo.webp`,
      path: `${baseGameUrl}/${GameSlug.Limbo}`,
      description: 'limbo_description',
    },
  ],

  [
    GameID.Coinflip,
    {
      id: GameID.Coinflip,
      name: 'Coinflip',
      icon: 'fa-sharp fa-solid fa-coin-blank',
      image: `${baseURL}/games/300x300/originals/coinflip.webp`,
      path: `${baseGameUrl}/${GameSlug.Coinflip}`,
      description: 'coinflip_description',
    },
  ],
  [
    GameID.Tower,
    {
      id: GameID.Tower,
      name: 'Tower',
      icon: 'fa-solid fa-monkey',
      image: `${baseURL}/games/300x300/originals/tower.webp`,
      path: `${baseGameUrl}/${GameSlug.Tower}`,
      description: 'tower_description',
    },
  ],
  /* [
    GameID.Plinko,
    {
      id: GameID.Plinko,
      name: 'Plinko',
      icon: 'fa-solid fa-ball-pile',
      image: `${baseURL}/games/300x300/originals/plinko.webp`,
      path: '/games/plinko',
      description: 'plinko_description',
    },
  ], */
  [
    GameID.Mines,
    {
      id: GameID.Mines,
      name: 'Mines',
      icon: 'fa-solid fa-bomb',
      image: `${baseURL}/games/300x300/originals/mines.webp`,
      path: `${baseGameUrl}/${GameSlug.Mines}`,
      description: 'mines_description',
    },
  ],
  [
    GameID.Dice,
    {
      id: GameID.Dice,
      name: 'Dice',
      icon: 'fa-solid fa-dice',
      image: `${baseURL}/games/300x300/originals/dice.webp`,
      path: `${baseGameUrl}/${GameSlug.Dice}`,
      description: 'dice_description',
    },
  ],
  [
    GameID.Double,
    {
      id: GameID.Double,
      name: 'Double',
      icon: 'fa-solid fa-cards',
      image: `${baseURL}/games/300x300/originals/double.webp`,
      path: `${baseGameUrl}/${GameSlug.Double}`,
      description: 'double_description',
    },
  ],
  [
    GameID.Crash,
    {
      id: GameID.Crash,
      name: 'Crash',
      icon: 'fa-solid fa-rocket-launch',
      image: `${baseURL}/games/300x300/originals/crash.webp`,
      path: `${baseGameUrl}/${GameSlug.Crash}`,
      description: 'crash_description',
    },
  ],
  [
    GameID.CrashTrenball,
    {
      id: GameID.CrashTrenball,
      name: 'Trenball',
      icon: 'fa-solid fa-rocket-launch',
      image: `${baseURL}/games/300x300/originals/crash.webp`,
      path: `${baseGameUrl}/${GameSlug.Crash}`,
      description: 'crash_description',
      hidden: true,
    },
  ],
  [
    GameID.Splash,
    {
      id: GameID.Splash,
      name: 'Splash',
      icon: 'fa-solid fa-tomato',
      image: `${baseURL}/games/300x300/originals/splash.webp`,
      path: `${baseGameUrl}/${GameSlug.Splash}`,
      description: 'splash_description',
      hidden: true,
    },
  ],
]);
