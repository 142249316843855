export enum LocalStorageKey {
  GamesMuted = '@casino/games-muted',
  GameMode = '@casino/game-mode',
  HasSeenFollowBetTutorial = '@casino/has-seen-follow-bet-tutorial',
  SearchHistory = '@casino/search-history',
  ChartType = '@casino/chart/type',
  ChartInterval = '@casino/chart/interval',
  ChartActiveIndicators = '@casino/chart/indicators/active',
  ConversionCoinId = '@casino/conversion-coin-id ',
  ConvertToCoin = '@casino/convert-to-coin ',
  HiddenSmalls = '@casino/hidden-smalls',
  TowerGrid = '@casino/tower-grid',
}
