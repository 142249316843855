import { Bet } from '@/models/bet';
import { Round } from '@/models/round';
import { CrashGameType } from './enums/game-type.enum';
import { Nullable } from '@starsoft/common/interfaces';
import { GameID } from '@/enums/gameId';

export enum CrashActions {
  GetCashOut = 'crash/getCashOut',
  DisableCashOut = 'crash/disabledCashOut',
  Set5xCashOut = 'crash/set5xCashOut',
  Set10xCashOut = 'crash/set10xCashOut',
  UpdateRound = 'crash/updateRound',
  InitRound = 'crash/initRound',
  HandleCloseBetLoading = 'crash/handleCloseBetLoading',
  HandleCloseBetError = 'crash/handleCloseBetError',
  HandleCloseBetSuccess = 'crash/handleCloseBetSuccess',
  HandleResetCloseBet = 'crash/handleReset',
  SetSocketConnection = 'crash/socketConnection',
  SetLastWinnerBet = 'crash/setLastWinnerBets',
  SetGameType = 'crash/setGameType',
  SetTrendModal = 'crash/setTrendModal',
  ToggleTrendModal = 'crash/toggleTrendModal',
  Reset = 'crash/reset',
}

export interface CrashProps {
  cashOut: string;
  lines: number[];
  totalBet: string | undefined;
  loadingCloseBet: boolean;
  errorCloseBet: boolean;
  isSocketConnected: boolean;
  lastWinnerBets: Bet[];
  gameType: CrashGameType;
  isTrendOpen: boolean;
  round: Nullable<Round>;
}

export namespace Crash {
  export function gameIdToGameType(gameId: GameID) {
    switch (gameId) {
      case GameID.Crash:
        return CrashGameType.Classic;
      case GameID.CrashTrenball:
        return CrashGameType.Trenball;
    }
  }
}

export interface CrashBasePayload<T extends CrashActions, V = null> {
  type: T;
  payload: V;
}

export interface CrashGetCashOutPayload
  extends CrashBasePayload<CrashActions.GetCashOut, string> {}

export interface CrashUpdateRoundPayload
  extends CrashBasePayload<
    CrashActions.UpdateRound,
    {
      oldRound: Round | undefined;
      newRound: Round;
    }
  > {}

export interface CrashInitRoundPayload
  extends CrashBasePayload<CrashActions.InitRound, number> {}

export interface CrashSetSocketConnectionPayload
  extends CrashBasePayload<CrashActions.SetSocketConnection, boolean> {}

export interface CrashSetLastWinnerBetPayload
  extends CrashBasePayload<CrashActions.SetLastWinnerBet, Bet> {}

export interface CrashSetGameTypePayload
  extends CrashBasePayload<CrashActions.SetGameType, CrashGameType> {}

export interface CrashSetTrendModalPayload
  extends CrashBasePayload<CrashActions.SetTrendModal, boolean> {}

export type CrashStatePayload =
  | CrashBasePayload<CrashActions.Set5xCashOut>
  | CrashBasePayload<CrashActions.Set10xCashOut>
  | CrashBasePayload<CrashActions.DisableCashOut>
  | CrashBasePayload<CrashActions.HandleCloseBetLoading>
  | CrashBasePayload<CrashActions.HandleCloseBetError>
  | CrashBasePayload<CrashActions.HandleCloseBetSuccess>
  | CrashBasePayload<CrashActions.HandleResetCloseBet>
  | CrashBasePayload<CrashActions.ToggleTrendModal>
  | CrashBasePayload<CrashActions.Reset>
  | CrashUpdateRoundPayload
  | CrashGetCashOutPayload
  | CrashInitRoundPayload
  | CrashSetSocketConnectionPayload
  | CrashSetLastWinnerBetPayload
  | CrashSetGameTypePayload
  | CrashSetTrendModalPayload;
