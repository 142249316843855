import { useLottiePlayer } from '@package/hooks';
import styles from './styles.module.scss';
import useTranslation from 'next-translate/useTranslation';
import { Copy, ErrorChip } from '@package/components';
import { useUserAccount } from '@/api/user';
import { NotificationsService } from '@/services/NotificationsService';
import ProfileDrawerContentReferAndEarnSkeleton from './Skeleton';

export default function ProfileDrawerContentReferAndEarn() {
  const { t } = useTranslation('common');

  const {
    user,
    isLoading: loadingUserAccount,
    error: errorUserAccount,
    refetch: refetchUserAccount,
  } = useUserAccount();

  const { lottieAnimationRef } = useLottiePlayer({
    path: `${process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL}/lotties/bonus-reward.json`,
    dependecyArray: [loadingUserAccount],
  });

  function onCopy() {
    if (typeof navigator == 'undefined') {
      return;
    }

    NotificationsService.success({
      title: 'copy_base_title',
      message: 'copy_url',
    });
  }

  if (errorUserAccount) {
    return <ErrorChip action={refetchUserAccount} />;
  }

  if (loadingUserAccount) {
    return <ProfileDrawerContentReferAndEarnSkeleton />;
  }

  return (
    <div className={styles.card}>
      <div className={styles.card__holder} />
      <div className={styles.card__lottie} ref={lottieAnimationRef} />
      <div className={styles.card__column}>
        <div className={styles.card__column__label}>{t('refer_and_win')}</div>
        <Copy
          label={user?.voucher?.url}
          textToCopy={user?.voucher?.url}
          callback={onCopy}
        />
      </div>
    </div>
  );
}
