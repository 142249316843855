import { BetType } from '@/models/bet/type';
import {
  DatePickerActions,
  DatePickerStateProps,
  SetBetTypeStatePayload,
} from './props';

const DEFAULT_END_DATE: Date = new Date();
const DEFAULT_START_DATE: Date = new Date();
DEFAULT_START_DATE.setDate(DEFAULT_END_DATE.getDate() - 90);

const initialState: DatePickerStateProps = {
  startDate: DEFAULT_START_DATE,
  endDate: DEFAULT_END_DATE,
  betType: BetType.Casino,
};

export default function datePickerReducer(
  state: DatePickerStateProps = initialState,
  action: SetBetTypeStatePayload,
): DatePickerStateProps {
  switch (action.type) {
    case DatePickerActions.SetDateFilter:
      return {
        ...state,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
      };
    case DatePickerActions.ResetDatePicker:
      return {
        ...state,
        startDate: DEFAULT_START_DATE,
        endDate: DEFAULT_END_DATE,
      };
    case DatePickerActions.SetBetType:
      return {
        ...state,
        betType: action.payload,
      };
    default:
      return state;
  }
}
