import SidebarContentCollapsedPage from '../Page';
import { useLogout } from '@/api/iam';
import { useUserAccount } from '@/api/user/queries';
import { usePathname } from '@/hooks/usePathname';

export default function LogoutPage() {
  const { isAuthorized } = useUserAccount();
  const pathname: string = usePathname();
  const { mutate } = useLogout();

  function handleLogOut() {
    mutate();
  }

  if (!isAuthorized) {
    return null;
  }

  return (
    <SidebarContentCollapsedPage
      page={{
        label: 'logout_button',
        icon: 'fa-solid fa-right-from-bracket',
        paths: [pathname],
        clickHandler: handleLogOut,
      }}
      shouldNotHighlight
    />
  );
}
