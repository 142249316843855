import { Column, Container, ProgressContainer } from '../styles';
import styles from './styles.module.scss';

export function UserRankCardSkeleton() {
  return (
    <Container>
      <div className={styles['show-desktop']}>
        <div className={styles.photo} />
      </div>

      <div className={styles['show-mobile']}>
        <div className={styles['photo--mobile']} />
      </div>

      <Column>
        <div className={styles.username} />

        <ProgressContainer>
          <div className={styles.progress} />
        </ProgressContainer>
      </Column>

      <div className={styles['show-desktop']}>
        <div className={styles.chevron} />
      </div>

      <div className={styles['show-mobile']}>
        <div className={styles['chevron--mobile']} />
      </div>
    </Container>
  );
}
