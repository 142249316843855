import { GenericError } from '@/models/generic-error';
import { Bet } from '@/models/bet';
import { Round } from '@/models/round';
import { PlinkoFetchMultipliersResponseDto } from '@/services/dto/plinko/plinko-fetch-multipliers.response.dto';

export enum PlinkoActions {
  EnterBet = 'plinko/enterBet',
  EnterBetSuccess = 'plinko/enterBetSuccess',
  EnterBetError = 'plinko/enterBetError',
  GetAmount = 'plinko/getAmount',
  CloseBet = 'plinko/closeBet',
  CloseBetSuccess = 'plinko/closeBetSuccess',
  CloseBetError = 'plinko/closeBetError',
  DismissLoading = 'plinko/dismissLoading',
  VerifyAmount = 'plinko/verifyAmount',
  SetHalfAmount = 'plinko/setHalfAmount',
  SetDoubleAmount = 'plinko/setDoubleAmount',
  SetRowsQuantity = 'plinko/setRowsQuantity',
  SetRisk = 'plinko/setRisk',
  SetBallInGame = 'plinko/setBallsInGame',
  SetBallFinished = 'plinko/setBallFinished',
  Reset = 'plinko/reset',
  FetchMultipliers = 'plinko/fetchMultipliers',
  FetchMultipliersSuccess = 'plinko/fetchMultipliersSuccess',
  FetchMultipliersError = 'plinko/fetchMultipliersError',
}

export type Risk = keyof PlinkoFetchMultipliersResponseDto;

export interface UpdatedUserInfo {
  round: Round;
}
export interface Ball {
  id: string;
  amount: number;
  destinationMultiplier: number;
  slot: number;
  updatedUserInfo: UpdatedUserInfo | null;
}
export interface PlinkoProps {
  loading: boolean;
  betLoading: boolean;
  error: GenericError | false;
  bet: Bet[] | [];
  amount: number;
  round: Round | null;
  rows: number;
  multipliers: PlinkoFetchMultipliersResponseDto | null;
  currentMultipliers: number[] | null;
  ballsInGame: Ball[];
  risk: Risk;
  updatedUserInfo: UpdatedUserInfo | null;
}

export interface PlinkoBasePayload {
  type: string;
}

export type PlinkoHalfAmountPayload = PlinkoBasePayload;
export type PlinkoDoubleAmountPayload = PlinkoBasePayload;
export type PlinkoResetPayload = PlinkoBasePayload;
export type PlinkoDismissLoadingPayload = PlinkoBasePayload;

export interface PlinkoBetAmountPayload extends PlinkoBasePayload {
  payload: {
    amount: number;
  };
}

export interface PlinkoBetVerifyAmountPayload extends PlinkoBasePayload {
  payload: {
    amount: string;
  };
}

export interface PlinkoRowsChangePayload extends PlinkoBasePayload {
  payload: {
    rows: number;
  };
}

export interface PlinkoEnterBetPayload extends PlinkoBasePayload {
  payload: {
    amount: number;
    rows: number;
    risk: string;
    coinId: number;
  };
}

export interface PlinkoEnterBetFailPayload extends PlinkoBasePayload {
  payload: GenericError;
}

export interface PlinkoEnterBetSuccessPayload extends PlinkoBasePayload {
  payload: {
    bet: Bet;
    round: Round;
    slot: number;
  };
}

export type PlinkoFetchMultipliersPayload = PlinkoBasePayload;

export interface PlinkoFetchMultipliersSuccessPayload
  extends PlinkoBasePayload {
  payload: {
    multipliers: PlinkoFetchMultipliersResponseDto;
  };
}

export interface PlinkoFetchMultipliersErrorPayload extends PlinkoBasePayload {
  payload: GenericError;
}

export interface PlinkoRiskChangePayload extends PlinkoBasePayload {
  payload: keyof PlinkoFetchMultipliersResponseDto;
}

export interface PlinkoSetBallInGamePayload extends PlinkoBasePayload {
  payload: Ball;
}

export interface PlinkoSetBallFinishedPayload extends PlinkoBasePayload {
  payload: {
    id: string;
  };
}

export type PlinkoStatePayload =
  | PlinkoActions
  | PlinkoProps
  | PlinkoHalfAmountPayload
  | PlinkoRowsChangePayload
  | PlinkoDismissLoadingPayload
  | PlinkoEnterBetFailPayload
  | PlinkoEnterBetSuccessPayload
  | PlinkoDoubleAmountPayload
  | PlinkoBetAmountPayload
  | PlinkoFetchMultipliersPayload
  | PlinkoFetchMultipliersSuccessPayload
  | PlinkoFetchMultipliersErrorPayload
  | PlinkoRiskChangePayload;
