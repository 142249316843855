import SidebarNotCollapsedItemSection from '../Item/Section';
import useTranslation from 'next-translate/useTranslation';
import { useLogout } from '@/api/iam';
import { useUserAccount } from '@/api/user/queries';

export default function LogoutSection() {
  const { mutate } = useLogout();
  const { t } = useTranslation('common');
  const { isAuthorized } = useUserAccount();

  function handleLogOut() {
    mutate();
  }

  if (!isAuthorized) {
    return null;
  }

  return (
    <SidebarNotCollapsedItemSection
      label={t('logout_button')}
      icon="fa-solid fa-right-from-bracket"
      onClick={handleLogOut}
    />
  );
}
