import SideBar from '../../SideBar';
import Header from '../../Header';
import { PropsWithChildren, useMemo } from 'react';
import styles from './styles.module.scss';
import publicRoutes from '@/constants/publicRoutes';
import dynamic from 'next/dynamic';
import { usePathname } from '@/hooks/usePathname';
import { useUserAccount } from '@/api/user/queries';
import useAccountsSocket from '@/hooks/sockets/useAccountsSocket';
import useNotificationsSocket from '@/hooks/sockets/useNotificationsSocket';
import useCoinsSocket from '@/hooks/sockets/useCoinsSocket';
import useSupportNotificationSocket from '@/hooks/sockets/support/useSupportNotificationSocket';
import { useDetectDevice } from '@/hooks/useDetectDevice';
import { Setting } from '@package/models';
import { useUserTagManagerEvents } from '@/api/user-tag-manager-events';
import MainLayoutModals from './Modals';
import { useSetLanguageCookie } from '@/hooks/useSetLanguageCookie';
import useUnregisterAllServiceWorkers from '@/hooks/useUnregisterSW';

const LayoutMobileNavigation = dynamic(() => import('./Navigation'));
const MobileNavigation = dynamic(() => import('../../MobileNavigation'));

function MainLayout({
  children,
  setting,
}: PropsWithChildren<{ setting: Setting }>) {
  useSetLanguageCookie();
  useUnregisterAllServiceWorkers();
  useDetectDevice();
  useUserAccount({
    refetchOnWindowFocus: 'always',
  });
  useAccountsSocket();
  useNotificationsSocket();
  useSupportNotificationSocket();
  useCoinsSocket();
  useUserTagManagerEvents();

  const pathname: string = usePathname();
  const shouldRenderSidebar = useMemo(
    () => !publicRoutes.some(route => pathname?.includes(route)),
    [pathname],
  );

  return (
    <>
      <MainLayoutModals setting={setting} />
      {shouldRenderSidebar && (
        <>
          <Header />
          <div className={styles['mobile-only']}>
            <MobileNavigation />
            <LayoutMobileNavigation />
          </div>
          <div className={styles['desktop-only']}>
            <SideBar />
          </div>
        </>
      )}
      {children}
    </>
  );
}

export default MainLayout;
