import { AxiosResponse } from 'axios';
import { call, put, all, takeLatest } from 'redux-saga/effects';
import { PlinkoActions, PlinkoEnterBetPayload } from './props';
import {
  enterBetFailPayload,
  enterBetSuccessPayload,
  fetchMultipliersError,
  fetchMultipliersSuccess,
  setBallInGame,
} from './actions';
import { PlinkoService } from '@/services/PlinkoService';
import { PlinkoCreateBetResponseDto } from '@/services/dto/plinko/plinko-create-bet-response.dto';
import { PlinkoFetchMultipliersResponseDto } from '@/services/dto/plinko/plinko-fetch-multipliers.response.dto';
import { RankQueryQueys } from '@/api/rank-status/queryKeys';
import { ErrorService } from '@/services/ErrorService';
import { UserRewardsQueryKeys } from '@/api/user-rewards/queryKeys';
import { getStore } from '..';
import { queryClient } from '@/lib/providers/TanstackQueryProvider';

function* fetchEnterBet({
  payload: { amount, rows, risk, coinId },
}: PlinkoEnterBetPayload) {
  try {
    const response: AxiosResponse<PlinkoCreateBetResponseDto> = yield call(
      PlinkoService.create,
      {
        amount: +amount,
        rows,
        risk,
        coinId,
      },
    );

    const { bet, slot, round, multiplier } = response.data;
    const store = getStore();

    store.dispatch(
      setBallInGame({
        id: `ball-${bet.id}`,
        slot: slot,
        amount: +amount,
        destinationMultiplier: Number(multiplier),
        updatedUserInfo: {
          round: round,
        },
      }),
    );

    /* NotificationsService.addBetNotification({ bet, gameId: GameID.PLINKO }); */
    queryClient.invalidateQueries({ queryKey: [RankQueryQueys.ByUser] });
    queryClient.invalidateQueries({ queryKey: [UserRewardsQueryKeys.Primary] });

    yield put(
      enterBetSuccessPayload({
        bet,
        slot,
        round,
      }),
    );
  } catch (error: any) {
    ErrorService.onError(error);
    yield put(
      enterBetFailPayload({
        statusCode: error?.response?.data?.statusCode ?? 500,
        message: error?.response?.data?.message ?? '',
      }),
    );
  }
}

function* fetchMultipliers() {
  try {
    const response: AxiosResponse<PlinkoFetchMultipliersResponseDto> =
      yield call(PlinkoService.fetchMultipliers);

    yield put(fetchMultipliersSuccess(response.data));
  } catch (error: any) {
    ErrorService.onError(error);
    yield put(
      fetchMultipliersError({
        statusCode: error?.response?.data?.statusCode ?? 500,
        message: error?.response?.data?.message ?? '',
      }),
    );
  }
}

export default function* plinkoSagas() {
  yield all([
    takeLatest(PlinkoActions.EnterBet, fetchEnterBet),
    takeLatest(PlinkoActions.FetchMultipliers, fetchMultipliers),
  ]);
}
