import { GridDifficulty } from '@/models/bet/grid/difficulty';

export function getColumnQuantity(difficulty: GridDifficulty) {
  switch (difficulty) {
    case GridDifficulty.Easy:
      return 4;
    case GridDifficulty.Medium:
      return 3;
    case GridDifficulty.Hard:
      return 2;
    case GridDifficulty.Expert:
      return 3;
    case GridDifficulty.Master:
      return 4;
    default:
      return 4;
  }
}
