import { RouletteColor } from '@/models/roulette/color';

export function getRouletteMultiplier(color: RouletteColor) {
  switch (color) {
    case RouletteColor.White:
      return 14;
    case RouletteColor.Black:
    case RouletteColor.Red:
      return 2;
  }
}
