import { ThemeType } from '@/enums/themeType';
import { ThemeStateProps, ThemeActions, SetThemePayload } from './props';

let initialState: ThemeStateProps = {
  type: ThemeType.dark,
  setting: null,
};

export default function reducer(
  state: ThemeStateProps = initialState,
  action: any,
): ThemeStateProps {
  switch (action.type) {
    case ThemeActions.Set: {
      const type: ThemeType = (action as SetThemePayload).payload;

      return { ...state, ...{ type: type } };
    }
    case ThemeActions.Settings:
      initialState = {
        ...state,
        setting: action.payload,
      };
      return {
        ...state,
        setting: action.payload,
      };
    default:
      return state;
  }
}
