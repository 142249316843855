import { useEffect, useState } from 'react';
import BalanceModal from '../BalanceModal';
import { useCountUp } from 'use-count-up';
import { useWalletsMe } from '@/api/wallets';
import BalanceButtonSkeleton from './Skeleton';
import WalletService from '@/services/WalletService';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/lib/store';
import { collapseWallet } from '@/lib/store/wallet/actions';
import useTranslation from 'next-translate/useTranslation';
import { useUserAccount } from '@/api/user/queries';
import { useModalNavigation } from '@/hooks/modals/useModalNavigation';
import { ModalsKey } from '@/enums/modalsKey';
import { CoinID } from '@/enums/coinId';
import { coinFormattingMapping } from '@/constants/coinFormattingMapping';
import { Button, ErrorChip, Image, RichTooltip } from '@package/components';
import { useConversionCoin } from '@/hooks/useConversionCoin';
import { AxiosError } from 'axios';
import { GenericError } from '@package/models';
import { queryClient } from '@/lib/providers/TanstackQueryProvider';
import { UserTagManagerEventsQueryKeys } from '@/api/user-tag-manager-events/queryKeys';
import { Nullable } from '@package/interfaces';
import styles from './styles.module.scss';
import { useRouter } from 'next/router';
import { GameTagFilter } from '@/models/games/game-tag-filter.enum';
import useTransactionsNavigation from '@/hooks/useTransacionsNavigation';

export default function BalanceButton() {
  const { t } = useTranslation('common');
  const { user } = useUserAccount();
  const dispatch = useDispatch();
  const { navigateToModal } = useModalNavigation();
  const open: boolean = useSelector(
    (state: RootState) => state.wallet.collapsed,
  );
  const { query } = useRouter();
  const tag: GameTagFilter | undefined = query.tag as GameTagFilter;
  const gameSlug: string | undefined = query.gameSlug as string;
  const isSlotPage: boolean = tag !== GameTagFilter.Originals && !!gameSlug;

  const { navigateToTransactionModal } = useTransactionsNavigation();

  const {
    wallet,
    error: errorWallet,
    refetch: refetchWallet,
    isLoading: isLoadingWallet,
  } = useWalletsMe({
    refetchOnWindowFocus: 'always',
  });
  const {
    coin,
    error: errorFiatConversionCoin,
    refetch: refetchFiatConversionCoin,
    isPending: isLoadingFiatConversionCoin,
  } = useConversionCoin();
  const isLoading: boolean = isLoadingWallet || isLoadingFiatConversionCoin;
  const error: Nullable<AxiosError<GenericError>> =
    errorWallet ?? errorFiatConversionCoin;

  const balance: number = coin
    ? WalletService.convertValueToCoin({
        value: wallet?.currentAccount?.balance ?? 0,
        valueCoinPrice: wallet?.currentAccount?.coin?.price as number,
        conversionCoinPrice: coin.price,
      })
    : (wallet?.currentAccount?.balance ?? 0);
  const [previousBalance, setPreviousBalance] = useState<number>(balance);
  const coinId: number = coin
    ? coin?.id
    : (wallet?.currentAccount?.coin?.id ?? CoinID.USD);

  const { value, reset } = useCountUp({
    isCounting: true,
    end: balance,
    start: previousBalance,
    duration: 1,
    formatter: value => {
      return WalletService.maskCurrency({
        value,
        coinId,
        hideSymbol: !coin,
      });
    },
    onComplete: () => {
      setPreviousBalance(balance);
      if (balance > previousBalance) {
        queryClient.invalidateQueries({
          queryKey: [UserTagManagerEventsQueryKeys.Primary],
        });
      }
    },
  });

  const decimal: string = coinFormattingMapping?.get(coinId)?.decimal ?? '';
  const formattedValue = String(value)?.split(decimal);

  useEffect(() => {
    reset();
  }, [wallet, reset]);

  function handleToggleModal() {
    dispatch(collapseWallet());
  }

  function handleRefetch() {
    if (errorWallet) {
      refetchWallet();
    }

    if (errorFiatConversionCoin) {
      refetchFiatConversionCoin();
    }
  }

  function handleDeposit() {
    if (!user) {
      navigateToModal(ModalsKey.Login);
      return;
    }
    navigateToTransactionModal(ModalsKey.Deposit);
  }

  if (isLoading) {
    return <BalanceButtonSkeleton />;
  }

  if (error) {
    return (
      <div className={styles.container}>
        <ErrorChip action={handleRefetch} />
      </div>
    );
  }

  return (
    <RichTooltip
      open={isSlotPage ? false : open}
      onClose={handleToggleModal}
      className={styles.container__tooltip}
      content={<BalanceModal />}
    >
      <div className={styles.container}>
        <button
          className={styles.container__balance}
          onClick={handleToggleModal}
        >
          <div
            className={`${styles.container__row} ${styles['container__row--start']}`}
          >
            <Image
              className={styles.container__balance__coin}
              alt={`coin-icon-${wallet?.currentAccount?.coin?.name}`}
              format="square"
              src={`${process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL}/coins/rounded/${wallet?.currentAccount?.coin?.image}`}
              secondarySkeleton
            />
            <div
              className={`${styles.container__row} ${styles['container__row--small']}`}
            >
              <span className={styles.container__balance__symbol}>
                {wallet?.currentAccount?.coin?.symbol}
              </span>
              {!isSlotPage && (
                <i
                  className={`fas fa-chevron-down ${styles.container__balance__chevron} ${open ? styles['container__balance__chevron--open'] : ''}`}
                />
              )}
            </div>
          </div>

          <div className={styles.container__balance__value}>
            {isSlotPage ? (
              `(${t('in_game')})`
            ) : (
              <>
                {formattedValue?.[0]}
                {formattedValue?.[1] && (
                  <span className={styles.container__balance__value__decimal}>
                    .{formattedValue?.[1]}
                  </span>
                )}
              </>
            )}
          </div>
        </button>
        <Button className={styles.container__deposit} onClick={handleDeposit}>
          <i className="fa-duotone fa-wallet desktop-only" />
          <i className="fa-solid fa-plus mobile-only" />
          <span>{t('tab_deposit')}</span>
        </Button>
      </div>
    </RichTooltip>
  );
}
