export enum GiphyActions {
  SetSearch = 'giphy/set-search',
}

export interface GiphyProps {
  search: string;
}

export interface GiphyBasePayload<T extends GiphyActions, V = null> {
  type: T;
  payload: V;
}

export type GiphySetSearchPayload = GiphyBasePayload<
  GiphyActions.SetSearch,
  string
>;

export type GiphyStatePayload = GiphySetSearchPayload;
