import { useFormContext } from 'react-hook-form';
import AuthModalContentEmailInput from '@/components/AuthModal/Content/Common/EmailInput';
import { LoginFormProps } from '@/types/login/props';
import useAuthForm from '@/hooks/auth/useAuthForm';

export default function LoginEmailContent() {
  const { watch } = useFormContext<LoginFormProps>();
  const { isValidEmail } = useAuthForm({ watch });

  return <AuthModalContentEmailInput isValidEmail={isValidEmail} />;
}
