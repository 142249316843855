export enum UsersNetworkTypeActions {
  ChangeCurrentLevel = 'usersNetwork/changeCurrentLevel',
}

export interface UsersNetworkTypeStateProps {
  depth: number;
}

export interface UsersNetworkTypeRequestBase {
  type: string;
}

export interface UsersNetworkCurrentLevelPayload
  extends UsersNetworkTypeRequestBase {
  payload: {
    depth: number;
  };
}

export type UsersNetworkStatePayload =
  | UsersNetworkTypeRequestBase
  | UsersNetworkCurrentLevelPayload;
