export enum GameSlug {
  Crash = 'crash-originals',
  Double = 'double-originals',
  Mines = 'mines-originals',
  Plinko = 'plinko-originals',
  Dice = 'dice-originals',
  Tower = 'tower-originals',
  Coinflip = 'coinflip-originals',
  Limbo = 'limbo-originals',
  Slide = 'slide-originals',
  Crash_Trenball = 'crash-trenball-originals',
  Splash = 'splash-originals',
  Frost = 'frost-originals',
}
