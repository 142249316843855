import Color from 'color';
import styled from 'styled-components';
import { CollapseIconProps, ProgressProps } from './props';
import { Image } from '@starsoft/common/components';
import { getComputedColor } from '@/utils/theme';

export const Container = styled.div`
  width: 100%;
  max-width: 200px;
  height: 40px;
  background: ${({ theme }: any) => theme.vars.palette.card.main};
  border-radius: ${({ theme }) => theme.vars.shape.borderRadius};
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  cursor: pointer;
  transition: background-color 0.25s ease-in-out;

  &:hover {
    background-color: ${({ theme }) =>
      Color(getComputedColor(theme.vars.palette.background.paper))
        .lighten(0.4)
        .toString()};
  }

  @media (max-width: 1225px) {
    all: unset;
    width: 40px;
    height: 40px;
    position: relative;
  }
`;

export const Rank = styled(Image)`
  width: 20px;
  height: 20px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;

  @media (max-width: 1225px) {
    display: none;
  }
`;

export const Username = styled.div`
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  max-width: 104px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media (max-width: 1225px) {
    width: ${({ theme }) => theme.vars.shape.boxHeight};
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const Icon = styled.i<CollapseIconProps>`
  color: ${({ theme }) => theme.vars.palette.text.secondary};
  transform: rotate(${({ open }) => (open ? '180deg' : '0deg')});
  transition: ease 0.3s;

  @media (max-width: 1225px) {
    position: absolute;
    background-color: ${({ theme }) => theme.vars.palette.background.paper};
    bottom: -6px;
    right: -6px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    font-size: 10px;
    color: ${({ theme }) => theme.vars.palette.text.primary};
    border: 1px solid
      ${({ theme }) =>
        Color(getComputedColor(theme.vars.palette.text.secondary))
          .alpha(0.2)
          .toString()};
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
`;

export const ProgressContainer = styled.div`
  height: 6px;
  border-radius: ${({ theme }) => theme.vars.shape.borderRadius};
  position: relative;
  background: ${({ theme }) => theme.vars.palette.background.default};
  width: 100%;
`;

export const Progress = styled.div<ProgressProps>`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  border-radius: ${({ theme }) => theme.vars.shape.borderRadius};
  background-color: ${({ theme, rankColor }) =>
    rankColor == null ? theme.vars.palette.text.secondary : rankColor};
  width: ${({ progress }) => progress}%;
  max-width: 100%;
  transition: width 0.3s;
`;
