import { ModalsKey } from '@/enums/modalsKey';
import SidebarContentCollapsedPage from '../Page';

export default function SupportPage() {
  return (
    <SidebarContentCollapsedPage
      page={{
        label: 'tab_support',
        icon: 'fa-solid fa-headset ',
        paths: [],
        isProtected: true,
        modal: ModalsKey.SupportModal,
      }}
      isHightligh
    />
  );
}
