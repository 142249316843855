import { RootState } from '@/lib/store';
import { useSelector } from 'react-redux';
import CollapseButton from './CollapseButton';
import { platformAssets } from '@/utils/assets';
import { logoSideBarVariants, logoSideBarVariantsMobile } from './variants';
import { useMediaQuery } from '@mui/material';
import Link from '@/components/core/Link';
import styles from './styles.module.scss';
import { m } from 'framer-motion';

export default function SidebarHeader() {
  const collapsedSidebar: boolean = useSelector(
    (state: RootState) => state.sideBar.collapsed,
  );
  const mobileSize = useMediaQuery('(max-width: 768px)');

  return (
    <div
      className={`${styles.container} ${collapsedSidebar ? styles['container--collapsed'] : ''}`}
    >
      <CollapseButton />
      <Link
        href="/"
        className={`${styles.container__link} ${collapsedSidebar ? styles['container__link--collapsed'] : ''}`}
      >
        <m.img
          className={`${styles['container__mobile-logo']} ${collapsedSidebar ? styles['container__mobile-logo--collapsed'] : ''}`}
          initial="open"
          animate={(mobileSize ? false : !collapsedSidebar) ? 'open' : 'closed'}
          variants={logoSideBarVariantsMobile}
          src={platformAssets.logo.icon}
          alt="logo"
        />
        <m.img
          className={`${styles['container__logo']} ${collapsedSidebar ? styles['container__logo--collapsed'] : ''}`}
          initial="open"
          alt="web-logo"
          animate={(mobileSize ? false : !collapsedSidebar) ? 'open' : 'closed'}
          variants={logoSideBarVariants}
          src={platformAssets.logo.web.dark}
        />
      </Link>
    </div>
  );
}
