import { ModalHeader } from '@package/components';
import styles from './styles.module.scss';
import { useModal } from '@package/hooks';
import { ModalsKey } from '@/enums/modalsKey';
import useTranslation from 'next-translate/useTranslation';
import { ProfileDrawerContentHeaderCloseButtonProps } from './props';

export default function ProfileDrawerContentHeaderCloseButton({
  hasScrolled,
}: ProfileDrawerContentHeaderCloseButtonProps) {
  const { onClose } = useModal(ModalsKey.ProfileDetails);
  const { t } = useTranslation('common');

  return (
    <div className={styles.container}>
      <div className={styles.container__item}>
        <ModalHeader
          onClose={onClose}
          handleGoBack={onClose}
          title={t('tab_profile')}
          withBackButton
          hideCloseButton
          className={`${styles.container__item__header} ${hasScrolled ? styles['container__item__header--scrolled'] : ''}`}
        />
      </div>
    </div>
  );
}
