import { RootState } from '@/lib/store';
import { setSettings } from '@/lib/store/theme/actions';
import { Setting } from '@package/models';
import { ComponentTypeEnum } from '@package/src/models/setting/layout/component/type';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export function useResolvedTheme(setting?: Setting) {
  const dispatch = useDispatch();
  const stateSetting = useSelector((state: RootState) => state.theme.setting);

  const components = useMemo(() => {
    const settings = setting || stateSetting;
    return new Map<ComponentTypeEnum, number>(
      settings?.components?.map(component => [
        component?.componentType?.name,
        component?.component?.id as number,
      ]),
    );
  }, [stateSetting, setting]);

  const handleInitStateSetting = useCallback(() => {
    if (!setting) {
      return;
    }

    dispatch(setSettings(setting));
  }, [setting, dispatch]);

  useEffect(handleInitStateSetting, [handleInitStateSetting]);

  return {
    setting: setting ?? stateSetting,
    components,
  };
}
