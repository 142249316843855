import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import { useDispatch } from 'react-redux';
import styles from './styles.module.scss';
import CloseButton from '@starsoft/common/src/components/Modal/Header/CloseButton';
import { setNotificationModalOpenState } from '@/lib/store/notifications/actions';

export default function NotificationModalHeader() {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();

  function handleTransactionModal() {
    dispatch(setNotificationModalOpenState(false));
  }

  return (
    <div className={styles.container}>
      <h6 className={styles.container__title}>{t('tab_notification')}</h6>

      <CloseButton onClick={handleTransactionModal} />
    </div>
  );
}
