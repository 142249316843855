export enum AffiliatesActions {
  SetSearch = 'affiliates/setSearch',
}

export interface AffiliatesStateProps {
  search: string;
}

export interface AffiliatesBasePayload<T extends AffiliatesActions, V = null> {
  type: T;
  payload: V;
}

export type SetSearchPayload = AffiliatesBasePayload<
  AffiliatesActions.SetSearch,
  string
>;

export type AffiliatesStatePayload = SetSearchPayload;
