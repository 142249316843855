import { BaseContent } from '..';
import useTranslation from 'next-translate/useTranslation';
import { TournamentNotification } from '@/models/notification/notification';
import { NotificationContentProps } from '../props';
import { TournamentExtraParams } from '@/models/notification/notificationExtraParams';
import { WalletService } from '@package/services';

export default function TournamentContent({
  notification,
  type,
}: NotificationContentProps<TournamentNotification>) {
  const { t } = useTranslation('notify');
  const extra: TournamentExtraParams = notification.extra;

  return (
    <BaseContent
      icon="fa-solid fa-trophy-star"
      title={t('tournament_title', {
        name: extra.tournament.name,
      })}
      description={t('tournament_description', {
        place: extra.place,
        prize: WalletService.maskCurrency({
          coinId: extra.coin.id,
          value: extra.prize,
        }),
      })}
      type={type}
      createdAt={notification?.createdAt ?? ''}
    />
  );
}
