import useTranslation from 'next-translate/useTranslation';
import { useLottiePlayer } from '@starsoft/common/hooks';

import styles from './styles.module.scss';
import Link from '@/components/core/Link';

export default function BonusButton() {
  const { lottieAnimationRef } = useLottiePlayer({
    path: `${process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL}/lotties/gift-box-deposit.json`,
  });
  const { t } = useTranslation('common');

  return (
    <Link href="/bonus" className={styles.link} privateRoute>
      <div className={styles.link__lottie__placeholder} />
      <div className={styles.link__lottie} ref={lottieAnimationRef} />
      <div className={styles.link__wrapper}>
        <div className={styles.link__wrapper__title}>{t('rewards_title')}</div>
        <div className={styles.link__wrapper__description}>
          {t('bonus_subtitle_label')}
        </div>
      </div>
    </Link>
  );
}
