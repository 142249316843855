import AuthModalContentRegisterSteps from './Content/Steps';
import RegisterLayout from './Layout';

export default function AuthModalRegisterContent() {
  return (
    <RegisterLayout>
      <AuthModalContentRegisterSteps />
    </RegisterLayout>
  );
}
