import useTranslation from 'next-translate/useTranslation';
import SidebarNotCollapsedItemSection from '../Item/Section';
import { useUserAccount } from '@/api/user/queries';
import { useModalNavigation } from '@/hooks/modals/useModalNavigation';
import { ModalsKey } from '@/enums/modalsKey';

export default function SupportSection() {
  const { t } = useTranslation('common');
  const { isAuthorized } = useUserAccount();
  const { navigateToModal } = useModalNavigation();

  function handleNavigate() {
    if (!isAuthorized) {
      navigateToModal(ModalsKey.Login);
      return;
    }

    navigateToModal(ModalsKey.SupportModal);
  }

  return (
    <SidebarNotCollapsedItemSection
      label={t('tab_support')}
      icon="fa-solid fa-headset"
      isHighligh
      onClick={handleNavigate}
    />
  );
}
