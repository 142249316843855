import { QueryFunctionContext } from '@tanstack/react-query';
import { UseEmailProvidersQueryKeyProps } from './props';
import baseAPI from '@/api';
import { PaginatedResponse } from '@/models/pagination/paginated-response.dto';
import { EmailProviders } from '@/models/email-providers';

export async function fetchEmailProviders({
  queryKey,
  pageParam,
}: QueryFunctionContext<UseEmailProvidersQueryKeyProps>) {
  const { limit } = queryKey[1];

  const response = await baseAPI.get<PaginatedResponse<EmailProviders>>(
    '/email-providers',
    {
      params: {
        page: pageParam,
        limit,
      },
    },
  );

  return response.data;
}
