import { NotificationGroupId } from '@/enums/notificationGroupId';
import {
  AddNotificationPayload,
  NotificationsActions,
  RemoveNotificationPayload,
  SetOpenPayload,
  SetSocketConnectionPayload,
  SetTabPayload,
} from './props';
import { Notification } from '@/models/notification/notification';

export function setNotificationModalOpenState(open: boolean): SetOpenPayload {
  return {
    type: NotificationsActions.SetOpen,
    payload: open,
  };
}

export function setNotificationModalTab(
  tab: NotificationGroupId,
): SetTabPayload {
  return {
    type: NotificationsActions.SetTab,
    payload: tab,
  };
}

export function addNotificationToasty(
  notification: Notification,
): AddNotificationPayload {
  return {
    type: NotificationsActions.AddNotification,
    payload: notification,
  };
}

export function removeNotificationToasty(
  id: number,
): RemoveNotificationPayload {
  return {
    type: NotificationsActions.RemoveNotification,
    payload: id,
  };
}

export function setNotificationsSocketConnection(
  connected: boolean,
): SetSocketConnectionPayload {
  return {
    type: NotificationsActions.SetSocketConnection,
    payload: connected,
  };
}
