import { Rows } from '@/services/dto/plinko/plinko-fetch-multipliers.response.dto';
import { Bet } from '@/models/bet';
import {
  PlinkoActions,
  PlinkoProps,
  PlinkoBetAmountPayload,
  PlinkoBetVerifyAmountPayload,
  PlinkoEnterBetFailPayload,
  PlinkoEnterBetPayload,
  PlinkoEnterBetSuccessPayload,
  PlinkoRowsChangePayload,
  PlinkoFetchMultipliersSuccessPayload,
  PlinkoRiskChangePayload,
  PlinkoSetBallInGamePayload,
  PlinkoSetBallFinishedPayload,
} from './props';

const initialState: PlinkoProps = {
  ballsInGame: [],
  loading: true,
  betLoading: false,
  error: false,
  bet: [],
  amount: 0,
  round: null,
  rows: 8,
  multipliers: null,
  currentMultipliers: null,
  risk: 'low',
  updatedUserInfo: null,
};

export default function reducer(
  state: PlinkoProps = initialState,
  action: any,
): PlinkoProps {
  switch (action.type) {
    case PlinkoActions.GetAmount:
      return {
        ...state,
        loading: false,
        amount: (action as PlinkoBetAmountPayload).payload.amount,
      };
    case PlinkoActions.VerifyAmount: {
      if (Number((action as PlinkoBetVerifyAmountPayload).payload.amount) < 0) {
        return {
          ...state,
          amount: 10,
        };
      }
      return state;
    }
    case PlinkoActions.SetHalfAmount:
      return {
        ...state,
        loading: false,
        amount: Number(state.amount) / 2,
      };
    case PlinkoActions.SetDoubleAmount:
      return {
        ...state,
        loading: false,
        amount: Number(state.amount) * 2,
      };
    case PlinkoActions.SetRowsQuantity:
      const risk = state.risk;
      const rows = (action as PlinkoRowsChangePayload).payload
        .rows as keyof Rows;
      const currentMultipliers = state.multipliers?.[risk][rows] ?? null;

      return {
        ...state,
        rows: (action as PlinkoRowsChangePayload).payload.rows,
        currentMultipliers,
      };
    case PlinkoActions.EnterBet:
      const payload = (action as PlinkoEnterBetPayload).payload;
      return {
        ...state,
        betLoading: true,
        amount: payload.amount,
      };
    case PlinkoActions.EnterBetSuccess:
      return {
        ...state,
        betLoading: false,
        bet: (state.bet as Bet[]).concat(
          (action as PlinkoEnterBetSuccessPayload).payload.bet,
        ),
        round: (action as PlinkoEnterBetSuccessPayload).payload.round,
      };
    case PlinkoActions.EnterBetError:
      return {
        ...state,
        betLoading: false,
        error: (action as PlinkoEnterBetFailPayload).payload,
      };
    case PlinkoActions.DismissLoading:
      return {
        ...state,
        loading: false,
        betLoading: false,
      };
    case PlinkoActions.CloseBet:
      return {
        ...state,
        betLoading: true,
      };
    case PlinkoActions.FetchMultipliers: {
      return {
        ...state,
        loading: true,
      };
    }
    case PlinkoActions.FetchMultipliersSuccess: {
      const rows = state.rows as keyof Rows;
      const multipliers = (action as PlinkoFetchMultipliersSuccessPayload)
        .payload.multipliers;
      const currentMultipliers = (
        action as PlinkoFetchMultipliersSuccessPayload
      ).payload.multipliers[state.risk][rows];

      return {
        ...state,
        loading: false,
        multipliers: multipliers,
        currentMultipliers,
      };
    }
    case PlinkoActions.FetchMultipliersError: {
      return {
        ...state,
        loading: false,
        error: (action as any).payload,
      };
    }
    case PlinkoActions.SetRisk: {
      const rows = state.rows as keyof Rows;
      const risk = (action as PlinkoRiskChangePayload).payload;
      const currentMultipliers = state.multipliers?.[risk][rows] ?? null;

      return {
        ...state,
        risk: risk,
        currentMultipliers,
      };
    }
    case PlinkoActions.SetBallInGame: {
      return {
        ...state,
        ballsInGame: state.ballsInGame.concat(
          (action as PlinkoSetBallInGamePayload).payload,
        ),
      };
    }
    case PlinkoActions.Reset: {
      return {
        ...state,
        ballsInGame: [],
        loading: true,
        betLoading: false,
        error: false,
        bet: [],
        amount: 10,
        round: null,
        rows: 8,
        multipliers: null,
        currentMultipliers: null,
        risk: 'low',
        updatedUserInfo: null,
      };
    }
    case PlinkoActions.SetBallFinished: {
      const ballId = (action as PlinkoSetBallFinishedPayload).payload.id;
      const ballFineshed = state.ballsInGame.find(ball => {
        return ball.id === ballId;
      });
      const ballsInGame = state.ballsInGame.filter(ball => {
        return ball.id !== ballId;
      });
      const formatedBall = ballFineshed && ballFineshed?.updatedUserInfo;

      return {
        ...state,
        ballsInGame,
        updatedUserInfo: formatedBall ?? state.updatedUserInfo,
      };
    }
    default:
      return state;
  }
}
