export enum GameTagFilter {
  Fortune = 'fortune',
  Roulette = 'roulette',
  Slots = 'slots',
  Blackjack = 'blackjack',
  Baccarat = 'baccarat',
  Poker = 'poker',
  Dice = 'dice',
  Crash = 'crash',
  Recommended = 'recommended',
  EnhancedRTP = 'enhanced_rtp',
  NewReleases = 'new_releases',
  LiveGame = 'live_game',
  LiveCasino = 'live_casino',
  Originals = 'originals',
}
