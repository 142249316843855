import useTranslation from 'next-translate/useTranslation';
import Rewards from './Rewards';

import styles from './styles.module.scss';

export default function RewardsSection() {
  const { t } = useTranslation('common');

  return (
    <section className={`${styles.container}`}>
      <div className={styles.container__row}>
        <i className={`${styles.container__icon} fa-solid fa-tags`} />
        <h6 className={styles.container__title}> {t('promotions_label')}</h6>
      </div>

      <Rewards />
    </section>
  );
}
