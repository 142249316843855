import useTranslation from 'next-translate/useTranslation';
import { ModalsKey } from '@/enums/modalsKey';
import styles from './styles.module.scss';
import { useUserAccount } from '@/api/user/queries';
import { useModalNavigation } from '@/hooks/modals/useModalNavigation';
import { useRouter } from 'next/router';

export default function ReferSection() {
  const { t } = useTranslation('common');
  const { isAuthorized } = useUserAccount();
  const { navigateToModal } = useModalNavigation();
  const { push } = useRouter();

  function handleNavigate() {
    if (!isAuthorized) {
      navigateToModal(ModalsKey.Login);
      return;
    }

    push('/affiliates/panel');
  }

  return (
    <div className={styles.container} onClick={handleNavigate}>
      <div className={styles.container__ticket}>
        <div className={styles.container__ticket__icon}>
          <i className="fa-solid fa-gift fa-swap-opacity" />
        </div>

        <div className={styles.container__row}>
          <span className={styles.container__ticket__label}>
            {t('refer_and_win')}
          </span>
        </div>
      </div>
    </div>
  );
}
