import {
  TournamentsActions,
  TournamentsStatePayload,
  TournamentStateProps,
} from './props';

const initialState: TournamentStateProps = {
  tab: null,
  query: '',
};

export default function tournamentReducer(
  state: TournamentStateProps = initialState,
  action: TournamentsStatePayload,
): TournamentStateProps {
  switch (action.type) {
    case TournamentsActions.SetCurrentTab: {
      return { ...state, tab: action.payload };
    }
    case TournamentsActions.SetTournamentQuery:
      return {
        ...state,
        query: action.payload,
      };
    default:
      return state;
  }
}
