import { CookiesKey } from '@/enums/cookiesKey';
import { getCookie, setCookie } from 'cookies-next';
import useTranslation from 'next-translate/useTranslation';
import { useEffect } from 'react';
import { usePathname } from './usePathname';
import { i18nConf } from '@/i18n/config';

export function useSetLanguageCookie() {
  const { lang } = useTranslation();
  const pathname: string = usePathname();

  function onMount() {
    const langCookie: string | undefined = getCookie(CookiesKey.Lang) as string;

    if (!lang || !langCookie) {
      return;
    }

    let newLanguage: string = lang;

    const urlLocaLe: string | undefined = i18nConf.locales.find(
      locale => pathname.includes(`/${locale}/`) || pathname === `/${locale}`,
    );

    if (!!langCookie && !!urlLocaLe && langCookie !== urlLocaLe) {
      newLanguage = urlLocaLe;
    }

    setCookie(CookiesKey.Lang, lang, {
      sameSite: 'strict',
      maxAge: 31536000,
    });
    setCookie('NEXT_LOCALE', lang);
  }

  useEffect(onMount, [lang, pathname]);
}
