import { ThemeType } from '@/enums/themeType';
import { Setting } from '@package/models';

export type ThemeStateProps = {
  type: ThemeType;
  setting?: Setting;
};

export enum ThemeActions {
  Set = 'theme/set',
  Settings = 'theme/settings-initialization',
}

export interface SetThemePayload {
  type: string;
  payload: ThemeType;
}

export interface SetSettingsPayload {
  type: string;
  payload: Setting;
}

export type ThemeStatePayload = SetThemePayload | SetSettingsPayload;
