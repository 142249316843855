export enum RewardId {
  LuckyWheel = 1,
  HourlyRakeback = 2,
  DailyRakeback = 3,
  DailyCashback = 4,
  WeeklyCashback = 5,
  MonthlyCashback = 6,
  SportsCashback = 7,
  LevelUp = 8,
}
