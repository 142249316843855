import {
  LuckySpinStatePayload,
  LuckySpinActions,
  LuckySpinProps,
} from './props';

const initialState: LuckySpinProps = {
  isSpinning: false,
  prize: null,
  isPrizeDefined: false,
};

export default function reducer(
  state: LuckySpinProps = initialState,
  action: LuckySpinStatePayload,
): LuckySpinProps {
  switch (action.type) {
    case LuckySpinActions.SetSpinning:
      return {
        ...state,
        isSpinning: action.payload.isSpinning,
      };
    case LuckySpinActions.SetPrize:
      return {
        ...state,
        prize: action.payload.prize,
      };
    case LuckySpinActions.SetIsPrizeDefined:
      return {
        ...state,
        isPrizeDefined: action.payload.isPrizeDefined,
      };
    case LuckySpinActions.ResetLuckySpin:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
