import styles from '../styles.module.scss';
import skeleton from './styles.module.scss';
import { Button } from '@package/components';

export default function ProfileDrawerContentBalanceSkeleton() {
  return (
    <div className={styles.card}>
      <div className={styles.card__row}>
        <div className={styles.card__label}>
          <div className={skeleton.icon} />
          <div className={skeleton.label} />
        </div>
        <div className={styles.card__title}>
          <div className={skeleton.value} />
        </div>
      </div>
      <div className={styles.card__row}>
        <Button isSmall isSkeleton />
        <Button isSmall isSkeleton />
      </div>
    </div>
  );
}
