import { useFormContext } from 'react-hook-form';
import styles from './styles.module.scss';
import { AuthLoginRequestDto } from '@/services/dto/auth/auth-login-request.dto';
import { Requires2faProps } from './props';
import useTranslation from 'next-translate/useTranslation';
import { FormGroup } from '@starsoft/common/components';
import { ValidateCodeInput } from '@starsoft/common/components';
import { Button } from '@starsoft/common/components';
import { m as motion } from 'framer-motion';
import { slideInRight } from './variants';
import { AuthService } from '@/services/AuthService';

export default function Requires2faContent({
  onClose,
  isLoading,
}: Requires2faProps) {
  const { t } = useTranslation('login');
  const { setValue, watch } = useFormContext<AuthLoginRequestDto>();

  function handleCode(code: string) {
    setValue('code', code);
  }

  const code = watch('code');

  return (
    <motion.div
      className={styles.container}
      variants={slideInRight}
      initial="hidden"
      animate="visible"
      exit="hidden"
    >
      <button
        className={styles.container__button}
        type="button"
        onClick={onClose}
      >
        <i className="fa-solid fa-chevron-left" />
        {t('back_button')}
      </button>
      <FormGroup label={t('2fa_input_label')}>
        <ValidateCodeInput setValue={handleCode} />
      </FormGroup>
      <Button
        type="submit"
        form="login-form"
        disabled={!code || (!!code && Number(code?.length) < 6)}
        isloading={isLoading || AuthService.isAuthorized()}
      >
        {t('submit_label')}
      </Button>
    </motion.div>
  );
}
