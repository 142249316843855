import { EmojiAvatarProps } from './props';
import { SkeletonRow, SkeletonAvatar } from './skeleton';
import { Button, Photo, PhotoContainer } from './styles';
import { useUserAccount } from '@/api/user/queries';

function EmojiAvatar({
  onClick,
  isSmall,
  isLarge,
  isHeader,
}: EmojiAvatarProps) {
  const { user, isLoading, isFetching } = useUserAccount();

  if (isLoading || !user || isFetching) {
    return (
      <SkeletonRow>
        <SkeletonAvatar isLarge={isLarge} isSmall={isSmall} />
      </SkeletonRow>
    );
  }

  return (
    <Button id="highlight-wrapper" onClick={onClick}>
      <PhotoContainer
        isLarge={isLarge}
        isSmall={isSmall}
        isHeader={isHeader}
        isFollowing={false}
      >
        <Photo
          format="square"
          width={30}
          height={30}
          src={user?.player?.photo}
          isSmall={isSmall}
          isLarge={isLarge}
          secondarySkeleton
        />
      </PhotoContainer>
    </Button>
  );
}

export default EmojiAvatar;
