import { QueryFunctionContext } from '@tanstack/react-query';
import { UseUserRewardsByRewardQueryKeyProps } from './props';
import baseAPI from '@/api';
import { PaginatedResponse } from '@/models/pagination/paginated-response.dto';
import { UserReward } from '@/models/userReward';

export async function fetchUserRewardsByReward({
  queryKey,
  pageParam,
}: QueryFunctionContext<UseUserRewardsByRewardQueryKeyProps>) {
  const { rewardId, limit } = queryKey[1];

  const response = await baseAPI.get<PaginatedResponse<UserReward>>(
    '/user-rewards/by-reward',
    {
      params: {
        rewardId,
        limit,
        page: pageParam,
      },
    },
  );

  return response.data;
}
