import { Image } from '@starsoft/common/components';
import { TableBonusRewardTypeCellProps } from './props';
import styles from './styles.module.scss';
import useTranslation from 'next-translate/useTranslation';

export default function TableBonusRewardTypeCell({
  rewardType,
}: TableBonusRewardTypeCellProps) {
  const { t } = useTranslation('bonus');

  return (
    <div className={styles.container}>
      <Image
        className={styles.container__icon}
        format="square"
        width={14}
        height={14}
        src={`${process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL}/icons/bonus/${rewardType?.image}`}
        alt={rewardType?.name}
      />
      {t(`${rewardType?.id}_bonus_reward_type`)}
    </div>
  );
}
