import { TableGridColumn } from '@starsoft/common/src/components/TableGrid/props';
import { BonusRewardType } from '@/models/bonusReward/bonusRewardType';
import TableBonusRewardTypeCell from '@/components/core/Table/CustomCells/BonusRewardTypeCell';
import { WalletService } from '@package/services';
import { CoinID } from '@package/enums';

export const bonusRewardTypeColumns: TableGridColumn<BonusRewardType>[] = [
  {
    field: 'name',
    headerName: 'bonus_type',
    renderCell: bonusRewardType => (
      <TableBonusRewardTypeCell rewardType={bonusRewardType} />
    ),
  },
  {
    field: 'bonusAmount',
    headerName: '',
    alignEnd: true,
    valueFormatter: row =>
      WalletService.maskCurrency({
        value: row?.bonusAmount,
        coinId: CoinID.USD,
      }),
  },
];
