import { RootState } from '@/lib/store';
import SidebarContent from './Content';
import SidebarHeader from './Header';
import BonusButton from './BonusButton';
import RewardsSection from './Content/NotCollapsed/RewardsSection';

import styles from './styles.module.scss';
import { useSelector } from 'react-redux';

function SideBar() {
  const isCollapsed: boolean = useSelector(
    (state: RootState) => state.sideBar.collapsed,
  );

  return (
    <div className={styles.container}>
      <aside
        className={`${styles.container__aside} ${isCollapsed ? styles['container__aside--collapsed'] : ''}`}
      >
        <div className={styles['container__desktop_only']}>
          <SidebarHeader />
        </div>

        <section
          className={`${styles.container__content} ${isCollapsed ? styles['container__content--collapsed'] : ''}`}
        >
          {!isCollapsed && (
            <>
              <BonusButton />
              <RewardsSection />
            </>
          )}

          <SidebarContent />
        </section>
      </aside>
    </div>
  );
}

export default SideBar;
