import { QueryFunctionContext } from '@tanstack/react-query';
import { UseNotificationGroupsMeQueryKey } from './props';
import { PaginatedResponse } from '@/models/pagination/paginated-response.dto';
import { NotificationGroupWithUnreadCount } from '@/models/notification/notificationGroupWithUnreadCount';
import { NotificationGroupsMeInputDto } from '@/services/dto/notification-groups/notification-groups-me-input.dto';
import { AxiosResponse } from 'axios';
import baseAPI from '@/api';

export async function fetchNotificationGroupsMe({
  queryKey,
}: QueryFunctionContext<UseNotificationGroupsMeQueryKey>): Promise<
  PaginatedResponse<NotificationGroupWithUnreadCount>
> {
  const { groupIds, limit, page }: NotificationGroupsMeInputDto = queryKey[1];
  const mappedGroupIds: string | undefined = groupIds?.join(',');

  const response: AxiosResponse<
    PaginatedResponse<NotificationGroupWithUnreadCount>
  > = await baseAPI.get<PaginatedResponse<NotificationGroupWithUnreadCount>>(
    '/notification-groups/me',
    {
      params: {
        page,
        limit,
        groupIds: mappedGroupIds,
      },
    },
  );

  return response.data;
}
