import { useWalletsMe } from '@/api/wallets';
import WalletService from '@/services/WalletService';
import { CurrencyButtonProps } from './props';
import { memo } from 'react';
import { coinFormattingMapping } from '@/constants/coinFormattingMapping';
import styles from './styles.module.scss';
import { Image } from '@package/components';
import CurrencyButtonSkeleton from './Skeleton';
import { useConversionCoin } from '@/hooks/useConversionCoin';
import { CoinID } from '@package/enums';

function CurrencyButton({
  account,
  onClick,
  noEffects,
  loading,
  isSkeleton,
  disabledCurrent,
  isBalanceModal = false,
}: CurrencyButtonProps): JSX.Element {
  const { wallet } = useWalletsMe({
    enabled: !loading,
    refetchOnMount: false,
  });
  const { coin: fiatConversionCoin } = useConversionCoin();
  const coinId: number = fiatConversionCoin
    ? (fiatConversionCoin?.id as number)
    : (account?.coin?.id as number);
  const amount: number = fiatConversionCoin
    ? WalletService.convertValueToCoin({
        value: account?.balance as number,
        valueCoinPrice: account?.coin?.price as number,
        conversionCoinPrice: fiatConversionCoin.price,
      })
    : (account?.balance as number);
  const formattedValue: string[] = WalletService.maskCurrency({
    value: amount,
    coinId,
    hideSymbol: !fiatConversionCoin,
  })?.split(coinFormattingMapping?.get(coinId)?.decimal ?? '');

  if (isSkeleton) {
    return <CurrencyButtonSkeleton />;
  }

  return (
    <button
      className={`${styles.container} ${noEffects ? styles['container--without-effect'] : ''} ${noEffects || disabledCurrent ? false : account?.id == wallet?.currentAccount?.id ? styles['container--current'] : ''}`}
      disabled={loading}
      onClick={() => !noEffects && onClick?.(account?.coin?.id as number)}
      type="button"
    >
      <div className={styles.container__coin}>
        <Image
          className={styles.container__coin__image}
          width={24}
          height={24}
          src={`${process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL}/coins/rounded/${account?.coin?.image}`}
          secondarySkeleton
          alt={`coin ${account?.coin?.name}`}
        />
        <div className={styles.container__coin__text__container}>
          <span
            className={`${styles.container__coin__text} ${isBalanceModal ? styles['container__coin__text--balance-modal'] : ''}`}
          >
            {account?.coin?.symbol}
          </span>
          {!isBalanceModal && account?.coin?.name}
        </div>
      </div>

      <div className={styles.container__balance}>
        <span
          className={`${styles.container__balance__value} ${isBalanceModal ? styles['container__balance__value--balance-modal'] : ''}`}
        >
          {formattedValue?.[0]}
          {formattedValue?.[1] && (
            <span
              className={`${styles.container__balance__value} ${styles['container__balance__value--secondary']}`}
            >
              .{formattedValue?.[1]}
            </span>
          )}
        </span>

        <span className={styles.container__balance__conversion}>
          {WalletService.maskCurrency({
            value: Number(account?.balance) * Number(account?.coin?.price),
            coinId: CoinID.USD,
          })}
        </span>
      </div>
    </button>
  );
}

export default memo(CurrencyButton);
