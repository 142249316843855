import { modals } from '@/constants/modals';
import { ModalsKey } from '@/enums/modalsKey';
import { useRouter } from 'next/router';
import { useMemo } from 'react';

export function useCurrentModal(): ModalsKey | undefined {
  const { asPath } = useRouter();
  const modalUrl: string | undefined = asPath.split('#')?.[1];

  const modal: ModalsKey | undefined = useMemo(
    () => modals.find(modal => modal === modalUrl),
    [modalUrl],
  );

  return modal;
}
