import { useDispatch, useSelector } from 'react-redux';
import NotificationsModal from '../NotificationModal';
import { RootState } from '@/lib/store';
import useMediaQuery from '@mui/material/useMediaQuery';
import { IconButton } from '@starsoft/common/components';
import { useNotificationGroupsMe } from '@/api/notification-groups';
import { ErrorChip } from '@starsoft/common/components';
import styles from './styles.module.scss';
import { setNotificationModalOpenState } from '@/lib/store/notifications/actions';
import { RichTooltip } from '@starsoft/common/components';

export default function NotificationButton() {
  const windowSize = useMediaQuery('(max-width: 768px)');
  const open: boolean = useSelector(
    (state: RootState) => state.notifications.open,
  );
  const { isPending, unreadCount, error, refetch } = useNotificationGroupsMe();
  const dispatch = useDispatch();

  function onClose() {
    dispatch(setNotificationModalOpenState(false));
  }

  function onOpen() {
    dispatch(setNotificationModalOpenState(true));
  }
  if (error) {
    return <ErrorChip action={refetch} />;
  }

  return (
    <RichTooltip
      open={open && !windowSize}
      onClose={onClose}
      content={<NotificationsModal />}
    >
      <IconButton onClick={onOpen} isLoading={isPending}>
        <i className="fad fa-envelope fa-swap-opacity" />

        {unreadCount > 0 && (
          <div className={styles.indicator}>
            {unreadCount > 99 ? '99+' : unreadCount}
          </div>
        )}
      </IconButton>
    </RichTooltip>
  );
}
