import { GenericError } from '@/models/generic-error';
import {
  Ball,
  PlinkoActions,
  PlinkoBasePayload,
  PlinkoBetAmountPayload,
  PlinkoBetVerifyAmountPayload,
  PlinkoDismissLoadingPayload,
  PlinkoDoubleAmountPayload,
  PlinkoEnterBetFailPayload,
  PlinkoEnterBetPayload,
  PlinkoEnterBetSuccessPayload,
  PlinkoFetchMultipliersErrorPayload,
  PlinkoFetchMultipliersPayload,
  PlinkoFetchMultipliersSuccessPayload,
  PlinkoHalfAmountPayload,
  PlinkoRiskChangePayload,
  PlinkoRowsChangePayload,
  PlinkoSetBallFinishedPayload,
  PlinkoSetBallInGamePayload,
  Risk,
} from './props';
import { PlinkoFetchMultipliersResponseDto } from '@/services/dto/plinko/plinko-fetch-multipliers.response.dto';

export function setBallInGame(ball: Ball): PlinkoSetBallInGamePayload {
  return {
    type: PlinkoActions.SetBallInGame,
    payload: ball,
  };
}

export function getBetAmount(amount: number): PlinkoBetAmountPayload {
  return {
    type: PlinkoActions.GetAmount,
    payload: {
      amount,
    },
  };
}

export function setRowsQuantity(rows: number): PlinkoRowsChangePayload {
  return {
    type: PlinkoActions.SetRowsQuantity,
    payload: {
      rows,
    },
  };
}

export function verifyAmount(amount: string): PlinkoBetVerifyAmountPayload {
  return {
    type: PlinkoActions.VerifyAmount,
    payload: {
      amount,
    },
  };
}

export function setHalfAmount(): PlinkoHalfAmountPayload {
  return {
    type: PlinkoActions.SetHalfAmount,
  };
}

export function setDoubleAmount(): PlinkoDoubleAmountPayload {
  return {
    type: PlinkoActions.SetDoubleAmount,
  };
}

export function enterBet(
  amount: number,
  rows: number,
  risk: string,
  coinId: number,
): PlinkoEnterBetPayload {
  return {
    type: PlinkoActions.EnterBet,
    payload: {
      amount,
      rows,
      risk,
      coinId,
    },
  };
}

export function enterBetSuccessPayload({
  bet,
  slot,
  round,
}: PlinkoEnterBetSuccessPayload['payload']): PlinkoEnterBetSuccessPayload {
  return {
    type: PlinkoActions.EnterBetSuccess,
    payload: {
      bet,
      slot,
      round,
    },
  };
}

export function enterBetFailPayload(
  error: GenericError,
): PlinkoEnterBetFailPayload {
  return {
    type: PlinkoActions.EnterBetError,
    payload: error,
  };
}

export function dismissPlinkoLoading(): PlinkoDismissLoadingPayload {
  return {
    type: PlinkoActions.DismissLoading,
  };
}

export function fetchMultipliers(): PlinkoFetchMultipliersPayload {
  return {
    type: PlinkoActions.FetchMultipliers,
  };
}

export function fetchMultipliersSuccess(
  multipliers: PlinkoFetchMultipliersResponseDto,
): PlinkoFetchMultipliersSuccessPayload {
  return {
    type: PlinkoActions.FetchMultipliersSuccess,
    payload: {
      multipliers,
    },
  };
}

export function fetchMultipliersError(
  error: GenericError,
): PlinkoFetchMultipliersErrorPayload {
  return {
    type: PlinkoActions.FetchMultipliersError,
    payload: error,
  };
}

export function setRisk(risk: Risk): PlinkoRiskChangePayload {
  return {
    type: PlinkoActions.SetRisk,
    payload: risk,
  };
}

export function reset(): PlinkoBasePayload {
  return {
    type: PlinkoActions.Reset,
  };
}

export function setBallFinished(id: string): PlinkoSetBallFinishedPayload {
  return {
    type: PlinkoActions.SetBallFinished,
    payload: {
      id,
    },
  };
}
