import { Coin, PaginatedRequest, PaginatedResponse } from '@package/models';
import { QueryFunctionContext } from '@tanstack/react-query';
import { UseCoinsQueryKey } from './props';
import { AxiosResponse } from 'axios';
import baseAPI from '@/api';

export async function fetchCoins({
  pageParam = 1,
  queryKey,
}: QueryFunctionContext<UseCoinsQueryKey>): Promise<PaginatedResponse<Coin>> {
  const { limit }: PaginatedRequest = queryKey[1];

  const response: AxiosResponse<PaginatedResponse<Coin>> = await baseAPI.get<
    PaginatedResponse<Coin>
  >('/coins', {
    params: {
      page: pageParam,
      limit,
    },
  });

  return response.data;
}
