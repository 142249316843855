import { SettingLayoutComponentId } from './componentId.enum';
import { SettingLayoutComponentType } from './type';

export interface SettingLayoutComponent {
  id: SettingLayoutComponentId;
  name: string;
  image: string;
  type: SettingLayoutComponentType;
}

export { SettingLayoutComponentId };
