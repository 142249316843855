import { ReferralVouchersQueryKeys } from '../../queryKeys';
import { UseReferralVoucherByCodeProps } from './props';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { GenericError } from '@/models/generic-error';
import { fetchReferralVoucherByCode } from './service';
import { AuthService } from '@/services/AuthService';
import { ReferralVoucherByCodeRequest } from '@/services/dto/referral-vouchers/referral-voucher-by-code-request.dto';
import { useEffect } from 'react';
import { ReferralVoucher } from '@/models/referral-voucher';

export function useReferralVoucherByCode(
  options: UseReferralVoucherByCodeProps,
) {
  const { enabled, voucher, refetchOnMount = true } = options ?? {};
  const queryKey = [
    ReferralVouchersQueryKeys.ByCode,
    {
      voucher,
    } as ReferralVoucherByCodeRequest,
  ];

  const { data, isPending, error, refetch, isError, fetchStatus, isSuccess } =
    useQuery<ReferralVoucher, AxiosError<GenericError>>({
      queryKey,
      queryFn: fetchReferralVoucherByCode,
      enabled: enabled && !!voucher,
      refetchOnMount,
    });

  function onSuccess() {
    if (!data) {
      return;
    }

    AuthService.setVoucher(data?.code);
  }

  useEffect(onSuccess, [data]);

  function onError() {
    if (!error) {
      return;
    }

    AuthService.removeVoucher();
  }
  useEffect(onError, [error]);

  return {
    voucher: data,
    isLoading: isPending && fetchStatus !== 'idle',
    error,
    isError,
    refetch,
    isSuccess,
  };
}
