import { LuckySpinRewards } from '@/models/userReward/lucky-spin/lucky-spin-rewards';

export enum LuckySpinActions {
  SetSpinning = 'lucky-spin/set-spinning',
  SetPrize = 'lucky-spin/set-prize',
  SetIsPrizeDefined = 'lucky-spin/set-is-prize-defined',
  ResetLuckySpin = 'lucky-spin/handle-reset',
}

export interface LuckySpinProps {
  isSpinning: boolean;
  prize: LuckySpinRewards | null;
  isPrizeDefined: boolean;
}

export interface LuckySpinBasePayload<T extends LuckySpinActions, V = null> {
  type: T;
  payload: V;
}

export interface SetIsSpinningPayload
  extends LuckySpinBasePayload<
    LuckySpinActions.SetSpinning,
    {
      isSpinning: boolean;
    }
  > {}

export interface SetIsPrizeDefinedPayload
  extends LuckySpinBasePayload<
    LuckySpinActions.SetIsPrizeDefined,
    {
      isPrizeDefined: boolean;
    }
  > {}

export interface SetPrizePayload
  extends LuckySpinBasePayload<
    LuckySpinActions.SetPrize,
    {
      prize: LuckySpinRewards | null;
    }
  > {}

export type LuckySpinStatePayload =
  | SetIsSpinningPayload
  | SetPrizePayload
  | LuckySpinBasePayload<LuckySpinActions.ResetLuckySpin>
  | SetIsPrizeDefinedPayload;
