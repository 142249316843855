const baseURL = process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL;

export const platformAssets = {
  app: 'ice.game',
  logo: {
    icon: `${process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL}/logos/icon.svg`,
    iconWithBackground: `${process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL}/logos/icon.svg`,
    web: {
      dark: `${process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL}/logos/light.svg`,
    },
  },
  theme: {
    light: {
      palette: {
        mode: 'light',
        primary: {
          contrastText: 'var(--primary-contrast-color)',
          main: 'var(--primary-color)',
          ghost: 'var(--primary-ghost-color)',
        },
        secondary: {
          contrastText: 'var(--secondary-contrast-color)',
          main: 'var(--secondary-color)',
        },
        card: {
          main: 'var(--card-color)',
        },
        success: {
          main: 'var(--success-color)',
          contrastText: 'var(--success-contrast-color)',
        },
        text: {
          primary: 'var(--text-primary-color)',
          secondary: 'var(--text-secondary-color)',
          disabled: 'var(--text-secondary-color)',
        },
        warning: {
          contrastText: 'var(--warning-contrast-color)',
          main: 'var(--warning-color)',
        },
        error: {
          contrastText: 'var(--error-contrast-color)',
          main: 'var(--error-color)',
        },
        background: {
          default: 'var(--background-default-color)',
          paper: 'var(--background-paper-color)',
        },
        severity: {
          main: 'var(--severity-color)',
          length: 'var(--severity-color)',
        },
        missionUncrossable: {
          primary: 'var(--missionUncrossable-primary-color)',
          secondary: 'var(--missionUncrossable-secondary-color)',
          shadow: 'var(--missionUncrossable-shadow-color)',
        },
        shape: {
          borderRadius: 'var(--shape-borderRadius)',
          boxHeight: 'var(--shape-boxHeight)',
        },
        customBar: {
          primary: '#00FF90',
        },
        roulette: {
          black: 'var(--roulette-black)',
          red: 'var(--roulette-red)',
        },
      },
      shape: {
        borderRadius: 'var(--shape-borderRadius)',
        boxHeight: 'var(--shape-boxHeight)',
      },
    },
    dark: {
      palette: {
        mode: 'light',
        primary: {
          contrastText: 'var(--primary-contrast-color)',
          main: 'var(--primary-color)',
          ghost: 'var(--primary-ghost-color)',
        },
        secondary: {
          contrastText: 'var(--secondary-contrast-color)',
          main: 'var(--secondary-color)',
        },
        card: {
          main: 'var(--card-color)',
        },
        success: {
          main: 'var(--success-color)',
          contrastText: 'var(--success-contrast-color)',
        },
        text: {
          primary: 'var(--text-primary-color)',
          light: 'var(--text-primary-color)',
          dark: 'var(--text-primary-color)',
          secondary: 'var(--text-secondary-color)',
          disabled: 'var(--text-secondary-color)',
        },
        warning: {
          contrastText: 'var(--warning-contrast-color)',
          main: 'var(--warning-color)',
        },
        error: {
          contrastText: 'var(--error-contrast-color)',
          main: 'var(--error-color)',
        },
        background: {
          default: 'var(--background-default-color)',
          paper: 'var(--background-paper-color)',
        },
        severity: {
          main: 'var(--severity-color)',
          length: 'var(--severity-color)',
        },
        missionUncrossable: {
          primary: 'var(--missionUncrossable-primary-color)',
          secondary: 'var(--missionUncrossable-secondary-color)',
          shadow: 'var(--missionUncrossable-shadow-color)',
        },
        shape: {
          borderRadius: 'var(--shape-borderRadius)',
          boxHeight: 'var(--shape-boxHeight)',
        },
        customBar: {
          primary: '#00FF90',
        },
        roulette: {
          black: 'var(--roulette-black)',
          red: 'var(--roulette-red)',
        },
      },
      shape: {
        borderRadius: 'var(--shape-borderRadius)',
        boxHeight: 'var(--shape-boxHeight)',
      },
    },
  },
  assets: {
    home: {
      swipper: {
        0: {
          imageMobile: `${baseURL}/artworks/493x213/trending.png`,
          imageDesktop: `${baseURL}/artworks/900x389/trending.png`,
        },
        1: {
          imageMobile: `${baseURL}/artworks/493x213/follow-bet.png`,
          imageDesktop: `${baseURL}/artworks/493x213/follow-bet.png`,
        },
        2: {
          imageMobile: `${baseURL}/artworks/493x213/bonus.png`,
          imageDesktop: `${baseURL}/artworks/900x389/bonus.png`,
        },
      },
      originals: {
        0: `${baseURL}/artworks/400x400/double.png`,
        1: `${baseURL}/artworks/400x400/crash.jpg`,
        2: `${baseURL}/artworks/400x400/plinko.jpg`,
        3: `${baseURL}/artworks/400x400/dice.jpg`,
        4: `${baseURL}/artworks/400x400/crash.jpg`,
        5: `${baseURL}/artworks/400x400/tower.jpg`,
        6: `${baseURL}/artworks/400x400/mines.jpg`,
        7: `${baseURL}/artworks/400x400/coinflip.jpg`,
        8: `${baseURL}/artworks/400x400/limbo.jpg`,
        9: `${baseURL}/artworks/400x400/slide.jpg`,
      },
      slots: {
        game1: `${baseURL}/slots/1.gif`,
        game2: `${baseURL}/slots/2.webp`,
        game3: `${baseURL}/slots/3.webp`,
        game4: `${baseURL}/slots/4.gif`,
        game5: `${baseURL}/slots/5.gif`,
        game6: `${baseURL}/slots/6.webp`,
        game7: `${baseURL}/slots/7.gif`,
        game8: `${baseURL}/slots/8.gif`,
        game10: `${baseURL}/slots/10.webp`,
        game11: `${baseURL}/slots/11.gif`,
        game12: `${baseURL}/slots/12.gif`,
        game13: `${baseURL}/slots/13.gif`,
        game14: `${baseURL}/slots/14.gif`,
        game15: `${baseURL}/slots/15.webp`,
        game16: `${baseURL}/slots/16.webp`,
        game17: `${baseURL}/slots/17.gif`,
        game18: `${baseURL}/slots/18.webp`,
        game19: `${baseURL}/slots/19.webp`,
        game20: `${baseURL}/slots/20.webp`,
        game21: `${baseURL}/slots/21.gif`,
        game22: `${baseURL}/slots/22.webp`,
        game23: `${baseURL}/slots/23.webp`,
        game24: `${baseURL}/slots/24.webp`,
      },
      providers: {
        image1: `${baseURL}/providers/0d2b46a1-ed2d-4c0b-8950-09c6d341a1d1.png`,
        image2: `${baseURL}/providers/1afbff60-9c4f-4eba-8de0-1db162e76f1d-p-500.png`,
        image3: `${baseURL}/providers/2e9917cb-7e4b-42a9-b705-501234406fe4-p-500.png`,
        image4: `${baseURL}/providers/615c4de4-d8ac-46c4-9994-5ad60bfc9c41-p-500.png`,
        image5: `${baseURL}/providers/62293247-f40f-4fca-bfd6-209e9a0c4d3c.png`,
        image6: `${baseURL}/providers/b814272e-ed24-4d80-8c1d-38b3d4130287.png`,
        image7: `${baseURL}/providers/bf4731e9-6185-4fe2-b25e-e9d7cf2c6f40.png`,
        image8: `${baseURL}/providers/bgaming.png`,
        image9: `${baseURL}/providers/cdcc751e-6e00-4e2b-b95f-eb9cbf7fe6d3-p-500.png`,
        image10: `${baseURL}/providers/evolutiongaming.png`,
        image11: `${baseURL}/providers/gameart.png`,
        image12: `${baseURL}/providers/netent.png`,
        image13: `${baseURL}/providers/playngo_logo_rgb.png`,
        image14: `${baseURL}/providers/pragmaticplay.png`,
        image15: `${baseURL}/providers/quickspin.png`,
      },
    },
    general: {
      authBaner: `${baseURL}/artworks/auth-banner.png`,
    },
  },
  seo: {
    '/': {
      '@context': 'http://schema.org',
      '@type': 'Casino',
      name: 'ice.game',
      description:
        'Online betting site ice.game offers the best in the world of online casino gaming, jump in and profit from games like Double, Dice, Coinflip and more.',
      url: 'https://www.ice.game.casino/',
      logo: `${process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL}/logos/icon.svg`,
    },
    '/sports': {
      '@context': 'http://schema.org',
      '@type': 'Casino',
      name: 'ice.game | Sports',
      description:
        'Place your bets online at the best sportsbooks and check out the latest betting odds on your favorite sport! Bet on Football, NBA, MMA, MLB and more with ice.game.',
      url: 'https://www.ice.game.casino/',
      logo: `${process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL}/logos/icon.svg`,
    },
    '/games/crash': {
      '@context': 'http://schema.org',
      '@type': 'Casino',
      name: 'ice.game | Crash',
      description:
        'Predict the multiplier in this fast and simplistic probability-based game, Crash! Take on the house with a variety of different strategies to defeat the house and take home huge winnings!',
      url: 'https://www.ice.game.casino/games/crash',
      logo: `${process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL}/logos/icon.svg`,
    },
    '/games/limbo': {
      '@context': 'http://schema.org',
      '@type': 'Casino',
      name: 'ice.game | Limbo',
      description:
        'Limbo is an online casino game that offers unrivaled gameplay and some really big wins.',
      url: 'https://www.ice.game.casino/games/limbo',
      logo: `${process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL}/logos/icon.svg`,
    },
    '/games/tower': {
      '@context': 'http://schema.org',
      '@type': 'Casino',
      name: 'ice.game | Tower',
      description:
        'Climb the Tower levels and avoid the skulls along the way to win in this exciting original ice.game online casino game!',
      url: 'https://www.ice.game.casino/games/tower',
      logo: `${process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL}/logos/icon.svg`,
    },
    '/games/coinflip': {
      '@context': 'http://schema.org',
      '@type': 'Casino',
      name: 'ice.game | Coinflip',
      description:
        'Heads or tails? Guess the side of the coin that will land to increase your multiplier and get exponential wins in this original ice.game game!',
      url: 'https://www.ice.game.casino/games/coinflip',
      logo: `${process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL}/logos/icon.svg`,
    },
    '/games/plinko': {
      '@context': 'http://schema.org',
      '@type': 'Casino',
      name: 'ice.game | Plinko',
      description:
        'Inspired by the Japanese mechanic game known as Pachinko, Plinko allows players to drop a ball from the top of our triangular peg pyramid to find the winning route up to a multiplier.',
      url: 'https://www.ice.game.casino/games/plinko',
      logo: `${process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL}/logos/icon.svg`,
    },
    '/games/dice': {
      '@context': 'http://schema.org',
      '@type': 'Casino',
      name: 'ice.game | Dice',
      description:
        'Dice is a simple original ice.game game with easy to understand betting mechanics so you can multiply your winnings.',
      url: 'https://www.ice.game.casino/games/dice',
      logo: `${process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL}/logos/icon.svg`,
    },
    '/games/zy-options': {
      '@context': 'http://schema.org',
      '@type': 'Casino',
      name: 'ice.game | Zy Options',
      description:
        'Will the trend be up or down? Hit the option in this exciting binary options game.',
      url: 'https://www.ice.game.casino/games/zy-options',
      logo: `${process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL}/logos/icon.svg`,
    },
    '/games/mines': {
      '@context': 'http://schema.org',
      '@type': 'Casino',
      name: 'ice.game | Mines',
      description:
        'Join the Mines fever with one of our most popular and beloved games! Inspired by the classic Minesweeper, Mines will simply reveal gems and avoid bombs to increase your payout multiplier.',
      url: 'https://www.ice.game.casino/games/mines',
      logo: `${process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL}/logos/icon.svg`,
    },
    '/games/double': {
      '@context': 'http://schema.org',
      '@type': 'Casino',
      name: 'ice.game | Double',
      description:
        'This Roulette game will keep you interested and intrigued with each spin as you look for different ways to make a profit! Spin the roulette wheel and try your luck with the original roulette wheel from ice.game Casino!',
      url: 'https://www.ice.game.casino/games/double',
      logo: `${process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL}/logos/icon.svg`,
    },
    '/games/fortune-double': {
      '@context': 'http://schema.org',
      '@type': 'Casino',
      name: 'ice.game | Frost',
      description:
        "Get ready to discover the colors that bring prosperity in Frost! This ice.game Original will take you straight to Asia for a game of dice with players from around the world and an unusual Dealer, let's say, the iconic lucky panda.",
      url: 'https://www.ice.game.casino/games/fortune-double',
      logo: `${process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL}/logos/icon.svg`,
    },
    '/games/slide': {
      '@context': 'http://schema.org',
      '@type': 'Casino',
      name: 'ice.game | Slide',
      description:
        'Another multiplayer game that hit ice.game in the form of Slide! Set your own multiplier and race for the biggest win by betting with the ice.game community on one of the most enjoyable games in the gaming industry!',
      url: 'https://www.ice.game.casino/games/slide',
      logo: `${process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL}/logos/icon.svg`,
    },
    '/games/tag/slot': {
      '@context': 'http://schema.org',
      '@type': 'Casino',
      name: 'ice.game | Slots',
      description:
        'Play the best slots and win big with our casino-style slot machine games. Discover over 3,000 online games with huge prizes!',
      url: 'https://www.ice.game.casino/games/tag/slot',
      logo: `${process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL}/logos/icon.svg`,
    },
    '/games/tag/live': {
      '@context': 'http://schema.org',
      '@type': 'Casino',
      name: 'ice.game | Live Casino',
      description:
        'Play live online casino games including roulette, blackjack, poker and baccarat. Discover the best live casino games with real dealers!',
      url: 'https://www.ice.game.casino/games/tag/live',
      logo: `${process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL}/logos/icon.svg`,
    },
    '/games/tag/fortune': {
      '@context': 'http://schema.org',
      '@type': 'Casino',
      name: 'ice.game | Fortune',
      description:
        'Play card games and win big. Discover the best online card games with huge prizes!',
      url: 'https://www.ice.game.casino/games/tag/fortune',
      logo: `${process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL}/logos/icon.svg`,
    },
    '/games/tag/blackjack': {
      '@context': 'http://schema.org',
      '@type': 'Casino',
      name: 'ice.game | Blackjack',
      description:
        'Play blackjack online and reach 21 in one of the most popular card games out there! Find the best online blackjack games at ice.game.',
      url: 'https://www.ice.game.casino/games/tag/blackjack',
      logo: `${process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL}/logos/icon.svg`,
    },
    '/games/tag/baccarat': {
      '@context': 'http://schema.org',
      '@type': 'Casino',
      name: 'ice.game | Baccarat',
      description:
        'Play the best baccarat games online and win big at ice.game. Discover your favorite casino games and play online today!',
      url: 'https://www.ice.game.casino/games/tag/baccarat',
      logo: `${process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL}/logos/icon.svg`,
    },
    '/games/tag/roulette': {
      '@context': 'http://schema.org',
      '@type': 'Casino',
      name: 'ice.game | Roulette',
      description:
        'Discover the most popular variety of online Roulette games with the best range of live games! Play your favorite Roulette games online at ice.game.',
      url: 'https://www.ice.game.casino/games/tag/roulette',
      logo: `${process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL}/logos/icon.svg`,
    },
    '/games/tag/poker': {
      '@context': 'http://schema.org',
      '@type': 'Casino',
      name: 'ice.game | Poker',
      description:
        'Play the best poker games online and win big at ice.game. Discover your favorite casino games and play online today!',
      url: 'https://www.ice.game.casino/games/tag/poker',
      logo: `${process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL}/logos/icon.svg`,
    },
    '/games/tag/originals': {
      '@context': 'http://schema.org',
      '@type': 'Casino',
      name: 'ice.game | Originals',
      description:
        'Online betting site ice.game offers the best in the world of online casino gaming, jump in and profit from games like Double, Dice, Coinflip and more.',
      url: 'https://www.ice.game.casino/games/tag/originals',
      logo: `${process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL}/logos/icon.svg`,
    },
    '/games/providers': {
      '@context': 'http://schema.org',
      '@type': 'Casino',
      name: 'ice.game | Providers',
      description:
        'Play online casino games with the best providers. Discover the best online casino games with huge prizes!',
      url: 'https://www.ice.game.casino/games/providers',
      logo: `${process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL}/logos/icon.svg`,
    },
  },
  fontFamily: {
    name: 'var(--font-family)',
    fontUrls: [
      {
        url: `${baseURL}/fonts/GeoGrotesk/geogrotesque-wide-regular.woff2`,
        weight: [300, 400, 500],
      },
      {
        url: `${baseURL}/fonts/GeoGrotesk/geogrotesque-wide-medium.woff2`,
        weight: 600,
      },
      {
        url: `${baseURL}/fonts/GeoGrotesk/geogrotesque-wide-bold.woff2`,
        weight: [700, 800, 900],
      },
    ],
    css: 'https://icegames-s3.s3.amazonaws.com/fonts/GTWalsheimPro/fontFamily.css',
  },
  tracking: {
    GTM: {
      1: process.env.NEXT_PUBLIC_GTM_ID,
    },
  },
};
