import { AuthStep } from '@/enums/auth/authStep';
import useAuthSearchParams from '../useAuthSearchParams';
import { UseAuthFormProps } from './props';
import { RegisterFormProps } from '@/types/register/props';
import { AuthLoginRequestDto } from '@/services/dto/auth/auth-login-request.dto';
import { Path } from 'react-hook-form';

export default function useAuthForm<
  T extends RegisterFormProps | AuthLoginRequestDto,
>({ watch }: UseAuthFormProps<T>) {
  const { step } = useAuthSearchParams();

  const email = watch('email' as Path<T>);
  const password = watch('password' as Path<T>);
  const emailPattern: RegExp =
    /^(?!.*\s)[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  const isValidEmail = emailPattern.test(email);
  const isPasswordEmpty = password?.length === 0;
  const isValidPasswordLength =
    password?.length >= 4 && password?.length <= 128;
  const isValidPassword = isValidPasswordLength && !isPasswordEmpty;

  const isInvalidForm =
    step == AuthStep.Email ? !isValidEmail : !isValidEmail || !isValidPassword;
  const isPasswordStep = step == AuthStep.Password;

  return {
    isInvalidForm,
    isValidEmail,
    isValidPassword,
    email,
    isPasswordStep,
  };
}
