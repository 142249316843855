import enLocale from 'date-fns/locale/en-US';
import ptLocale from 'date-fns/locale/pt-BR';
import frLocale from 'date-fns/locale/fr-CA';
import deLocale from 'date-fns/locale/de';
import esLocale from 'date-fns/locale/es';
import zhLocale from 'date-fns/locale/zh-CN';

export default function getDateFNSLocale(lang: string) {
  switch (lang.slice(0, 2)) {
    case 'pt':
      return ptLocale;
    case 'en':
      return enLocale;
    case 'fr':
      return frLocale;
    case 'es':
      return esLocale;
    case 'de':
      return deLocale;
    case 'ru':
      return deLocale;
    case 'zh':
      return zhLocale;
    default:
      return enLocale;
  }
}
