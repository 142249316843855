import styles from '../styles.module.scss';
import skeleton from './styles.module.scss';

export default function BalanceButtonSkeleton() {
  return (
    <div className={styles.container}>
      <div className={styles.container__balance}>
        <div
          className={`${styles.container__row} ${styles['container__row--start']}`}
        >
          <div className={skeleton.coin} />
          <div
            className={`${styles.container__row} ${styles['container__row--small']}`}
          >
            <div className={skeleton.symbol} />
            <div className={skeleton.chevron} />
          </div>
        </div>

        <div className={skeleton.value} />
      </div>
      <div className={skeleton.deposit} />
    </div>
  );
}
