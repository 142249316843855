import { Bet } from '@/models/bet';

export enum LimboActions {
  EnterBetSuccess = 'limbo/enterBetSuccess',
  GetAmount = 'limbo/getAmount',
  VerifyAmount = 'limbo/verifyAmount',
  SetHalfAmount = 'limbo/setHalfAmount',
  SetDoubleAmount = 'limbo/setDoubleAmount',
  HandleAnimate = 'limbo/handleAnimate',
  HandleChangePredictMultiplier = 'limbo/handleChangePredictMultiplier',
  HandleReset = 'limbo/handleReset',
}

export interface LimboProps {
  bet: Bet | null;
  amount: string;
  predictMultiplier: string;
  winChance: string | null;
  limboMultiplier: number;
  animate: boolean;
}

export interface LimboBasePayload {
  type: string;
}

export type LimboHalfAmountPayload = LimboBasePayload;
export type LimboDoubleAmountPayload = LimboBasePayload;

export type HandleLimboSidePayload = LimboBasePayload;
export type HandleAnimateLimbo = LimboBasePayload;
export type AnimateLimbo = LimboBasePayload;

export interface LimboBetAmountPayload extends LimboBasePayload {
  payload: {
    amount: string;
  };
}
export interface LimboPredictMultiplierPayload extends LimboBasePayload {
  payload: {
    multiplier: string;
  };
}

export interface LimboBetVerifyAmountPayload extends LimboBasePayload {
  payload: {
    amount: string;
  };
}

export interface LimboEnterBetSuccessPayload extends LimboBasePayload {
  payload: {
    bet: Bet;
  };
}

export type LimboStatePayload =
  | LimboBasePayload
  | LimboHalfAmountPayload
  | LimboDoubleAmountPayload
  | LimboBetAmountPayload
  | LimboBetVerifyAmountPayload
  | LimboEnterBetSuccessPayload;
