import { useUserAccount } from '@/api/user';
import { useWalletsMe } from '@/api/wallets';
import { ModalsKey } from '@/enums/modalsKey';
import { useCallback } from 'react';
import { useModalNavigation } from '../modals/useModalNavigation';
import { useSearchParams } from '@package/hooks';
import { WithdrawStep } from '@/enums/withdrawStep';

export default function useTransactionsNavigation() {
  const { wallet } = useWalletsMe();
  const currentAccount = wallet?.currentAccount;

  const { navigateToModal } = useModalNavigation();
  const params = useSearchParams();

  const getModalSearchParams = useCallback(() => {
    params.set('coin-type', currentAccount?.coin?.type);
    params.set('coin-id', String(currentAccount?.coin?.id));
    params.set('step', WithdrawStep.Form);

    return `?${params.toString()}`;
  }, [params, currentAccount]);

  const navigateToTransactionModal = useCallback(
    (modal: ModalsKey.Deposit | ModalsKey.Withdraw) => {
      const searchParams = getModalSearchParams();
      navigateToModal(modal, searchParams);
    },
    [getModalSearchParams, navigateToModal],
  );

  return {
    navigateToTransactionModal,
    getModalSearchParams,
  };
}
