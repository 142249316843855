import { AxiosResponse } from 'axios';
import baseAPI from '@/api';
import { PlinkoCreateBetRequestDto } from '../dto/plinko/plinko-create-bet-request.dto';
import { PlinkoCreateBetResponseDto } from '../dto/plinko/plinko-create-bet-response.dto';
import { PlinkoCloseBetResponseDto } from '../dto/plinko/plinko-close-bet-response.dto';
import { PlinkoFetchMultipliersResponseDto } from '../dto/plinko/plinko-fetch-multipliers.response.dto';

export class PlinkoService {
  static async create({
    amount,
    rows,
    risk,
    coinId,
  }: PlinkoCreateBetRequestDto): Promise<
    AxiosResponse<PlinkoCreateBetResponseDto>
  > {
    return await baseAPI.post<PlinkoCreateBetResponseDto>('plinko/enter-bet', {
      amount,
      rows,
      risk,
      coinId,
    });
  }

  static async endRound(): Promise<AxiosResponse<PlinkoCloseBetResponseDto>> {
    return await baseAPI.patch<PlinkoCloseBetResponseDto>('plinko/close-bet');
  }

  static async fetchMultipliers(): Promise<
    AxiosResponse<PlinkoFetchMultipliersResponseDto>
  > {
    return await baseAPI.get<PlinkoFetchMultipliersResponseDto>(
      'plinko/multipliers',
    );
  }
}
