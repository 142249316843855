import { PageProps } from '@/types/page/props';

const baseApi = `${process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL}/flags`;

export default <PageProps[]>[
  {
    label: 'live_matches_title',
    paths: ['/sports/live'],
    icon: `fa-solid fa-circle-play`,
  },
  {
    label: 'tab_upcoming_events',
    paths: ['/sports/schedule'],
    icon: `fa-solid fa-stopwatch`,
  },
  {
    label: 'tab_bets',
    paths: ['/sports/bets'],
    icon: `fa-solid fa-ticket`,
  },
  {
    label: 'Soccer',
    icon: 'icon-soccer',
    paths: ['/sports/soccer-1'],
  },
  {
    label: 'American_Football',
    icon: 'icon-american-football',
    paths: ['/sports/american-football-16'],
  },
  {
    label: 'Badminton',
    icon: 'icon-badminton',
    paths: ['/sports/badminton-31'],
  },
  {
    label: 'Baseball',
    icon: 'icon-baseball',
    paths: ['/sports/baseball-3'],
  },
  {
    label: 'Basketball',
    icon: 'icon-basketball',
    paths: ['/sports/basketball-2'],
  },
  {
    label: 'Counter_Strike',
    icon: 'icon-counter-strike',
    paths: ['/sports/counter-strike-109'],
  },
  {
    label: 'Cricket',
    icon: 'icon-cricket',
    paths: ['/sports/cricket-21'],
  },
  {
    label: 'Cycling',
    icon: 'icon-cycling',
    paths: ['/sports/cycling-17'],
  },
  {
    label: 'Darts',
    icon: 'icon-darts',
    paths: ['/sports/darts-22'],
  },
  {
    label: 'Dota',
    icon: 'icon-dota',
    paths: ['/sports/dota-2-111'],
  },
  {
    label: 'Formula_1',
    icon: 'icon-formula-1',
    paths: ['/sports/formula-1-40'],
  },
  {
    label: 'Futsal',
    icon: 'icon-futsal',
    paths: ['/sports/futsal-29'],
  },
  {
    label: 'Golf',
    icon: 'icon-golf',
    paths: ['/sports/golf-9'],
  },
  {
    label: 'Handball',
    icon: 'icon-handball',
    paths: ['/sports/handball-6'],
  },
  {
    label: 'Ice_Hockey',
    icon: 'icon-ice-hockey',
    paths: ['/sports/ice-hockey-4'],
  },
  {
    label: 'League_of_Legends',
    icon: 'icon-league-of-legends',
    paths: ['/sports/league-of-legends-110'],
  },
  {
    label: 'MMA',
    icon: 'icon-mma',
    paths: ['/sports/mma-117'],
  },
  {
    label: 'MotoGP',
    icon: 'icon-moto-gp',
    paths: ['/sports/motorcycle-racing-190'],
  },
  {
    label: 'Rugby',
    icon: 'icon-rugby',
    paths: ['/sports/rugby-12'],
  },
  {
    label: 'Snooker',
    icon: 'icon-snooker',
    paths: ['/sports/snooker-19'],
  },
  {
    label: 'Table_Tennis',
    icon: 'icon-table-tennis',
    paths: ['/sports/table-tennis-20'],
  },
  {
    label: 'Tennis',
    icon: 'icon-tennis',
    paths: ['/sports/tennis-5'],
  },
  {
    label: 'Volleyball',
    icon: 'icon-volleyball',
    paths: ['/sports/volleyball-23'],
  },
  {
    label: 'Waterpolo',
    icon: 'icon-waterpolo',
    paths: ['/sports/polo-116'],
  },
  {
    label: 'brasil_tournament',
    icon: `${baseApi}/br.svg`,
    paths: ['/sports/soccer/brazil/brasileiro-serie-a-1666079971237564416'],
    isImage: true,
  },
  {
    label: 'the_league',
    icon: `${baseApi}/es.svg`,
    paths: ['/sports/soccer/spain/laliga-1666080196081618944'],
    isImage: true,
  },
  {
    label: 'champion_league_uefa',
    icon: `${baseApi}/international.svg`,
    paths: [
      '/sports/soccer/international-clubs/uefa-europa-league-1666080280198385664',
    ],
    isImage: true,
  },
  {
    label: 'italy_series_a',
    icon: `${baseApi}/it.svg`,
    paths: ['/sports/soccer/argentina/serie-a-1666080211319525376'],
    isImage: true,
  },
  {
    label: 'germany_bundesliga',
    icon: `${baseApi}/de.svg`,
    paths: ['/sports/fifa/germany/bundesliga-2344190344528269322'],
    isImage: true,
  },
  {
    label: 'ligue_1_french',
    icon: `${baseApi}/fr.svg`,
    paths: ['/sports/soccer/france/ligue-1-1666080025423777792'],
    isImage: true,
  },
  {
    label: 'eSoccer',
    icon: 'icon-esoccer',
    paths: ['/sports/fifa-300'],
  },
];
